import { useForm, useWatch } from 'react-hook-form';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ErSelect, ErSwitch, ErTextField } from 'eventrocket-commoncomponents';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import { yupResolver } from '@hookform/resolvers/yup';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  eventDetailsCustomizationValidationSchema,
  eventDetailsCustomizationDefaults,
} from './eventDetailsCustomizationValidationSchema';
import {
  onlinePlayerDateFormat,
  onlinePlayerDateTime,
  onlinePlayerDividerActors,
  onlinePlayerDividerTitle,
  onlinePlayerTextSizeOptions,
} from '../../../util/onlineStageSettingsOptions';
import useUserPermissions from '../../Hooks/useUserPermissions';

export default function EventDetailsFormLayoutOnlineStageDisplay() {
  // props

  // translation
  const { t } = useTranslation('event');

  // permission handling
  const { hasPermission } = useUserPermissions();

  // media query
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // event details context
  const {
    origEventData,
    basicData,
    customizationData,
    updateCustomizationData,
  } = useEventDetailsFormData();

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsCustomizationValidationSchema),
    defaultValues: {
      // ...eventDetailsCustomizationDefaults,
      // ...customizationData?.data,
      textSize: customizationData?.data.textSize,
      dividerActors: customizationData?.data.dividerActors,
      dividerTitle: customizationData?.data.dividerTitle,
      dateFormat: customizationData?.data.dateFormat,
      timeFormat: customizationData?.data.timeFormat,
      programHeadline: customizationData?.data.programHeadline,
      agendaHeadline: customizationData?.data.agendaHeadline,
      dateInTitle: customizationData?.data.dateInTitle,
      locationInTitle: customizationData?.data.locationInTitle,
      slotTitleInSecondaryColor:
        customizationData?.data.slotTitleInSecondaryColor,
    },
  });

  const watch = useWatch({ control });

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context

    if (!firstRun) {
      updateCustomizationData(getValues());
    } else {
      setFirstRun(false);
    }
  }, [watch]);

  return (
    <>
      <Stack sx={{ mt: 2 }}>
        <ErSelect
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SELECT_textSize',
          )}
          fieldName="textSize"
          control={control}
          size="small"
          variant="outlined"
          menuItems={onlinePlayerTextSizeOptions}
          sx={{ mb: 2, width: 'fit-content', minWidth: '200px' }}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        <ErSelect
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SELECT_dividerActors',
          )}
          fieldName="dividerActors"
          control={control}
          size="small"
          variant="outlined"
          menuItems={onlinePlayerDividerActors}
          sx={{ mb: 2, width: 'fit-content', minWidth: '200px' }}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        <ErSelect
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SELECT_dividerTitle',
          )}
          fieldName="dividerTitle"
          control={control}
          size="small"
          variant="outlined"
          menuItems={onlinePlayerDividerTitle}
          sx={{ mb: 2, width: 'fit-content', minWidth: '200px' }}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        <ErSelect
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SELECT_dateFormat',
          )}
          fieldName="dateFormat"
          control={control}
          size="small"
          variant="outlined"
          menuItems={onlinePlayerDateFormat}
          sx={{ mb: 2, width: 'fit-content', minWidth: '200px' }}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        <ErSelect
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SELECT_timeFormat',
          )}
          fieldName="timeFormat"
          control={control}
          size="small"
          variant="outlined"
          menuItems={onlinePlayerDateTime}
          sx={{ mb: 2, width: 'fit-content', minWidth: '200px' }}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        {origEventData.multiSession && (
          <ErTextField
            fieldLabel={t(
              'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.FIELD_programHeadline',
            )}
            fieldName="programHeadline"
            size="small"
            variant="outlined"
            control={control}
            sx={{ mb: 2 }}
            readOnly={
              !hasPermission({
                objPath: 'event.settingsMenu',
                operation: 'update',
              })
            }
          />
        )}

        <ErTextField
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.FIELD_agendaHeadline',
          )}
          fieldName="agendaHeadline"
          size="small"
          variant="outlined"
          control={control}
          sx={{ mb: 2 }}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        <Box
          sx={{
            display: 'grid',
            columnGap: '10px',
            gridTemplateColumns: {
              xs: 'repeat(auto-fill, minmax(150px, auto));',
              sm: 'minmax(150px, auto) minmax(150px, auto) minmax(150px, auto);',
            },
          }}
        >
          <ErSwitch
            control={control}
            sx={{ flexShrink: 0 }}
            fieldLabel={t(
              'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.CHECKBOX_dateInTitle',
            )}
            fieldName="dateInTitle"
            size="small"
            labelPos="end"
            disabled={
              !hasPermission({
                objPath: 'event.settingsMenu',
                operation: 'update',
              })
            }
          />
          <ErSwitch
            control={control}
            sx={{ flexShrink: 0 }}
            fieldLabel={t(
              'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.CHECKBOX_locationInTitle',
            )}
            fieldName="locationInTitle"
            size="small"
            labelPos="end"
            disabled={
              !hasPermission({
                objPath: 'event.settingsMenu',
                operation: 'update',
              })
            }
          />
          <ErSwitch
            sx={{ flexShrink: 0 }}
            fieldLabel={t(
              'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.CHECKBOX_slotTitleInSecondaryColor',
            )}
            fieldName="slotTitleInSecondaryColor"
            control={control}
            size="small"
            labelPos="end"
            disabled={
              !hasPermission({
                objPath: 'event.settingsMenu',
                operation: 'update',
              })
            }
          />
        </Box>

        {/* <Stack
          direction={mediaQuery ? 'column' : 'row'}
          spacing={mediaQuery ? 0 : 0}
          // sx={{ mb: 2 }}
        >
          <Box sx={{ width: '50%' }}>
            <ErSwitch
              control={control}
              fieldLabel={t(
                'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.CHECKBOX_dateInTitle',
              )}
              fieldName="dateInTitle"
              size="small"
              labelPos="end"
            />
          </Box>
          <Box sx={{ width: '50%' }}>
            <ErSwitch
              control={control}
              fieldLabel={t(
                'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.CHECKBOX_locationInTitle',
              )}
              fieldName="locationInTitle"
              size="small"
              labelPos="end"
            />
          </Box>
        </Stack>

        <Stack
          direction={mediaQuery ? 'column' : 'row'}
          spacing={mediaQuery ? 0 : 0}
          sx={{ mb: 2 }}
        >
          <Box sx={{ width: '50%' }}>
            <ErSwitch
              fieldLabel={t(
                'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.CHECKBOX_slotTitleInSecondaryColor',
              )}
              fieldName="slotTitleInSecondaryColor"
              control={control}
              size="small"
              labelPos="end"
            />
          </Box>
          <Box sx={{ width: '50%' }}> */}
        {/* <ErSwitch
              fieldLabel={t(
                'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.CHECKBOX_displayModeratorInAgenda',
              )}
              fieldName="displayModeratorInAgenda"
              control={control}
              size="small"
              labelPos="end"
            /> */}
        {/* </Box>
        </Stack> */}
      </Stack>
    </>
  );
}
