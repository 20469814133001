import { QueryStatus } from '@tanstack/react-query';
import { create } from 'zustand';
import Parse from 'parse';

// type State = {
//   [key: string]: any;
// };

type State = {
  data: Parse.Object | null;
  status: QueryStatus;
  isSuccess: (state: State) => boolean;
  isError: (state: State) => boolean;
  isPending: (state: State) => boolean;
  setData: (data: Parse.Object) => void;
  updateData: (update: Record<string, any>) => void;
  setStatus: (status: QueryStatus) => void;
};

// init empty store
const useSessionStore = create<State>((set, get) => ({
  data: null,
  status: 'pending',
  isSuccess: (state: State) => state.status === 'success',
  isError: (state: State) => state.status === 'error',
  isPending: (state: State) => state.status === 'pending',
  setData: (data: any) => set({ data }), // !!! typing
  updateData: (data: any) => {
    const currentData = get().data;

    if (currentData && currentData instanceof Parse.Object) {
      const curDataClone = currentData.clone();
      curDataClone.set(data); // Update properties
      set({ data: curDataClone }); // Update store with modified object
    }
  },
  setStatus: (status: QueryStatus) => set({ status }),
}));

// // populate store
// export function populateAuthStore(data: any) {
//   useEventStore.setState(data);
// }

// export hook
export default useSessionStore;
