import {
  Navigate,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import MainNavigation from '../Navigation/MainNavigation';
import { SubNavigationParamsTypes } from '../Navigation/SubNavigationMain';
import useEventStore from '../../store/eventStore';
import { Suspense, useEffect } from 'react';
import { useFeedback } from '../Feedback/FeedbackContext';
import { useQuery } from '@tanstack/react-query';
import { getSessionsApi } from '../../api';
import EventSettingsModal from '../Event/EventSettingsModal';
import { Box } from '@mui/material';

export default function MainLayout({ menu }: SubNavigationParamsTypes) {
  // event & loading status
  // const eventDataStatus = useEventStore((state) => state.status);
  const { data: eventData, status: eventDataStatus } = useEventStore();

  // get path params
  const { eventId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // navigation
  const navigate = useNavigate();

  // feedback
  const { setFeedback } = useFeedback();

  //// side effects
  useEffect(() => {
    setFeedback({ type: 'loading' });
  }, []);

  useEffect(() => {
    if (eventDataStatus === 'success') {
      setFeedback(null);
    }
  }, [eventDataStatus]);

  // get the session for single session events
  const getSession = () => {
    return useQuery({
      queryKey: ['session', 'single', eventId],
      queryFn: async () => {
        return await getSessionsApi({
          eventId: eventId,
          itemsPerRequest: 1,
          currentPage: 0,
        });
      },
    });
  };

  const { data: singleSession } = getSession();

  useEffect(() => {
    // check for redirects due to single session
    if (eventDataStatus === 'success') {
      if (!eventData.multiSession && searchParams.get('fc')) {
        if (singleSession?.[0]) {
          navigate('sessions/' + singleSession[0].id + '/slots', {
            replace: true,
          });
        }
      }
    }
  }, [eventDataStatus, singleSession]);

  return (
    <>
      <MainNavigation menu={menu} />
      {eventDataStatus === 'success' && <Outlet />}
      <ModalWrapper />
    </>
  );
}

function ModalWrapper() {
  return <EventSettingsModal />;
}
