import { useForm, useWatch } from 'react-hook-form';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
} from '@mui/material';
import { ErTextField } from 'eventrocket-commoncomponents';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import { yupResolver } from '@hookform/resolvers/yup';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  eventDetailsCustomizationValidationSchema,
  eventDetailsCustomizationDefaults,
} from './eventDetailsCustomizationValidationSchema';
import useUserPermissions from '../../Hooks/useUserPermissions';

export default function EventDetailsFormLayoutBranding() {
  // props

  // translation
  const { t } = useTranslation('event');

  // permission handling
  const { hasPermission } = useUserPermissions();

  // event details context
  const { customizationData, updateCustomizationData } =
    useEventDetailsFormData();

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsCustomizationValidationSchema),
    defaultValues: {
      // ...eventDetailsCustomizationDefaults,
      // ...customizationData?.data,
      logo: customizationData?.data.logo,
      bannerImage: customizationData?.data.bannerImage,
      primaryColor: customizationData?.data.primaryColor,
      secondaryColor: customizationData?.data.secondaryColor,
      backgroundColor: customizationData?.data.backgroundColor,
    },
  });

  const watch = useWatch({ control });

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context

    if (!firstRun) {
      updateCustomizationData(getValues());
    } else {
      setFirstRun(false);
    }
  }, [watch]);

  return (
    <>
      {/* <style>{`
            div#color-popover.MuiPopover-root.MuiColorInput-Popover {
              z-index: 1400;
            }
        `}</style> */}

      <Stack sx={{ mt: 2 }}>
        <_FileUpload
          fullWidth
          control={control}
          fieldName="logo"
          fieldLabel={t('DETAILS-FORM.SECTION.BRANDING.FILEUPLOAD_logo')}
          error={errors.logo && getErrorMessage(errors, 'logo')}
          setValue={setValue}
          defaultValue={getValues!('logo')}
          imageResizerSettings={{
            maxWidth: 512,
            maxHeight: 512,
            minWidth: 512,
            minHeight: 512,
            jpegQuality: 80,
          }}
          sx={{ mb: 2 }}
          // disabled
          disabled={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        <_FileUpload
          fullWidth
          control={control}
          fieldName="bannerImage"
          fieldLabel={t('DETAILS-FORM.SECTION.BRANDING.FILEUPLOAD_banner')}
          error={errors.bannerImage && getErrorMessage(errors, 'bannerImage')}
          // error={errors?.logoFile && getErrorMessage(errors, 'logoFile')}
          setValue={setValue}
          defaultValue={getValues!('bannerImage')}
          imageResizerSettings={{
            maxWidth: 640,
            maxHeight: 640,
            minWidth: 640,
            minHeight: 640,
            jpegQuality: 80,
          }}
          sx={{ mb: 2 }}
          disabled={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        <_ColorPicker
          // fullWidth
          control={control}
          fieldLabel={t('DETAILS-FORM.SECTION.BRANDING.COLORPICKER_primary')}
          fieldName="primaryColor"
          size="small"
          error={
            errors?.primaryColor && getErrorMessage(errors, 'primaryColor')
          }
          setValue={setValue}
          sx={{ mb: 2, zIndex: 1401, width: 'fit-content', minWidth: '200px' }}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        <_ColorPicker
          // fullWidth
          control={control}
          fieldLabel={t('DETAILS-FORM.SECTION.BRANDING.COLORPICKER_secondary')}
          fieldName="secondaryColor"
          size="small"
          error={
            errors?.secondaryColor && getErrorMessage(errors, 'secondaryColor')
          }
          setValue={setValue}
          sx={{ mb: 2, zIndex: 1401, width: 'fit-content', minWidth: '200px' }}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        <_ColorPicker
          // fullWidth
          control={control}
          fieldLabel={t('DETAILS-FORM.SECTION.BRANDING.COLORPICKER_background')}
          fieldName="backgroundColor"
          size="small"
          error={
            errors?.backgroundColor &&
            getErrorMessage(errors, 'backgroundColor')
          }
          setValue={setValue}
          sx={{ mb: 2, zIndex: 1401, width: 'fit-content', minWidth: '200px' }}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />
      </Stack>
    </>
  );
}
