import { Box, Paper, useTheme } from '@mui/material';
import EventDetailsFormLayout from './Form/EventDetailsFormLayout';
import { EventDetailsFormContextProvider } from './Form/EventDetailsFormContext';
import useEventStore from '../../store/eventStore';
import EventDetailsSingleFormLayout from './Form/EventDetailsSingleFormLayout';

export type EventSettingsPropsTypes = {
  // eventData: Parse.Object;
  handleCancel?: () => void;
  activateSection?: string;
  singleForm?: string;
  submitCallback?: (state: 'success' | 'error') => void;
};

export default function EventSettings(props: EventSettingsPropsTypes) {
  // props
  const {
    handleCancel = () => {},
    activateSection,
    singleForm = '',
    submitCallback,
  } = props;

  // theme
  const theme = useTheme();

  // global store data
  const eventData = useEventStore((store) => store.data);
  const eventDataStatus = useEventStore((store) => store.status);

  return (
    <>
      <Paper
        sx={{
          //   p: 2,
          position: { xs: 'relative', sm: 'absolute' },
          top: { xs: '0', sm: '50%' },
          left: { xs: '0', sm: '50%' },
          transform: { xs: 'unset', sm: 'translate(-50%, -50%)' },
          // bgcolor: theme.palette.background.default,
          bgcolor: theme.palette.backgroundDark.default,
          backgroundImage: 'none',
          border: {
            xs: 'none !important',
            sm: '1px solid rgba(255,255,255,0.5) !important',
          },
        }}
      >
        <Box
          sx={{
            maxHeight: { xs: '100svh', sm: '90vh' },
            minHeight: { xs: 'unset', sm: '40vh' },
            width: { xs: '100vw', sm: '90vw' },
            height: { xs: '100svh', sm: 'unset' },
            maxWidth: { xs: '100svw', sm: 1050 },
            overflowY: 'scroll',
            overflowX: { xs: 'hidden', sm: 'unset' },
          }}
        >
          {eventDataStatus === 'success' && (
            <EventDetailsFormContextProvider
              eventData={eventData}
              handleCancel={handleCancel}
              activateSection={activateSection}
              submitCallback={submitCallback}
            >
              {singleForm === '' ? (
                <EventDetailsFormLayout />
              ) : (
                <EventDetailsSingleFormLayout />
              )}
            </EventDetailsFormContextProvider>
          )}
        </Box>
      </Paper>
    </>
  );
}
