import i18n from 'i18next';

export const getUserMenuAccess = async (section: string) => {
  await i18n.loadNamespaces('subMenu');

  switch (section) {
    case 'main':
      return [
        {
          path: 'sessions',
          active: true,
          title: i18n.t('subMenu:MAIN.sessions'),
          backLink: true,
        },
        // {
        //   path: 'actors',
        //   active: true,
        //   title: 'Akteure',
        //   backLink: false,
        // },
        // { path: 'media', active: true, title: 'Medien-Event', backLink: false },
      ];
    case 'session':
      return [
        {
          path: 'sessions/:sessionId/slots',
          active: true,
          title: i18n.t('subMenu:SESSION.slots'),
          backLink: true,
        },
        {
          path: 'sessions/:sessionId/actors',
          active: true,
          title: i18n.t('subMenu:SESSION.actors'),
          backLink: false,
        },
        // {
        //   path: 'sessions/:sessionId/options',
        //   active: true,
        //   title: 'Optionen',
        //   backLink: false,
        // },
        // {
        //   path: 'sessions/:sessionId/media',
        //   active: true,
        //   title: 'Medien-Session',
        //   backLink: false,
        // },
      ];
    default:
      break;
  }
};
