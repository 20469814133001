import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default async function getSlotsApi(sessionId: string, eventId?: string) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  const result = await Parse.Cloud.run(
    'getSlots',
    { sessionId: sessionId, eventId: eventId },
    { sessionToken: sessionToken },
  );

  //// data conversion
  // map the result to a regular obj
  const slotsMapped = result.map((slot: Parse.Object, index: number) => {
    const slotMapped = { ...slot.attributes };

    // get the id & the sessionId
    slotMapped.sessionId = slotMapped.session.id;
    slotMapped.id = slot.id;

    // delete the session object
    delete slotMapped.session;

    return slotMapped;
  });

  // return the mapped data
  return slotsMapped;
}
