import React, {
  useCallback,
  useState,
  useEffect,
  SyntheticEvent,
  ChangeEvent,
} from 'react';
import { useDropzone, DropEvent } from 'react-dropzone';
import {
  Box,
  Stack,
  CardMedia,
  IconButton,
  FormHelperText,
  IconButtonPropsColorOverrides,
  Palette,
} from '@mui/material';
import {
  AddAPhoto as AddPhotoIcon,
  Update as UpdateIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material';

export interface FileUploadParamsTypes {
  fullwidth?: boolean;
  required?: boolean;
  maxFileSize?: number;
  acceptedUploadFiles?: { [key: string]: string[] };
  // message: string;
  multiple?: boolean;
  maxFiles?: number;
  index?: number;
  name: string;
  error: boolean;
  helperText?: string;
  // defaultValue: string[] | undefined;
  defaultValue: (string | undefined)[];
  width?: number | string;
  height?: number | string;
  disabled: boolean;
  // defaultValueChangeAction: (files: string[]) => Blob[] | [];
  handleFiles?: (files: Blob[]) => Promise<void> | undefined;
  // !!! typing
  onChange?: (event: any[]) => void | null;
  color?: string;
  // refreshOnlyOnFirstRun?: boolean;
}

// the index of the clicked input item
let inIndex = 0;

function FileUpload(props: FileUploadParamsTypes) {
  // provide theme data
  const theme = useTheme();

  //// dropzone / file input settings
  const {
    fullwidth,
    multiple,
    acceptedUploadFiles,
    maxFileSize,
    // message,
    maxFiles = 1,
    name,
    handleFiles,
    onChange,
    index,
    defaultValue,
    // defaultValueChangeAction,
    error,
    helperText,
    width,
    height,
    color,
    disabled,
    // refreshOnlyOnFirstRun = true,
  } = props;

  //
  const [afiles, setAcceptedFiles] = useState<Blob[]>([]);
  const [firstRun, setFirstRun] = useState(true);

  // load existing files; values = array of img urls
  const defaultValueChangeAction = async (values: (string | undefined)[]) => {
    if (values && values.length > 0) {
      return await Promise.all(
        values.map(async (value, index) => {
          if (value) {
            return fetch(value).then((r) => r.blob());
          }
          return new Blob();
        }),
      );
    } else {
      return [];
    }
    // return blobValues;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback(
      (acceptedFiles: Blob[], rejectedFiles: any, event: DropEvent) => {
        // set / get the index on which a file was dropped / on which was clicked

        if (!disabled) {
          let dropEventId;

          // check if we have an event, if not the file was not dropped
          // but selected via the file dialog
          if (event) {
            dropEventId = (event.target as HTMLElement).getAttribute('id');
          }

          if (dropEventId) {
            inIndex = Number(dropEventId.replace(name, ''));
          }

          const dropIndex = inIndex;

          // remove the image from the imagelist
          const newFileList = [...afiles];
          newFileList.splice(dropIndex, 1);

          const filesToRemove =
            maxFiles - newFileList.length - acceptedFiles.length;

          if (filesToRemove < 0) {
            acceptedFiles.splice(filesToRemove, filesToRemove * -1);
          }

          newFileList.splice(dropIndex, 0, ...acceptedFiles);

          handleFiles && handleFiles(newFileList);
          setAcceptedFiles(newFileList);

          inIndex = 0;
        }
      },
      [afiles],
    ),
    onDropAccepted: useCallback(
      // !!! typing
      (event: any) => {
        if (!disabled) {
          onChange && onChange(event);
          onChange && setAcceptedFiles([]);
        }
      },
      [afiles],
    ),
    // onDropAccepted: (event: any) => {
    //   onChange && onChange(event);
    //   onChange && setAcceptedFiles([]);
    // },

    accept: acceptedUploadFiles,
    multiple,
    maxSize: maxFileSize,
    // message,
    maxFiles,
  });

  const rootProps = getRootProps({
    onClick: useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        if (disabled) {
          event.stopPropagation();
          event.preventDefault();
        }
        if (!disabled) {
          const targetElement = event.target as HTMLElement;

          let dropId = targetElement.getAttribute('id');

          if (!dropId && targetElement.offsetParent) {
            dropId = targetElement.offsetParent?.getAttribute('id');
          }

          if (dropId) {
            inIndex = Number(dropId.replace(name, ''));
          }
        }
      },
      [disabled],
    ),
    // onChange: (event: React.ChangeEvent<HTMLElement>) => {},
  });

  // render fileUpload Inputs
  const renderInputs = () => {
    const renderOutput = [];
    const counter =
      afiles?.length === 0
        ? 1
        : afiles?.length < maxFiles
        ? afiles?.length + 1
        : maxFiles;

    for (let i = 0; i < counter; i++) {
      renderOutput.push(
        <Box
          key={name + i}
          id={name + i}
          {...rootProps}
          sx={{
            width: width,
            height: height,
            position: 'relative',
            border: '2px solid',
            borderColor: error
              ? theme.palette.error.main
              : color
              ? color
              : theme.palette.grey[400],
            borderRadius: (theme) => theme.shape.borderRadius * 0.5,
            marginLeft: {
              '&:not(:first-of-type)': {
                marginLeft: 4,
              },
            },
          }}
        >
          {afiles[i] && (
            <Box sx={{ width: '100%', height: '100%' }}>
              <CardMedia
                component="img"
                image={URL.createObjectURL(afiles[i])}
                sx={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  borderRadius: (theme) => theme.shape.borderRadius * 0.5,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <IconButton sx={{ pointerEvents: 'none', color: color }}>
                  <UpdateIcon />
                </IconButton>
                <IconButton
                  sx={{ pointerEvents: 'auto', color: color }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!disabled) {
                      const csClone = [...afiles];
                      csClone.splice(i, 1);
                      handleFiles && handleFiles(csClone);
                      setAcceptedFiles(csClone);
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Box>
          )}

          {!afiles[i] && (
            <IconButton
              sx={{
                color: color,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
              }}
            >
              <AddPhotoIcon />
            </IconButton>
          )}
        </Box>,
      );
    }

    return renderOutput;
  };

  //// init
  // useEffect(() => {
  //   if (firstRun) {
  //     if (defaultValue === undefined) {
  //       // reset
  //       setAcceptedFiles([]);
  //     } else {
  //       const dVCA = async () => {
  //         const files = await defaultValueChangeAction(defaultValue);
  //         setAcceptedFiles(files);
  //       };

  //       // load existing files
  //       dVCA();
  //       // check if we should refresh on every rerender
  //       // refreshOnlyOnFirstRun prevents flickering in some situations
  //       if (refreshOnlyOnFirstRun) setFirstRun(false);
  //     }
  //   }
  // }, [defaultValue]);

  useEffect(() => {
    if (defaultValue === undefined) {
      // reset
      setAcceptedFiles([]);
    } else {
      const dVCA = async () => {
        const files = await defaultValueChangeAction(defaultValue);
        setAcceptedFiles(files);
      };

      // load existing files
      dVCA();
      setFirstRun(false);
    }
  }, [defaultValue]);

  return (
    <>
      <input {...getInputProps()} />
      <Stack direction="row" sx={{ width: fullwidth ? '100%' : 'unset' }}>
        {renderInputs()}
      </Stack>
      <FormHelperText error={error} component="p" sx={{ marginLeft: '14px' }}>
        {helperText}
      </FormHelperText>
    </>
  );
}

// defaults
FileUpload.defaultProps = {
  onChange: null,
  required: false,
  acceptedUploadFiles: {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
  },
  maxFileSize: 1024 * 1024 * 2, // 1MB
  // message: 'Select files',
  multiple: false,
  maxFiles: 1,
  index: 0,
  name: 'fileUpload',
  width: 64,
  height: 64,
};

export default FileUpload;
