import { Outlet, useParams } from 'react-router-dom';
import SubNavigationMain, {
  SubNavigationParamsTypes,
} from '../Navigation/SubNavigationMain';
import routesConfig from '../../Router/routesConfig';
import { Box, Container, Skeleton, Stack, Toolbar } from '@mui/material';
import useEventStore from '../../store/eventStore';
import { Suspense, useEffect, useState } from 'react';
import { SlotProvider } from '../Slots/Context/SlotContext';
import useSessionQueryHook from '../../data/session/session.query';
import useSessionStore from '../../store/sessionStore';
import useLiveQuery, {
  invalidateMultipleQueries,
} from '../../Hooks/useLiveQuery';
import useSlotsQueryHook from '../../data/slots/slots.query';
import useSlotsStore from '../../store/slotsStore';
import { useActorsQuery } from '../../Router/routes/actor';
import { useQueryClient } from '@tanstack/react-query';
import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default function SubLayoutSession({ menu }: SubNavigationParamsTypes) {
  // global state data
  const { data: eventData, status: eventDataStatus } = useEventStore();

  // navigation
  const { sessionId, masterEventId, eventId } = useParams();

  // get installationKey
  const { installationId } = useAuthStore();
  const { instanceId } = useAuthStore();
  //// initial data queries
  // get session details
  useSessionQueryHook(sessionId!, eventId);
  const { data: sessionData, status: statusSession } = useSessionStore();

  // opt in for live query for session
  // need also to invalidate slots for slotlist update to work
  useLiveQuery({
    queryKeywords: [
      { queryKey: ['session', 'details', sessionId!] },
      // { queryKey: ['slots', 'list', sessionId!] },
    ],
    queryParams: {
      equalTo: [
        { fieldName: 'updatedClass', value: 'Session' },
        { fieldName: 'updatedClassId', value: sessionId! },
      ],
      // notEqualTo: [
      //   { fieldName: 'updatingInstallation', value: installationId },
      // ],
      notEqualTo: [{ fieldName: 'updatingInstance', value: instanceId }],
    },
  });

  // get slot list
  useSlotsQueryHook(sessionId!, eventId);
  const { data: slotsData, status: statusSlots } = useSlotsStore();

  // opt in for live query for slots
  useLiveQuery({
    queryKeywords: [{ queryKey: ['slots', 'list', sessionId!] }],
    queryParams: {
      equalTo: [
        { fieldName: 'updatedClass', value: 'Slot' },
        { fieldName: 'updatedSessionId', value: sessionId! },
      ],
      // notEqualTo: [
      //   { fieldName: 'updatingInstallation', value: installationId },
      // ],
      notEqualTo: [{ fieldName: 'updatingInstance', value: instanceId }],
    },
  });

  // get session actors
  const {
    data: actorsSession,
    status: statusActorsSession,
  }: { data: any; status: any } = useActorsQuery(eventId!);

  const [displayContent, setDisplayContent] = useState(false);

  //// side effects
  useEffect(() => {
    return () => {
      // should not be necessary to remove queries
      // reset session store & query
      // queryClient.removeQueries({ queryKey: ['session', 'details'] });
      useSessionStore.setState({ data: null, status: 'pending' });

      // reset slot store & query
      // queryClient.removeQueries({ queryKey: ['session', 'details'] });
      useSlotsStore.setState({ data: null, status: 'pending' });
    };
  }, []);

  useEffect(() => {
    // make sure session list is not displayed for singleSession Events
    // !!! not nice should be handled earlier
    if (
      eventData.multiSession ||
      (!eventData.multiSession && menu === 'session')
    ) {
      setDisplayContent(true);
    } else {
      setDisplayContent(false);
    }
  }, [menu, eventData]);

  return (
    <>
      {displayContent && (
        <>
          <SubNavigationMain menu={menu} />
          {/* <SlotListDummy /> */}
          <>
            {statusSlots === 'success' && statusSession === 'success' && (
              <Suspense fallback={<SlotListDummy />}>
                <SlotProvider
                  session={sessionData}
                  slots={slotsData}
                  actorsSession={actorsSession}
                  eventId={eventId!}
                  masterEventId={masterEventId!}
                  sessionId={sessionId!}
                >
                  <Outlet />
                </SlotProvider>
              </Suspense>
            )}
          </>
        </>
      )}
    </>
  );
}

function SlotListDummy() {
  return (
    <>
      <Toolbar variant="dense" />
      <Container sx={{ height: 'calc(100% - 64px)' }}>
        <Stack direction="row" spacing={2} height="100%" p={4}>
          {/* <Box width="40%" height="100%"> */}
          <Skeleton variant="rounded" width="40%" height="100%" />
          {/* </Box> */}
          {/* <Box width="60%" height="100%"> */}
          <Skeleton variant="rounded" width="60%" height="100%" />
          {/* </Box> */}
        </Stack>
      </Container>
    </>
  );
}
