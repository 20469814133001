import Parse from 'parse';
import useAuthStore from '../../store/authStore';
import { convertAllIsoStringsToDateObjects } from '../../util/dateFormating';

// Level I Data handling
export default async function getEventApi(eventId: string) {
  // get the user form global store
  const user = useAuthStore.getState().user;

  const event: object = await Parse.Cloud.run(
    'getEvent',
    { id: eventId },
    { sessionToken: user.sessionToken },
  ).catch((e) => {
    console.error(e);
    throw new Error(e);
  });

  // const eventJson = event.parse.toJSON();

  // console.log('###123-ParseData', event.parse);
  // console.log('###123-jsonizedEventData', event.json);

  // convert all included dates to Date objects
  const timeConvertedObj = convertAllIsoStringsToDateObjects(event);

  // return event.parse;
  return timeConvertedObj;
}
