import Parse from 'parse';
import { NewSessionDataTypes } from '../../Components/Sessions/Form/sessionNewFormTypes';
import useAuthStore from '../../store/authStore';

export type setNewSessionApiParamsTypes = {
  eventId: string;
  sessionParams: NewSessionDataTypes;
};

export default async function setNewSessionApi(
  params: setNewSessionApiParamsTypes,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;
  const instanceId = useAuthStore.getState().instanceId;

  return Parse.Cloud.run(
    'setNewSession',
    {
      eventId: params.eventId,
      ...params.sessionParams,
      instanceId: instanceId,
    },
    {
      sessionToken: sessionToken,
    },
  );
}
