import { Theme, useTheme } from '@mui/material';
import {
  mediaDataTypes,
  slotDataTypes,
} from '../../Components/Slots/Context/SlotContext';
import { mediaIconStateTypes } from '../../Components/Slots/SlotListItem';

type getMediaStateParams = {
  media: mediaDataTypes[];
  mediaRequests?: boolean; // mediaRequests parameter could probably eliminated
  actorOptions?: any[]; // actorOptions of the slot / session to check for media requests
  actor?: Parse.Object; // check media requests for a specif actor? if not all mediarequests of the slot will be checked}
  sessionActors?: Parse.Object[];
  theme: Theme;
};

export default function getMediaState(params: getMediaStateParams) {
  const { media, mediaRequests, actorOptions, actor, sessionActors, theme } =
    params;

  // check which type of state to return
  let typeOfState: 'mediaItem' | 'slotItem' | 'actorItem' = 'mediaItem';

  if (!actor && !actorOptions) {
    // state of a specific media item; no actor involved
    typeOfState = 'mediaItem';
  }

  if (!actor && actorOptions) {
    // state of slot / session item; multiple actors involved
    typeOfState = 'slotItem';
  }

  if (actor && actorOptions) {
    // state of actor media; single actor involved
    typeOfState = 'actorItem';
  }

  // media icon start color
  const mediaIconState: mediaIconStateTypes = {
    mediaColor: theme.palette.warning.main,
    mediaActive: true,
  };

  // see if we need to check for mediaRequests
  const mediaRequestActorIds: any[] = [];
  const mediaRequestState: any[] = [];

  if (typeOfState === 'slotItem') {
    // no specific actor get all actorOptions that have a media request
    const actorOptionsWithMediaRequests = actorOptions?.filter(
      (actorOpt: any) => actorOpt?.requestMediaUpload === true,
    );

    actorOptionsWithMediaRequests?.forEach((actOpt: any) =>
      mediaRequestActorIds.push(actOpt.actorId),
    );

    // get the request state (undefined, send)
    actorOptionsWithMediaRequests?.forEach((actOpt: any) =>
      mediaRequestState.push(actOpt.requestMediaUploadState ?? undefined),
    );
  } else if (typeOfState === 'actorItem') {
    // specific actor only check the singele actor
    const specificActorOption = actorOptions?.find(
      (actorOpt: any) => actorOpt?.actorId === actor?.id,
    );

    if (specificActorOption?.requestMediaUpload === true) {
      mediaRequestActorIds.push(specificActorOption.actorId);
      mediaRequestState.push(specificActorOption.requestMediaUploadState);
    }
  }

  // check existing media
  if (media) {
    // media is an empty array
    if (media?.length === 0) {
      mediaIconState.mediaColor = theme.palette.action.disabled;
      mediaIconState.mediaActive = false;

      // no media? but maybe requested media
      if (mediaRequests) {
        // check if we only have not request sended media here
        // display icon in normal grey then (not deactivated)
        if (mediaRequestState.some((reqState) => reqState === 'send')) {
          mediaIconState.mediaColor = theme.palette.warning.main;
          mediaIconState.mediaActive = true;
        } else {
          mediaIconState.mediaColor = theme.palette.action.active;
          mediaIconState.mediaActive = true;
        }
      }
    } else {
      const allNewerVersionsunapproved = media?.every((media: any) => {
        const approvedFile = media
          ?.get('files')
          ?.find((file: any) => file.file.get('approved'));

        if (!approvedFile) {
          return false;
        }

        const approvedCreatedAt = new Date(
          approvedFile?.file?.get('createdAt'),
        );

        return media?.get('files')?.some((file: any) => {
          const fileCreatedAt = new Date(file.file.get('createdAt'));
          return (
            !file.file.get('approved') && fileCreatedAt > approvedCreatedAt
          );
        });
      });

      const allLatestVersionsApproved = media?.every((media: any) => {
        if (media?.get('files').length === 0) return false;
        const latestFile = media
          ?.get('files')
          ?.reduce((latest: any, file: any) => {
            return new Date(file.file.get('createdAt')) >
              new Date(latest.file.get('createdAt'))
              ? file
              : latest;
          });

        return latestFile?.file.get('approved');
      });

      if (allNewerVersionsunapproved) {
        mediaIconState.mediaColor = theme.palette.info.main;
      }

      if (allLatestVersionsApproved)
        mediaIconState.mediaColor = theme.palette.success.main;
    }
  } else {
    mediaIconState.mediaColor = theme.palette.action.disabled;
  }

  // done for media state
  if (typeOfState === 'mediaItem') {
    return mediaIconState;
  }

  // handle actor item media state
  if (typeOfState === 'actorItem') {
    // specific user
    const user = actor?.get('user');

    // check if there is media from the user
    const haveMediaFromUser = media?.some((medium: any) => {
      return medium.get('owner')?.id === user?.id;
    });

    // if there is media, display the media's state
    if (haveMediaFromUser) {
      return mediaIconState;
    }

    // if there is no request for the user return the  state
    if (!mediaRequests) {
      mediaIconState.mediaColor = theme.palette.action.disabled;
      return mediaIconState;
    }

    // get the actor's mediaRequestState
    const mediaRequestStateActor = actorOptions?.find(
      (mediaRqState) => mediaRqState.actorId === actor?.id,
    );

    if (mediaRequestStateActor?.requestMediaUploadState === 'send') {
      if (!haveMediaFromUser) {
        // at this stage this is always true
        mediaIconState.mediaColor = theme.palette.warning.main;
      }
      return mediaIconState;
    } else {
      mediaIconState.mediaColor = theme.palette.action.active;
      return mediaIconState;
    }
  }

  // check for slotlist item
  if (mediaRequestActorIds.length > 0 && sessionActors) {
    for (let index = 0; index < mediaRequestActorIds.length; index++) {
      // get user
      const user = sessionActors
        .find((actor: any) => actor.id === mediaRequestActorIds[index])
        ?.get('user');

      // check if the user has media uploaded
      const haveMediaFromUser = media?.some((medium: any) => {
        return medium.get('owner')?.id === user?.id;
      });

      if (mediaRequestState[index] === 'send') {
        if (!haveMediaFromUser) {
          mediaIconState.mediaColor = theme.palette.warning.main;
          break;
        }
      } else {
        if (mediaIconState.mediaColor === theme.palette.action.disabled) {
          mediaIconState.mediaColor = theme.palette.action.active;
        }
        // if media are available, but there are unsend mediaRequests show warning
        if (!haveMediaFromUser && media?.length > 0) {
          mediaIconState.mediaColor = theme.palette.warning.main;
          break;
        }
      }
    }
  }

  return mediaIconState;
}
