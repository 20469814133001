import { Box, Button, FormControlLabel, Select, Stack } from '@mui/material';
import { ErSelect } from 'eventrocket-commoncomponents';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type CrewChangeMemberRoleForm = {
  staffMember: Parse.Object | undefined;
  closeModalHandler: () => void;
  saveStaffRoleChange: (staffId: string, staffRole: string) => void;
};

export default function CrewChangeMemberRoleForm(
  props: CrewChangeMemberRoleForm,
) {
  // props
  const { staffMember, closeModalHandler, saveStaffRoleChange } = props;

  const role = staffMember?.get('roles')[0].split('_')[0];
  const defaultValues = { role: role };

  // translation
  const { t } = useTranslation('staff');

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
  } = useForm({
    // resolver: yupResolver(crewNewMemberFormDataValSchema),
    defaultValues: defaultValues,
  });

  const submitForm = (data: any) => {
    reset();
    // addNewStaff(data.name, data.email, data.role);
    if (staffMember) {
      saveStaffRoleChange(staffMember?.id, data.role);
    }

    if (closeModalHandler) {
      closeModalHandler();
    }
  };

  return (
    <>
      <Stack sx={{ mt: 2 }}>
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <Stack spacing={1}>
            <Box sx={{ px: '20px' }}>
              <ErSelect
                fullWidth
                variant="outlined"
                size="small"
                fieldLabel={t('ROLE_FORM_fieldLabel_role', 'Role')}
                fieldName="role"
                control={control}
                // error={errors?.role && getErrorMessage(errors, 'role')}
                menuItems={[
                  {
                    label: t('ROLE_eventManager', 'Event Manager'),
                    value: 'eventManager',
                  },
                  {
                    label: t('ROLE_eventRead', 'Event Viewer'),
                    value: 'eventRead',
                  },
                ]}
                sx={{ mb: 2 }}
              />
            </Box>

            <Box sx={{ p: '10px' }}>
              <Button
                type="submit"
                variant="outlined"
                fullWidth
                disabled={!isDirty}
                // color={saveButtonColor}
              >
                {t('ROLE_FORM_button_save', 'Save')}
              </Button>
            </Box>
          </Stack>
        </form>
      </Stack>
    </>
  );
}
