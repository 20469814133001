import { QueryStatus } from '@tanstack/react-query';
import { create } from 'zustand';

type State = {
  [key: string]: any;
};

// init empty store
const useEventStore = create<State>((set, get) => ({
  data: null,
  status: 'pending',
  // updateStartDate: async (newStartDate: Date) => {
  //   const { data } = get();

  //   if (data) {
  //     if (!data.get('multiSession')) {
  //       const endDate = new Date(newStartDate);
  //       endDate.setDate(endDate.getDate() + 1);
  //       endDate.setHours(1, 0, 0, 0);
  //       endDate.setMilliseconds(endDate.getMilliseconds() - 50);

  //       data.set('startdate', new Date(newStartDate));
  //       data.set('enddate', new Date(newStartDate));
  //       set({ data });
  //     }
  //   }
  // },
  isSuccess: (state: State) => state.status === 'success',
  isError: (state: State) => state.status === 'error',
  isPending: (state: State) => state.status === 'pending',
  setData: (data: any) => set({ data }), // !!! typing
  // updateData: (data: any) => {
  //   const currentData = get().data;

  //   if (currentData && currentData instanceof Parse.Object) {
  //     const curDataClone = currentData.clone();
  //     curDataClone.set(data); // Update properties
  //     set({ data: curDataClone }); // Update store with modified object
  //   }
  // },
  updateData: (data: any) => {
    const currentData = get().data;

    // if (currentData && currentData instanceof Parse.Object) {
    // const curDataClone = currentData.clone();
    const newData = { ...currentData, ...data }; // Update properties
    set({ data: newData }); // Update store with modified object
    // }
  },
  setStatus: (status: QueryStatus) => set({ status }),
}));

// // populate store
// export function populateAuthStore(data: any) {
//   useEventStore.setState(data);
// }

// export hook
export default useEventStore;
