import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type EventDetailsFormContextProviderPropsTypes = {
  children: ReactNode;
  eventData: any;
  handleCancel?: () => void;
  activateSection?: string;
  submitCallback?: (state: 'success' | 'error') => void;
};

type EventDetailsFormContextTypes = {
  handleCancel: () => void;
  eventId: string | undefined;
  activateSection?: string;
  origEventData: basicDataTypes;
  basicData: basicDataObjTypes;
  updateBasicData: (data: any, isDirty?: boolean) => void;
  metaData: metaDataObjTypes | undefined;
  updateMetaData: (data: any, isDirty?: boolean) => void;
  customizationData: customizationDataObjTypes | undefined;
  updateCustomizationData: (data: any, isDirty?: boolean) => void;
  marketingData: marektingDataObjTypes | undefined;
  updateMarketingData: (data: any, isDirty?: boolean) => void;
  featureData: featureDataObjTypes | undefined;
  updateFeatureData: (data: any, isDirty?: boolean) => void;
  mediaData: mediaDataObjTypes | undefined;
  updateMediaData: (data: any, active: boolean, isDirty?: boolean) => void;
  monetData: monetDataObjTypes | undefined;
  updateMonetData: (data: any, active: boolean, isDirty?: boolean) => void;
  registrationData: registrationDataObjTypes | undefined;
  updateRegistrationData: (
    data: any,
    active: boolean,
    isDirty?: boolean,
  ) => void;
  onlineData: onlineDataObjTypes | undefined;
  updateOnlineData: (data: any, active: boolean, isDirty?: boolean) => void;
  eventSettingsIsDirty: boolean;
  updateEventSettingsIsDirty: (state: boolean) => void;
  submitCallback?: (state: 'success' | 'error') => void;
};

type basicDataTypes = any;
// {
//   title: string;
//   startdate: Date;
//   enddate: Date;
//   type: string;
//   logo: string[];
//   location: string;
//   eventCode: string;
//   timezone: string;
// };
type basicDataObjTypes = { data: basicDataTypes; isDirty: boolean };
type metaDataObjTypes = { data: any; isDirty: boolean };
type customizationDataObjTypes = { data: any; isDirty: boolean };
type marektingDataObjTypes = { data: any; isDirty: boolean };
type featureDataObjTypes = { data: any; isDirty: boolean };
type mediaDataObjTypes = { data: any; active: boolean; isDirty: boolean };
type monetDataObjTypes = { data: any; active: boolean; isDirty: boolean };
type registrationDataObjTypes = {
  data: any;
  active: boolean;
  isDirty: boolean;
};
type onlineDataObjTypes = { data: any; active: boolean; isDirty: boolean };

const EventDetailsFormContext =
  createContext<EventDetailsFormContextTypes | null>(null);

export function EventDetailsFormContextProvider(
  props: EventDetailsFormContextProviderPropsTypes,
) {
  // props
  const {
    children,
    eventData,
    handleCancel = () => {},
    activateSection,
    submitCallback,
  } = props;

  console.log('#!#!-eventData', eventData);

  //// state
  // any changedData
  const [eventSettingsIsDirty, setEventSettingsIsDirty] = useState(false);
  const updateEventSettingsIsDirty = (state: boolean) =>
    setEventSettingsIsDirty(state);

  // eventId
  const [eventId, setEventId] = useState<string | undefined>();

  //// basic data
  const origEventData = { ...eventData };
  delete origEventData.meta;
  delete origEventData.customization;
  delete origEventData.marketing;
  // delete eDate.features_active;

  // const origData = useMemo(() => origEventData, [eventData]);

  const [basicData, setBasicData] = useState<basicDataObjTypes>({
    data: origEventData,
    isDirty: false,
  });

  const updateBasicData = (data: any, isDirty?: boolean) => {
    setBasicData((bd) => ({
      data: { ...bd.data, ...data },
      isDirty: isDirty !== undefined ? isDirty : true,
    }));
  };

  //// meta data
  let mData = undefined;
  if (eventData.meta) {
    mData = eventData.meta.attributes;
  }

  const [metaData, setMetaData] = useState<metaDataObjTypes | undefined>({
    data: mData,
    isDirty: false,
  });

  const updateMetaData = (data: any, isDirty?: boolean) => {
    setMetaData((md) => ({
      data: { ...md?.data, ...data },
      isDirty: isDirty !== undefined ? isDirty : true,
    }));
  };

  //// customization data
  let cData = undefined;
  if (eventData.customization) {
    cData = eventData.customization.attributes;
  }

  const [customizationData, setCustomizationData] = useState<
    customizationDataObjTypes | undefined
  >({
    data: cData,
    isDirty: false,
  });

  const updateCustomizationData = (data: any, isDirty?: boolean) => {
    setCustomizationData((cd) => ({
      data: { ...cd?.data, ...data },
      isDirty: isDirty !== undefined ? isDirty : true,
    }));
  };

  //// marketing data
  let maData = undefined;
  if (eventData.marekting) {
    maData = eventData.marketing.attributes;
  }

  const [marketingData, setMarketingData] = useState<
    customizationDataObjTypes | undefined
  >({
    data: maData,
    isDirty: false,
  });

  const updateMarketingData = (data: any, isDirty?: boolean) => {
    setMarketingData((md) => ({
      data: { ...md?.data, ...data },
      isDirty: isDirty !== undefined ? isDirty : true,
    }));
  };

  //// feature data
  let fData = undefined;
  if (eventData.features_active) {
    fData = eventData.features_active;
  }

  const [featureData, setFeatureData] = useState<
    featureDataObjTypes | undefined
  >({
    data: fData,
    isDirty: false,
  });

  const updateFeatureData = (data: any, isDirty?: boolean) => {
    setFeatureData((fd) => ({
      data: { ...fd?.data, ...data },
      isDirty: isDirty !== undefined ? isDirty : true,
    }));
  };

  // additional flag active for paid features
  // media mng
  let meData = undefined;
  if (fData && fData.hasOwnProperty('mediaMng') && fData.mediaMng.length > 0) {
    meData = fData.mediaMng[0].settingsObj;
  }
  const [mediaData, setMediaData] = useState<mediaDataObjTypes | undefined>({
    data: meData,
    active: meData ? true : false,
    isDirty: false,
  });

  const updateMediaData = (data: any, active: boolean, isDirty?: boolean) => {
    setMediaData((mediad) => ({
      data: { ...mediad?.data, ...data },
      active: active,
      isDirty: isDirty !== undefined ? isDirty : true,
    }));
  };

  // monetization
  let monData = undefined;
  if (fData && fData.hasOwnProperty('monet') && fData.monet.length > 0) {
    monData = fData.monet[0].settingsObj;
  }
  const [monetData, setMonetData] = useState<monetDataObjTypes | undefined>({
    data: monData,
    active: monData ? true : false,
    isDirty: false,
  });

  const updateMonetData = (data: any, active: boolean, isDirty?: boolean) => {
    setMonetData((moned) => ({
      data: { ...moned?.data, ...data },
      active: active,
      isDirty: isDirty !== undefined ? isDirty : true,
    }));
  };

  // registration
  let regData = undefined;

  if (
    fData &&
    fData.hasOwnProperty('registration') &&
    fData.registration.length > 0
  ) {
    regData = fData.registration[0].settingsObj;
  }

  const [registrationData, setRegistrationData] = useState<
    registrationDataObjTypes | undefined
  >({
    data: regData,
    active: regData ? true : false,
    isDirty: false,
  });

  const updateRegistrationData = (
    data: any,
    active: boolean,
    isDirty?: boolean,
  ) => {
    setRegistrationData((reged) => ({
      data: { ...reged?.data, ...data },
      active: active,
      isDirty: isDirty !== undefined ? isDirty : true,
    }));
  };

  // online stage
  let osData = undefined;
  if (
    fData &&
    fData.hasOwnProperty('onlineStage') &&
    fData.onlineStage.length > 0
  ) {
    osData = fData.onlineStage[0].settingsObj;
  }
  const [onlineData, setOnlineData] = useState<onlineDataObjTypes | undefined>({
    data: osData,
    active: osData ? true : false,
    isDirty: false,
  });

  const updateOnlineData = (data: any, active: boolean, isDirty?: boolean) => {
    setOnlineData((on) => ({
      data: { ...on?.data, ...data },
      active: active,
      isDirty: isDirty !== undefined ? isDirty : true,
    }));
  };

  //// functions
  // set the final context values
  const value = {
    eventId: eventId,
    activateSection: activateSection,
    origEventData: useMemo(() => origEventData, [eventData]),
    basicData: basicData,
    updateBasicData: updateBasicData,
    metaData: metaData,
    updateMetaData: updateMetaData,
    customizationData: customizationData,
    updateCustomizationData: updateCustomizationData,
    marketingData: marketingData,
    updateMarketingData: updateMarketingData,
    featureData: featureData,
    updateFeatureData: updateFeatureData,
    mediaData: mediaData,
    updateMediaData: updateMediaData,
    monetData: monetData,
    updateMonetData: updateMonetData,
    registrationData: registrationData,
    updateRegistrationData: updateRegistrationData,
    onlineData: onlineData,
    updateOnlineData: updateOnlineData,
    handleCancel: handleCancel,
    eventSettingsIsDirty: eventSettingsIsDirty,
    updateEventSettingsIsDirty: updateEventSettingsIsDirty,
    submitCallback: submitCallback,
  };

  //// side effects
  // set initial form data &
  // update on changes
  useEffect(() => {
    // eventId
    setEventId(eventData.objectId);

    // basic data
    if (eventData) {
      const tmpBasicData = { ...eventData };
      delete tmpBasicData.meta;
      delete tmpBasicData.customization;
      delete tmpBasicData.marketing;

      updateBasicData(tmpBasicData, false);
      updateMetaData(eventData.meta, false);
      updateCustomizationData(eventData.customization, false);
      updateMarketingData(eventData.marketing, false);

      const fData = eventData.features_active;
      updateFeatureData(fData, false);

      if (fData?.media && fData?.media.length > 0) {
        const mediaSettings = fData.media[0].settingsObj;
        updateMonetData(mediaSettings, mediaSettings ? true : false, false);
      }

      if (fData?.monet && fData?.monet.length > 0) {
        const monetSettings = fData.monet[0].settingsObj;
        updateMonetData(monetSettings, monetSettings ? true : false, false);
      }

      if (fData?.registration && fData?.registration.length > 0) {
        const registrationSettings = fData.registration[0].settingsObj;
        updateRegistrationData(
          registrationSettings,
          registrationSettings ? true : false,
          false,
        );
      } else if (!fData?.registration || fData?.registration.length === 0) {
        updateRegistrationData({}, false, false);
      }

      if (fData?.onlineStage && fData?.onlineStage.length > 0) {
        const onlineStategSettings = fData.onlineStage[0].settingsObj;
        updateOnlineData(
          onlineStategSettings,
          onlineStategSettings ? true : false,
          false,
        );
      } else if (!fData?.onlineStage || fData?.onlineStage.length === 0) {
        updateOnlineData({}, false, false);
      }
    }
  }, [
    eventData,
    eventData.features_active,
    eventData.features_active?.onlineStage,
    eventData.features_active?.registration,
  ]);

  // update eventSettingsIsDirty
  useEffect(() => {
    const dataArray = [
      basicData,
      metaData,
      customizationData,
      featureData,
      mediaData,
      monetData,
      registrationData,
      onlineData,
      marketingData,
    ];

    // if (!eventSettingsIsDirty) {
    if (dataArray.some((data) => data?.isDirty === true)) {
      setEventSettingsIsDirty(true);
    } else {
      setEventSettingsIsDirty(false);
    }
    // }
  }, [
    basicData,
    metaData,
    customizationData,
    featureData,
    mediaData,
    monetData,
    registrationData,
    onlineData,
    marketingData,
  ]);

  return (
    <EventDetailsFormContext.Provider value={value}>
      {children}
    </EventDetailsFormContext.Provider>
  );
}

export const useEventDetailsFormData = (): EventDetailsFormContextTypes => {
  const context = useContext(EventDetailsFormContext);

  // make sure we return a valid context
  if (!context) {
    throw new Error(
      'useEventDetailsFormData must be used within a EventDetailsFormContext Provider',
    );
  }

  return context;
};
