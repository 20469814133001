import React from 'react';
import { FormControl, SxProps } from '@mui/material';
import { FieldValues, Path, Control, Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { de } from 'date-fns/locale';
import { ErDatePickerTypes } from './ErDatePicker.types';

/**
 * DatePicker Component
 * @param props 
 * @returns a DatePicker component to be directly used in react-hook-form contexts
 */
export default function ErDatePicker<T extends FieldValues>(
  props: ErDatePickerTypes<T>,
) {
  const {
    fullWidth,
    fieldName,
    control,
    defaultValue,
    error,
    disabled,
    fieldLabel,
    textFieldVariant,
    textFieldSize,
    sx,
    readOnly
  } = props;

  return (
    <FormControl fullWidth={fullWidth}>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
            <DatePicker
            readOnly={readOnly}
            disabled={disabled}
              sx={sx}
              label={fieldLabel}
              slotProps={{
                textField: {
                  error: Boolean(error),
                  helperText: error && error,
                  variant: textFieldVariant,
                  size: textFieldSize,
                },
              }}
              {...field}
            />
          </LocalizationProvider>
        )}
      />
    </FormControl>
  );
}
