import { useQuery } from '@tanstack/react-query';
import { getStaffApi } from '../../api';

// react-query query definition
const staffQuery = (eventId: string, roleName?: string) => ({
  queryKey: ['staff', 'list', eventId, roleName],
  queryFn: async () => await getStaffApi(eventId, roleName),
  refetchOnWindowFocus: false,
});

export function useStaffQuery(eventId: string, role?: string) {
  return useQuery(staffQuery(eventId, role));
}
