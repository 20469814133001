import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Alert,
  Box,
  Divider,
  IconButton,
  List,
  Stack,
  SxProps,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import MediaListItem from './MediaListItem';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { MediaFileUpdateTypes, MediaUpdateTypes } from '../Slots/Slots';
import { useParams } from 'react-router-dom';
import useEventStore from '../../store/eventStore';
import FolderZipOutlinedIcon from '@mui/icons-material/FolderZipOutlined';
import downloadMediaFilesApi, {
  downloadMediaFilesApiParamsTypes,
  downloadType,
  parentClass,
} from '../../api/media/downloadMediaFilesApi';
import { downloadFile } from '../../util/fileActions';
import { useFeedback } from '../Feedback/FeedbackContext';
import MediaListDownloadPopover from './MediaDownload/MediaListDownloadPopover';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import useUserPermissions from '../Hooks/useUserPermissions';
import { useTranslation } from 'react-i18next';
import { mediaDataTypes } from '../Slots/Context/SlotContext';

export type MediaListPropsTypes = {
  media: any[];
  addNewMedia?: (fileName: string) => void;
  addNewMediaFileBased?: (files: any[], parentClassName: string) => void;
  addNewMediaVersion?: (
    activeMediaId: string | null,
    files: FileList,
  ) => Promise<mediaDataTypes>;
  deleteMediaVersion?: (
    activeMediaId: string | null,
    mediaFileId: string,
  ) => void;
  deleteMedia?: (activeMediaId: string | null) => void;
  updateMedia?: (updateData: MediaUpdateTypes) => void;
  updateMediaFile?: (updateData: MediaFileUpdateTypes) => void;
  updateMediaOrder?: (
    data: string[],
    invalidateQuery?: boolean,
  ) => Promise<any>;
  mediaquery?: boolean;
  sx?: SxProps;
  detailsEditing?: string;
  activeEditId?: string | undefined;
  parentClass?: parentClass;
  parentId?: string;
  variant?: 'reduced' | 'full';
};

export type MediaDataTypes = {
  id: string;
  title: string;
  type: string;
};

export interface downloadMediaFilesDataTypes
  extends Omit<downloadMediaFilesApiParamsTypes, 'downloadType'> {}

export default function MediaList(props: MediaListPropsTypes) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const events = window.fsEvents;

  // props
  const {
    media,
    addNewMedia = () => {},
    addNewMediaVersion = async () => {},
    deleteMedia = () => {},
    deleteMediaVersion = () => {},
    updateMediaFile = () => {},
    updateMediaOrder = () => {},
    sx,
    detailsEditing = 'slots',
    activeEditId,
    parentClass,
    parentId,
    variant = 'full',
  } = props;

  // url params
  const { sessionId, eventId } = useParams();

  // global store
  const paidFeatures = useEventStore((state) => state.data?.features_active);

  // permissions
  const { hasPermission } = useUserPermissions();

  // feedback
  const feedback = useFeedback();

  // translation
  const { t } = useTranslation('media');

  // media queries
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const downloadMediaFilesData: downloadMediaFilesDataTypes = {
    parentClass: parentClass || 'Slot',
    parentId: parentId || '',
    eventId: eventId!,
  };

  // media management activated?
  const [mediaMngActive, setMediaMngActive] = useState(true);

  const [activateMedieMngLink, setActivateMedieMngLink] = useState(
    mediaQuery ? 'mediaMng' : 'features',
  );

  // state
  const [items, setItems] = useState<any[]>([]);

  const [oldItems, setOldItems] = useState<MediaDataTypes[]>([]);
  const [newItem, setNewItem] = useState<MediaDataTypes | undefined>();

  //
  const [activeId, setActiveId] = useState(null);

  // id of the item that is expanded
  const [itemOpen, setItemOpen] = useState();

  // id of the item that has active details editing (title,...)
  const [isEditingDetailsId, setIsEditingDetailsId] = useState(null);

  // new media ui active?
  const [newMediaActive, setNewMediaActive] = useState(false);
  const [newMediaName, setNewMediaName] = useState('');

  //// paid options modal
  const [paidOptionsModal, setPaidOptionsModal] = useState(false);

  const togglePaidOptionsModal = () => {
    // close the download overlay if open
    downloadOverlayOpen && toggleDownloadOverlayOpen();
    setPaidOptionsModal(!paidOptionsModal);
  };

  // download overlay
  const downloadOverlayRef = useRef(null);
  const [downloadOverlayOpen, setDownloadOverlayOpen] = useState(false);

  const toggleDownloadOverlayOpen = () => {
    setDownloadOverlayOpen(!downloadOverlayOpen);
  };

  // activate the form for adding new media
  const handleNewMedia = () => {
    setNewMediaActive(true);
  };

  // save new media
  const saveNewMedia = () => {
    if (newMediaName && newMediaName !== '') {
      addNewMedia(newMediaName);
      setNewMediaActive(false);
      setNewMediaName('');
    }
  };

  const deleteMediaWrapper = (activeMediaId: string | null) => {
    deleteMedia(activeMediaId);
  };

  // delete item wrapper
  const deleteVersion = (mediafileId: string) => {
    deleteMediaVersion(activeId, mediafileId);
  };

  // download media handler
  const downloadHandler = (type: downloadType) => {
    // display loading indicator
    feedback.setFeedback({
      headline: t('LIST.FEEDBACK_preparingDownload'),
      type: 'loading',
    });

    downloadMediaFilesApi({
      ...downloadMediaFilesData,
      downloadType: type,
    })
      .then((res: string) => {
        downloadFile(res);
        feedback.setFeedback(null);
      })
      .catch((e: Error) => {
        feedback.setFeedback({
          type: 'error',
          errorCode: e,
          autoclose: true,
        });
        console.error(e);
      });
  };

  // theme
  const theme = useTheme();

  // dnd setup
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
  );

  function handleDragStart(event: any) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setItems((items: any[]) => {
        const oldIndex = items.map((media: any) => media.id).indexOf(active.id);
        const newIndex = items.map((media: any) => media.id).indexOf(over.id);

        const resortedItems = arrayMove(items, oldIndex, newIndex);

        // get the sorting of the media
        const mediaIds: string[] = [];

        resortedItems.forEach((mediaObj: Parse.Object) =>
          mediaIds.push(mediaObj.id),
        );

        updateMediaOrder(mediaIds);

        return resortedItems;
      });
    }
  }

  // check the permission to the create media button
  const checkCreateMediaButtonPermission = () => {
    if (detailsEditing === 'slots') {
      return hasPermission({
        objPath: 'slots.slot.media',
        operation: 'create',
        context: {
          slotId: activeEditId,
        },
      });
    } else {
      return hasPermission({
        objPath: 'sessions.media',
        operation: 'create',
        context: {
          sessionId: sessionId,
        },
      });
    }
  };

  useEffect(() => {
    // update the paid features state for the active session
    if (paidFeatures?.mediaMng && paidFeatures?.mediaMng.length > 0) {
      setMediaMngActive(true);
    } else {
      setMediaMngActive(false);
    }
  }, [paidFeatures]);

  // update the list items
  useEffect(() => {
    setItems(media);
  }, [media]);

  // update the name in the new media UI
  useEffect(() => {
    if (!newMediaActive) {
      setNewMediaName('');
    }
  }, [newMediaActive]);

  // unset active media if type of item (slot or session) changes
  useEffect(() => {
    setNewMediaActive(false);
  }, [activeEditId]);

  return (
    <>
      <Stack sx={{ alignItems: 'flex-start', ...sx }}>
        {/* BUY NOW */}
        {!mediaMngActive && variant === 'full' && (
          <Alert
            icon={<GradeOutlinedIcon />}
            severity="info"
            sx={{ width: '100%', mt: 1, cursor: 'pointer' }}
            onClick={() => {
              events?.publish('toggleEventMenu', {
                activateSection: activateMedieMngLink,
              });
            }}
          >
            {t('LIST.ALERT_mediaManagementBookNow')}
          </Alert>
        )}

        {/* DND List */}
        {
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              <List
                sx={{
                  mt: variant === 'full' ? 1 : 0,
                  p: 0,
                  borderRadius: theme.shape.borderRadius / 2,
                  width: '100%',
                  maxHeight: {
                    xs: 'calc(100vh - 132px)',
                    sm: 'calc(100vh - 280px)',
                  },
                  overflowX: 'clip',
                  overflowY: 'scroll',
                }}
              >
                {items.map((mediaData: any) => (
                  <MediaListItem
                    key={mediaData.id}
                    id={mediaData.id}
                    active={mediaData.id === activeId}
                    setActiveId={setActiveId}
                    itemOpen={itemOpen}
                    setItemOpen={setItemOpen}
                    mediaData={mediaData}
                    deleteVersion={deleteVersion}
                    updateMediaFile={updateMediaFile}
                    addNewMediaVersion={addNewMediaVersion}
                    deleteMedia={deleteMedia}
                    isEditingDetailsId={isEditingDetailsId}
                    setIsEditingDetailsId={setIsEditingDetailsId}
                    sessionId={sessionId!}
                    eventId={eventId!}
                    newItemId={newItem?.id}
                    mediaMngActive={mediaMngActive}
                    variant={variant}
                  />
                ))}
                <li></li>
              </List>
            </SortableContext>
          </DndContext>
        }

        <Divider sx={{ my: 1 }} />

        {newMediaActive && (
          <Box sx={{ px: { xs: 1, md: 0 }, width: '100%' }}>
            <TextField
              label={t('LIST.FIELD_labelCreateNewMedia')}
              size="small"
              fullWidth
              autoFocus
              value={newMediaName}
              onChange={(event) => setNewMediaName(event.currentTarget.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  saveNewMedia();
                }
              }}
              InputProps={{
                endAdornment: (
                  <Stack direction="row">
                    <IconButton
                      size="small"
                      onClick={() => {
                        saveNewMedia();
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setNewMediaActive(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                ),
              }}
            />
          </Box>
        )}
        {!newMediaActive && variant === 'full' && (
          <>
            {checkCreateMediaButtonPermission() && (
              <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                <IconButton
                  color="primary"
                  sx={{
                    flex: 'auto',
                    border: '2px solid',
                    borderColor: theme.palette.primary.main,
                    borderRadius: theme.shape.borderRadius / 2,
                  }}
                  onClick={() => {
                    handleNewMedia();
                  }}
                >
                  <NoteAddOutlinedIcon />
                </IconButton>

                <IconButton
                  color="primary"
                  sx={{
                    flex: 'auto',
                    border: '2px solid',
                    borderColor: theme.palette.primary.main,
                    borderRadius: theme.shape.borderRadius / 2,
                  }}
                  onClick={() => {
                    toggleDownloadOverlayOpen();
                  }}
                  ref={downloadOverlayRef}
                >
                  <FolderZipOutlinedIcon />
                </IconButton>
              </Stack>
            )}
          </>
        )}

        {/* Download modal */}
        <MediaListDownloadPopover
          open={downloadOverlayOpen}
          toggleOpen={toggleDownloadOverlayOpen}
          togglePaidOptionsModal={togglePaidOptionsModal}
          anchorRef={downloadOverlayRef}
          featurePaid={mediaMngActive}
          downloadHandler={downloadHandler}
          position={{
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            transformOrigin: { vertical: 'bottom', horizontal: 'center' },
          }}
          headline={
            detailsEditing === 'slots'
              ? t('LIST.HEADLINE_downloadSlotMedia')
              : t('LIST.HEADLINE_downloadSessionMedia')
          }
          subline={t('LIST.SUBLINE_downloadLatestMedia')}
        />
      </Stack>
    </>
  );
}
