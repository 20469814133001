import { Controller, useForm, useWatch } from 'react-hook-form';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import {
  Box,
  Collapse,
  FormControl,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import {
  ErDatePicker,
  ErEventDurationOptions,
  ErEventTypeOptions,
  ErHtmlEditor,
  ErSelect,
  ErSwitch,
  ErTextField,
  ErTimePickerDesktop,
} from 'eventrocket-commoncomponents';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  eventDetailsBasicValidationSchema,
  eventDetailsBasicDefaults,
} from './eventDetailsBasicValidationSchema';
import { useTranslation } from 'react-i18next';
import timezonesSelectList from '../../../util/timezones';
import { useFeedback } from '../../Feedback/FeedbackContext';
import useUserPermissions from '../../Hooks/useUserPermissions';

// import '../../../util/htmlEditor/themes/bsDark.css';

export default function EventDetailsFormLayoutBasic() {
  // props

  // feedback
  const { setFeedback } = useFeedback();

  // translation
  const { t } = useTranslation('event');

  // permission handling
  const { hasPermission } = useUserPermissions();

  // event details context
  const { origEventData, basicData, updateBasicData } =
    useEventDetailsFormData();

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(eventDetailsBasicValidationSchema),
    defaultValues: { ...eventDetailsBasicDefaults, ...origEventData },
  });

  const watch = useWatch({ control });

  const multiDay = useWatch({ name: 'multiDay', control });
  const multiSession = useWatch({ name: 'multiSession', control });

  // state
  const [firstRun, setFirstRun] = useState(true);
  const [externalUpdate, setExternalUpdate] = useState(false);

  //// side effects
  useEffect(() => {
    // save changed form data to context
    if (!firstRun && !externalUpdate) {
      updateBasicData(getValues());
    } else {
      setFirstRun(false);
      setExternalUpdate(false);
    }
  }, [watch]);

  // trigger rerenders on external changes
  useEffect(() => {
    if (!firstRun) {
      setExternalUpdate(true);
      reset({ ...eventDetailsBasicDefaults, ...origEventData });
    }
  }, [origEventData]);

  useEffect(() => {
    if (!firstRun) {
      if (multiDay && !multiSession && !origEventData.multiSession) {
        setFeedback({
          type: 'confirmAction',
          headline: t('DETAILS-FORM.SECTION.BASIC.FEEDBACK.MULTIDAY.headline'),
          message: t('DETAILS-FORM.SECTION.BASIC.FEEDBACK.MULTIDAY.message'),
          confirmButtons: {
            confirm: t('DETAILS-FORM.SECTION.BASIC.FEEDBACK.BUTTON_confirm'),
            cancel: t('DETAILS-FORM.SECTION.BASIC.FEEDBACK.BUTTON_cancel'),
          },
          callback: () => {
            setValue('duration', 2);
            setValue('multiSession', true);
          },
          callbackCancel: () => {
            setValue('multiDay', false);
          },
        });
      }
    }
  }, [multiDay]);

  useEffect(() => {
    if (multiSession && !multiDay && !origEventData.multiSession) {
      setFeedback({
        type: 'confirmAction',
        headline: t(
          'DETAILS-FORM.SECTION.BASIC.FEEDBACK.MULTISESSION.headline',
        ),
        message: t('DETAILS-FORM.SECTION.BASIC.FEEDBACK.MULTISESSION.message'),
        confirmButtons: {
          confirm: t('DETAILS-FORM.SECTION.BASIC.FEEDBACK.BUTTON_confirm'),
          cancel: t('DETAILS-FORM.SECTION.BASIC.FEEDBACK.BUTTON_cancel'),
        },
        callback: () => {
          setValue('multiSession', true);
        },
        callbackCancel: () => {
          setValue('multiSession', false);
        },
      });
    }
  }, [multiSession]);

  return (
    <>
      {/* <Box height="1280px" width="100%" bgcolor="red"></Box> */}
      <Stack sx={{ mt: 2 }}>
        <ErTextField
          size="small"
          control={control}
          fieldLabel={t('DETAILS-FORM.SECTION.BASIC.FIELD_title')}
          fieldName="title"
          sx={{ mb: 2 }}
          error={errors.title && getErrorMessage(errors, 'title')}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        <ErSelect
          fullWidth={false}
          control={control}
          fieldLabel={t('DETAILS-FORM.SECTION.BASIC.SELECT_type')}
          fieldName="type"
          menuItems={ErEventTypeOptions}
          size="small"
          sx={{ mb: 2, width: 'fit-content', minWidth: '200px' }}
          error={errors.type && getErrorMessage(errors, 'type')}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        {/* <Stack sx={{ mb: 2 }} direction="row" spacing={1}> */}
        <ErDatePicker
          fullWidth
          control={control}
          fieldLabel={t('DETAILS-FORM.SECTION.BASIC.FIELD_startdate')}
          fieldName="startdate"
          textFieldSize="small"
          error={errors.startdate && getErrorMessage(errors, 'startdate')}
          sx={{
            mb: 2,
            width: 'fit-content',
            minWidth: '200px',
            maxWidth: '200px',
          }}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        <Collapse in={multiSession}>
          <ErTimePickerDesktop
            fullWidth
            size="small"
            textFieldVariant="outlined"
            control={control}
            fieldLabel={t('DETAILS-FORM.SECTION.BASIC.FIELD_starttime')}
            fieldName="starttime"
            error={errors.starttime && getErrorMessage(errors, 'starttime')}
            sx={{
              mb: 2,
              width: 'fit-content',
              minWidth: '200px',
              maxWidth: '200px',
            }}
            readOnly={
              !hasPermission({
                objPath: 'event.settingsMenu',
                operation: 'update',
              })
            }
          />
        </Collapse>

        <Stack direction="row" sx={{ mb: 2 }}>
          <ErSwitch
            fieldName="multiDay"
            control={control}
            size="small"
            disabled={
              !hasPermission({
                objPath: 'event.settingsMenu',
                operation: 'update',
              })
            }
          />
          <Typography variant="body1">
            {t('DETAILS-FORM.SECTION.BASIC.SWITCH_multiDay')}
          </Typography>
        </Stack>

        <Collapse in={multiDay}>
          <ErSelect
            fullWidth
            control={control}
            fieldLabel={t('DETAILS-FORM.SECTION.BASIC.SELECT_duration')}
            fieldName="duration"
            menuItems={ErEventDurationOptions}
            size="small"
            sx={{ mb: 2, width: 'fit-content', minWidth: '200px' }}
            error={errors.duration && getErrorMessage(errors, 'duration')}
            readOnly={
              !hasPermission({
                objPath: 'event.settingsMenu',
                operation: 'update',
              })
            }
          />
        </Collapse>

        <Stack direction="row" sx={{ mb: 2 }}>
          <ErSwitch
            fieldName="multiSession"
            control={control}
            disabled={
              !hasPermission({
                objPath: 'event.settingsMenu',
                operation: 'update',
              }) ||
              origEventData.multiSession ||
              multiDay
            }
            size="small"
          />
          <Typography variant="body1">
            {t('DETAILS-FORM.SECTION.BASIC.SWITCH_multiSession')}
          </Typography>
        </Stack>

        <ErTextField
          size="small"
          control={control}
          fieldLabel={t('DETAILS-FORM.SECTION.BASIC.FIELD_description')}
          fieldName="description"
          multiline
          rows={2}
          sx={{ mb: 2 }}
          error={errors.description && getErrorMessage(errors, 'description')}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        {/* <ErHtmlEditor
          control={control}
          editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
          fieldLabel={t('DETAILS-FORM.SECTION.BASIC.FIELD_descriptionHtml')}
          fieldName="descriptionHtml"
          sx={{ mb: 2 }}
          error={
            errors.descriptionHtml && getErrorMessage(errors, 'descriptionHtml')
          }
          onChangeCallback={() =>
            !basicData.isDirty && updateBasicData({}, true)
          }
          language="de"
        /> */}

        <ErSelect
          control={control}
          fieldLabel={t('DETAILS-FORM.SECTION.BASIC.FIELD_timezone')}
          fieldName="timezone"
          menuItems={timezonesSelectList()}
          size="small"
          sx={{ mb: 2, width: 'fit-content', minWidth: '200px' }}
          error={errors.timezone && getErrorMessage(errors, 'timezone')}
          readOnly={
            !hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            })
          }
        />

        {/* <ErTextField
          size="small"
          control={control}
          fieldLabel="Event Code"
          fieldName="eventCode"
          sx={{ mb: 2 }}
          error={errors.eventCode && getErrorMessage(errors, 'eventCode')}
        /> */}
      </Stack>
    </>
  );
}
