import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getEventApi, getSessionApi } from '../../api';
import { useEffect } from 'react';
import useSessionStore from '../../store/sessionStore';

// Layer II Data Handling
export function useSessionByIdQuery(sessionId: string, eventId?: string) {
  const queryClient = useQueryClient();

  return useQuery(
    {
      queryKey: ['session', 'details', sessionId],
      queryFn: () => getSessionApi(sessionId, eventId),
    },
    queryClient,
  );
}

// Layer III Data Handling
export default function useSessionQueryHook(
  sessionId: string,
  eventId?: string,
) {
  // event query
  const { data, error, isLoading, isSuccess, isError, status } =
    useSessionByIdQuery(sessionId, eventId);

  // event store
  const { setData, setStatus } = useSessionStore();

  useEffect(() => {
    // update data
    if (data) setData(data);
  }, [data]);

  useEffect(() => {
    // update state
    setStatus(status);
  }, [status]);

  return { data, isLoading, isSuccess, isError };
}
