import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { navigationComponents } from './NavigationComponents';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useRef, useState } from 'react';
import useUserPermissions from '../Hooks/useUserPermissions';
import { getUserMenuAccess } from '../../util/userAccessRights';
import { displayEventFromToDate } from '../../util/dateFormating';

import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import useEventStore from '../../store/eventStore';
import useEventQueryHook from '../../data/event/event.query';
import useLiveDataQueryHook from '../../data/live/live.query';
import { useQueryClient } from '@tanstack/react-query';
import useSessionStore from '../../store/sessionStore';
import useAuthStore from '../../store/authStore';

type SubNavigationParamsTypes = {
  menu?: string;
};

export default function MainNavigation({
  menu = '',
}: SubNavigationParamsTypes) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const events = window.fsEvents;

  // params
  const menuData = getUserMenuAccess(menu);

  // jodit editor dummy ref
  const joditRef = useRef(null);

  // state
  const [subMenuPos, setSubMenuPos] = useState('Sessions');
  const [subMenuBackLink, setSubMenuBackLink] = useState(false);

  // get path params
  const { eventId, sessionId } = useParams();

  // query client
  const queryClient = useQueryClient();

  // get the modal container
  const modalRoot = document.getElementById('modalRoot');

  // authstore
  const { unreadMessages } = useAuthStore();
  console.log('129-user', unreadMessages);

  // drawer setup
  const [mobileOpen, setMobileOpen] = useState(false);
  const toggleDrawer = () => {
    // setDrawerOpen(!drawerOpen);
    events?.publish('drawerState', { drawerState: !mobileOpen });
    setMobileOpen(!mobileOpen);
  };
  // const handleDrawerToggle = () => {
  //   events?.publish('drawerState', { drawerState: !mobileOpen });
  //   setMobileOpen(!mobileOpen);
  // };

  const drawerWidth = 240;

  // drawer container
  const container =
    typeof window !== 'undefined' ? window.document.body : undefined;

  // subscribe to cached query
  useEventQueryHook(eventId!);

  //// global store
  const eventData = useEventStore((state) => state.data);
  const eventDataStatus = useEventStore((state) => state.status);

  const sessionData = useSessionStore((state) => state.data);
  const sessionDataStatus = useSessionStore((state) => state.status);

  // state
  // toogle settings modal
  const [modalOpen, setModalOpen] = useState(false);

  // !!! typing
  const toggleEventSettingsModal = () => {
    // if (modalOpen) useEventStore.setState({ data: eventData });
    // setModalOpen(!modalOpen);
    events?.publish('toggleEventMenu', {});
  };

  // subscribe to live data
  useLiveDataQueryHook(eventId!);

  // navigation
  const navigate = useNavigate();
  const location = useLocation();

  // theme
  const theme = useTheme();

  // permission settings
  const { hasPermission } = useUserPermissions();

  const navigateOneUpMs = () => {
    const parts = location.pathname.split('/');
    const newParts = parts.slice(0, -2);
    const newPath = newParts.join('/');
    navigate(newPath);
  };

  // subscribe to session name event
  useEffect(() => {
    return () => {
      // reset event store & query
      queryClient.removeQueries({ queryKey: ['event', 'details'] });
      useEventStore.setState({ data: null, status: 'pending' });

      // // reset session store & query
      // queryClient.removeQueries({ queryKey: ['session', 'details'] });
      // useSessionStore.setState({ data: null, status: 'pending' });
    };
  }, []);

  return (
    <>
      {eventDataStatus === 'success' && (
        <>
          <Box
            id="mainNavContainerEvent"
            sx={{ display: 'flex', position: 'relative' }}
          >
            <AppBar
              position="fixed"
              sx={{
                boxShadow: 'none',
                zIndex: (theme) => {
                  return theme.zIndex.drawer + 1;
                },
                // backgroundColor: 'transparent',
                // pointerEvents: 'none',
              }}
            >
              <Toolbar
                sx={{ px: { xs: 1, sm: 1 }, backgroundColor: 'transparent' }}
                onClick={(e) => e.preventDefault()}
              >
                <Stack
                  direction="row"
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  width={'100%'}
                >
                  {/* left */}
                  {/* Desktop */}
                  {
                    // sessionId &&
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'flex',
                        },
                        zIndex: 0,
                        cursor: eventData.multiSession ? 'pointer' : 'default',
                      }}
                      // onClick={() =>
                      //   eventData.multiSession &&
                      //   navigate('../..', { relative: 'path' })
                      // }
                    >
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{
                          fontWeight: 'bold',
                          // maxWidth: '40%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {/* {subMenuPos} */}
                        {
                          // sessionDataStatus === 'success' &&
                          eventDataStatus === 'success' && (
                            <Typography
                              variant="logo"
                              sx={{
                                fontSize: '1.125rem',
                                ml: { xs: 0, sm: '18px' },
                              }}
                              color="primary"
                            >
                              Britestage
                            </Typography>
                          )
                        }
                      </Typography>
                    </Stack>
                  }

                  {/* Mobile */}
                  <Box
                    sx={{
                      position: 'relative',
                      display: { xs: 'inline-flex', sm: 'none' },
                    }}
                  >
                    <IconButton
                      aria-label="open drawer"
                      edge="start"
                      onClick={toggleDrawer}
                      sx={{
                        pl: 0,
                        mx: 0,
                      }}
                      color="primary"
                    >
                      <MenuIcon />
                    </IconButton>
                    {unreadMessages && unreadMessages > 0 ? (
                      <Box
                        sx={{
                          width: '10px',
                          height: '10px',
                          backgroundColor: 'red',
                          position: 'absolute',
                          borderRadius: '50%',
                          top: '8px',
                          right: '4px',
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>

                  {/* center */}
                  {/* Desktop */}
                  {/* eventData.multiSession ? (
                          sessionData?.get('title')
                        ) : */}
                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'block', zIndex: 10 },
                      maxWidth: '20%',
                    }}
                  >
                    <Stack direction="row">
                      <Stack sx={{ maxWidth: '100%' }} alignItems="center">
                        {eventDataStatus === 'success' && (
                          <>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                              >
                                {/* session & event title get synced for single session events so use session title for display */}
                                {eventData.multiSession
                                  ? eventData.title
                                  : sessionData?.get('title')}
                              </Typography>
                            </Stack>

                            {/* session & event startdate get synced for
                            single session events so use session startdate for display as sessionStore is updated earlier here */}
                            {/* <Typography variant="caption">
                              {eventData.multiSession
                                ? displayEventFromToDate(
                                    new Date(eventData.startdate),
                                    new Date(eventData.enddate),
                                  )
                                : sessionData &&
                                  displayEventFromToDate(
                                    sessionData?.get('startdate'),
                                    sessionData?.get('enddate'),
                                  )}
                            </Typography> */}
                          </>
                        )}

                        {sessionId && eventData?.multiSession && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                            sx={{
                              cursor: eventData.multiSession
                                ? 'pointer'
                                : 'default',
                            }}
                            onClick={() => {
                              navigateOneUpMs();
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {sessionDataStatus === 'success' &&
                                sessionData?.get('title')}
                            </Typography>

                            <SwapHorizIcon
                              sx={{
                                fontSize: theme.typography.body1.fontSize,
                                width: '18px',
                                height: '18px',
                                ml: '4px !important',
                                display: eventData?.multiSession
                                  ? 'block'
                                  : 'none',
                              }}
                              color="primary"
                            />
                          </Stack>
                        )}
                      </Stack>

                      {/* {eventData.multiSession && (
                        <>
                          <SwapHorizIcon
                            sx={{
                              width: '18px',
                              height: '18px',
                              ml: '4px !important',
                              opacity: eventData?.multiSession ? 1 : 0,
                            }}
                            color="primary"
                          />
                        </>
                      )} */}
                    </Stack>
                  </Box>

                  {/* Mobile */}
                  <Stack
                    alignItems="center"
                    sx={{
                      display: { xs: 'flex', sm: 'none' },
                      width: 'calc(100svw - 40%)',
                      overflow: 'hidden',
                    }}
                  >
                    <Stack
                      onClick={() => !sessionId && navigate('/events')}
                      sx={{
                        cursor: !sessionId ? 'pointer' : 'default',
                        width: '100%',
                      }}
                    >
                      {/* Upper row */}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 'bold',
                            lineHeight: 1,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (eventData.multiSession) {
                              // navigate('../..', { relative: 'path' });
                              navigateOneUpMs();
                            }

                            if (
                              !sessionId ||
                              (sessionId && !eventData?.multiSession)
                            ) {
                              navigate('/events');
                            }
                          }}
                        >
                          {eventDataStatus === 'success' && eventData.title}
                        </Typography>
                        {(!sessionId ||
                          (sessionId && !eventData?.multiSession)) && (
                          <>
                            <SwapHorizIcon
                              onClick={() =>
                                (!sessionId ||
                                  (sessionId && !eventData?.multiSession)) &&
                                navigate('/events')
                              }
                              sx={{
                                fontSize: theme.typography.body1.fontSize,
                                width: '18px',
                                height: '18px',
                                ml: '4px !important',
                                cursor: 'pointer',
                              }}
                              color="primary"
                            />
                          </>
                        )}
                      </Stack>

                      {/* {!sessionId ? (
                        <Typography
                          variant="caption"
                          sx={{
                            textAlign: 'center',
                            lineHeight: 1,
                          }}
                        >
                          {eventDataStatus === 'success' &&
                            displayEventFromToDate(
                              new Date(eventData.startdate),
                              new Date(eventData.enddate),
                            )}
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          sx={{
                            textAlign: 'center',
                            lineHeight: 1,
                          }}
                        >
                          {sessionDataStatus === 'success' &&
                            new Date(
                              sessionData?.get('startdate'),
                            ).toLocaleDateString()}
                        </Typography>
                      )} */}

                      {/* Lower row */}
                      {sessionId && eventData?.multiSession && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                          sx={{
                            cursor: eventData.multiSession
                              ? 'pointer'
                              : 'default',
                          }}
                          onClick={() =>
                            // eventData.multiSession &&
                            // navigate('../..', { relative: 'path' })
                            navigateOneUpMs()
                          }
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {sessionDataStatus === 'success' &&
                              sessionData?.get('title')}
                          </Typography>

                          {sessionId && eventData.multiSession && (
                            <>
                              <SwapHorizIcon
                                // onClick={() =>
                                //   eventData.multiSession &&
                                //   navigate('../..', { relative: 'path' })
                                // }
                                sx={{
                                  fontSize: theme.typography.body1.fontSize,
                                  width: '18px',
                                  height: '18px',
                                  ml: '4px !important',
                                  display: eventData?.multiSession
                                    ? 'block'
                                    : 'none',
                                }}
                                color="primary"
                              />
                            </>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>

                  {/* right menu */}
                  {navigationComponents.userMenu}
                </Stack>
              </Toolbar>
            </AppBar>
          </Box>
        </>
      )}
    </>
  );
}

// {/* Mobilde Drawer */}
// <Box
// sx={{
//   display: { xs: 'block', sm: 'none' },
//   position: 'absolute',
// }}
// >
// <Drawer
//   container={container}
//   variant="temporary"
//   open={drawerOpen}
//   onClose={() => toggleDrawer()}
//   ModalProps={{
//     keepMounted: true, // Better open performance on mobile.
//   }}
//   sx={{
//     zIndex: 1201,
//     '& .MuiDrawer-paper': {
//       boxSizing: 'border-box',
//       width: drawerWidth,
//     },
//   }}
// >
//   {/* top box */}
//   <Box sx={{ backgroundColor: theme.palette.secondary.main, p: 1 }}>
//     <Stack spacing={1}>
//       <Skeleton
//         variant="rectangular"
//         width={32}
//         height={32}
//         sx={{ bgcolor: theme.palette.secondary.contrastText, m: 0 }}
//       />
//       <Stack
//         direction="row"
//         justifyContent="space-between"
//         alignItems="end"
//       >
//         <Stack sx={{ maxWidth: '100%' }}>
//           <Stack direction="row" alignItems="center">
//             <Typography
//               variant="body2"
//               sx={{
//                 fontWeight: 'bold',
//                 whiteSpace: 'nowrap',
//                 textOverflow: 'ellipsis',
//                 overflow: 'hidden',
//               }}
//             >
//               {/* {eventQueryResult.isSuccess &&
//                 eventQueryResult.data.get('title')} */}
//               {eventDataStatus === 'success' && eventData.title}
//             </Typography>
//             {hasPermission({ objPath: 'event.settingsMenu' }) && (
//               <IconButton
//                 // size="small"
//                 onClick={() => toggleEventSettingsModal()}
//                 sx={{ fontSize: theme.typography.body1.fontSize }}
//               >
//                 <SettingsIcon fontSize="inherit" />
//               </IconButton>
//             )}
//           </Stack>

//           <Typography variant="body2">
//             {eventDataStatus === 'success' &&
//               displayEventFromToDate(
//                 new Date(eventData.startdate),
//                 new Date(eventData.enddate),
//               )}
//           </Typography>
//         </Stack>
//       </Stack>
//     </Stack>
//   </Box>

//   {/* Menu */}
//   <Stack direction="column" spacing={1}>
//     <Link
//       component={RouterLink}
//       to="/events"
//       underline="none"
//       sx={{
//         lineHeight: '32px',
//       }}
//     >
//       Event wechseln
//     </Link>
//     {menuData?.map((link, index) => {
//       const pathSegements = link.path?.split('/');
//       let targetSegment: string = '';

//       if (pathSegements && pathSegements.length > 0) {
//         targetSegment = pathSegements[pathSegements.length - 1];
//       }

//       return link.path ? (
//         hasPermission({
//           objPath: 'subnavigation.' + menu + '.' + targetSegment,
//         }) && (
//           <Link
//             key={link.title}
//             component={RouterLink}
//             to={link.path}
//             underline="none"
//             sx={{
//               lineHeight: '32px',
//             }}
//             onClick={() => {
//               setSubMenuPos(link.title);
//               toggleDrawer();
//             }}
//           >
//             {link.title}
//           </Link>
//         )
//       ) : (
//         <Link key={link.title}>{link.title}</Link>
//       );
//     })}
//   </Stack>
// </Drawer>
// </Box>
