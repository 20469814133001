import CrewList from '../../Staff/CrewList';
// import StaffList from '../../Staff/StaffList';
import EventDetailsFormSection from './EventDetailsFormSection';

import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';

type EventDetailsFormLayoutGeneralPropsTypes = {
  mediaQueryMobile: boolean;
  featureType?: string;
};

export default function EventDetailsFormLayoutAccess(
  props: EventDetailsFormLayoutGeneralPropsTypes,
) {
  // props
  const { mediaQueryMobile } = props;

  return (
    <EventDetailsFormSection
      headlineTranslationString="DETAILS-FORM.SECTION.ACCESS.HEADLINE"
      sublineTranslationString="DETAILS-FORM.SECTION.ACCESS.SUBLINE"
      translationNs="event"
      Icon={VpnKeyOutlinedIcon}
      // FormComponent={<StaffList role="eventManager" />}
      FormComponent={<CrewList />}
      mediaQueryMobile={mediaQueryMobile}
      keepOpen
    />
  );
}
