import Parse from 'parse';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  addNewSlotMutation,
  deleteNewSlotMutation,
  updateSlotMutation,
} from './slotDataHandling';
import { useFeedback } from '../../Feedback/FeedbackContext';
import {
  deleteActorApi,
  deleteMediaVersionApi,
  setNewActorApi,
  setNewMediaApi,
  setNewMediaFileBasedApi,
  setNewMediaVersionApi,
  updateActorApi,
  updateMediaApi,
  updateMediaFileApi,
  updateSessionApi,
} from '../../../api';
import useAuthStore from '../../../store/authStore';
import useUserPermissions from '../../Hooks/useUserPermissions';
import updateSlotOrderApi from '../../../api/slot/updateSlotOrderApi';
import {
  calcDurationBetweenDates,
  slotlistRenderDataTypes,
} from './useSlotlistRenderData';
import { UpdateActorApiParamsTypes } from '../../../api/actor/updateActorApi';
import deleteMediaApi from '../../../api/media/deleteMediaApi';
import appendActorApi from '../../../api/actor/appendActorApi';
import { removeActorFromParentApi } from '../../../api/actor/removeActorFromParentApi';
import updateSessionAgendaApi from '../../../api/agenda/updateSessionAgendaApi';
import { actorOptions } from '../../Actors/actor';
import updateActorOrderApi, {
  UpdateActorOrderApiParamsTypes,
} from '../../../api/actor/updateActorOrderApi';
import updateMediaOrderApi from '../../../api/media/updateMediaOrderApi';
import { isSameTime } from '../../../util/dateHelpers';
import useEventStore from '../../../store/eventStore';
import useSessionStore from '../../../store/sessionStore';
import { clone } from 'lodash';

export type slotDataTypes = {
  actors?: actorDataTypes[];
  actorOptions?: actorOptions[];
  media?: mediaDataTypes[];
  createdAt?: Date;
  default?: boolean;
  description?: string;
  durationMin: number;
  durationPlaned: number;
  id?: string;
  sessionId?: string;
  title: string;
  type: 'standard' | ' buffer' | 'pause' | string;
  updatedAt?: Date;
  starttimePlanned?: Date | null;
  showInAgenda?: boolean;
  showDescriptionInAgenda?: boolean;
  showActorsInAgenda?: boolean;
  newColumnAfter?: boolean;
  slotContentType?: string;
  // starttime?: Date | null;
  // endtime?: Date | null;
};

export type slotsDataTypes = slotDataTypes[];

export type actorDataTypes = Parse.Object;

export type ActorsListEventTypes = {
  id?: string;
  status: 'pending' | 'confirmed' | ' rejected';
  firstname: string;
  lastname: string;
  organisation: string;
  title: string;
  displayActorInAgenda: boolean;
  email: string;
};

export type mediaDataTypes = any;

export type mediaVersionDataTypes = Parse.Object;

export type mediaFileDataTypes = { id?: string };

export type agendaType = 'preview' | 'marketing' | 'live';

type SlotContextType = {
  sessionData: any;
  setSessionData: React.Dispatch<React.SetStateAction<any | null>>;

  slotsData: slotsDataTypes;
  setSlotsData: React.Dispatch<React.SetStateAction<slotsDataTypes>>;
  updateSlotsData: (data: slotsDataTypes) => void;

  slotsRenderDataUI: slotlistRenderDataTypes | undefined;
  // setSlotsRenderDataUI: React.Dispatch<
  //   React.SetStateAction<slotlistRenderDataTypes | undefined>
  // >;
  updateSlotsRenderDataUI: (data: slotlistRenderDataTypes | undefined) => void;

  activeEditId: string | undefined;
  setActiveEditId: React.Dispatch<React.SetStateAction<string | undefined>>;

  activeDndId: any | null;
  setActiveDndId: React.Dispatch<React.SetStateAction<any | null>>;
  activeSlotData: slotDataTypes;
  setActiveSlotData: React.Dispatch<
    React.SetStateAction<slotDataTypes | undefined>
  >;

  slotNavigationData: any;
  setSlotNavigationData: React.Dispatch<React.SetStateAction<any | null>>;
  navigateSlot: (direction: 1 | -1) => void;
  detailsEditing: 'session' | 'slots' | string;
  setDetailsEditing: React.Dispatch<
    React.SetStateAction<'session' | 'slots' | string>
  >;
  mainTabValue: any;
  setMainTabValue: React.Dispatch<React.SetStateAction<any | null>>;

  addNewSlot: (slotData: slotDataTypes, invalidateQuery?: boolean) => void;
  // updateSlot: (
  //   slotData: slotDataTypes,
  //   invalidateQuery?: boolean,
  // ) => Promise<slotDataTypes>;
  updateSlot: (
    slotData: slotDataTypes,
    invalidateQuery?: boolean,
    slotOrderUpdatePause?: boolean,
  ) => void;
  deleteSlot: (slotId: string) => void;
  updateSlotOrder: (
    slotOrder: any[],
    invalidateQuery?: boolean,
    resortedPauseData?: slotDataTypes,
  ) => void;
  updateSession: (
    newSessiondata: any[],
    invalidateQuery?: boolean,
    invalidateEventQuery?: boolean,
  ) => void;

  // slot actions
  trimSlot: (slot: slotDataTypes) => void;
  splitSlot: (slot: slotDataTypes) => void;
  movePause: (slot: slotDataTypes) => void;
  fillBuffer: (slot: slotDataTypes) => void;

  ////
  // actor data slot
  slotActorData: actorDataTypes[];

  slotInvitationLinks: { [key: string]: string } | undefined;
  sessionActorInvitationLinks: { [key: string]: string } | undefined;

  actorsEvent: Parse.Object[];
  actorsEventMapped: ActorsListEventTypes[];

  //// ---- actor
  addNewActor: (
    parentClassName: string,
    name?: string,
    invalidateQuery?: boolean,
  ) => Promise<actorDataTypes>;
  updateActor: (
    data: UpdateActorApiParamsTypes,
    invalidateQuery?: boolean,
  ) => Promise<actorDataTypes>;
  updateActorOrder: (data: string[], invalidateQuery?: boolean) => Promise<any>;
  deleteActor: (actorId: string, invalidateQuery?: boolean) => Promise<number>;
  appendActor: (
    parentClassName: string,
    actorId: string,
    invalidateQuery?: boolean,
  ) => Promise<any>;
  removeActorFromParent: (
    parentClassName: string,
    actorId: string,
    invalidateQuery?: boolean,
  ) => Promise<any>;
  //// ---- actor

  // media data slots
  slotMediaData: mediaDataTypes[];

  //// ---- media
  addNewMediaFileBased: (
    files: any[],
    parentClassName: string,
    // parentId: string,
    invalidateQuery?: boolean,
  ) => Promise<any>;
  addNewMedia: (
    fileName: string,
    invalidateQuery?: boolean,
  ) => Promise<Parse.Object>;
  addNewMediaVersion: (
    activeMediaId: string | null,
    files: FileList,
    invalidateQuery?: boolean,
  ) => Promise<mediaDataTypes>;
  deleteMediaVersion: (
    activeMediaId: string | null,
    mediafileId: string,
    invalidateQuery?: boolean,
  ) => Promise<mediaDataTypes>;
  deleteMedia: (
    mediaId: string | null,
    invalidateQuery?: boolean,
  ) => Promise<mediaDataTypes>;
  // downloadMedia: (filePath: string) => void;
  updateMedia: (
    updateData: { data: mediaDataTypes; mediaId: string },
    invalidateQuery?: boolean,
  ) => Promise<mediaDataTypes>;
  updateMediaOrder: (data: string[], invalidateQuery?: boolean) => Promise<any>;
  updateMediaFile: (
    updateData: {
      data: mediaDataTypes;
      mediaFileId: string;
    },
    invalidateQuery?: boolean,
  ) => Promise<any>;
  getActorsMedia: (actorId: string) => mediaDataTypes[];
  //// ---- media

  // actor data session
  sessionActorData: actorDataTypes[];
  sessionMediaData: mediaDataTypes[];

  //
  allSessionActors: actorDataTypes[];

  // // agenda update timeout ref
  // agendaUpdateTimeout: any;
  // setAgendaUpdateTimeout: React.Dispatch<React.SetStateAction<any | null>>;
  updateAgenda: (
    e: any,
    agendaTypes: agendaType | agendaType[],
    updateProgramOnly?: boolean,
  ) => void;

  // update slotlist switch
  updateSlotRendering: boolean;
  changeUpdateSlotRendering: (value: boolean) => void;
};

const SlotContext = createContext<SlotContextType | null>(null);

export function SlotProvider({
  children,
  slots,
  session,
  actorsSession = [], // THIS ARE CURRENTLY ALL EVENT ACTORS, NOT SESSION ACTORS
  masterEventId,
  eventId,
  sessionId,
}: {
  children: ReactNode;
  session: any;
  actorsSession: any;
  slots: any;
  masterEventId: string;
  eventId: string;
  sessionId: string;
}) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const events = window.fsEvents;

  // const event data
  const { data: eventData, updateData: updateStoreEventData } = useEventStore();
  const { setData: setStoreSessionData } = useSessionStore();

  //query client
  const queryClient = useQueryClient();

  // feedback
  const { setFeedback } = useFeedback();

  // permissions
  const { getUserRoles } = useUserPermissions();

  //// STATE
  // data of the according session
  const [sessionData, setSessionData] = useState<any | undefined>(session);
  const sessionIdRef = useRef(sessionData?.id);

  // actor data session
  const [sessionActorData, setSessionActorData] = useState<actorDataTypes[]>(
    [],
  );

  //
  const [allSessionActors, setAllSessionActors] = useState<actorDataTypes[]>(
    [],
  );

  // actor invitation data session
  const [sessionActorInvitationLinks, setSessionActorInvitationLinks] =
    useState<{
      [key: string]: string;
    }>();

  // media data of the session
  const [sessionMediaData, setSessionMediaData] = useState<mediaDataTypes[]>(
    [],
  );

  // data of all slots of the session
  const [slotsData, setSlotsData] = useState<slotsDataTypes>(slots || []);
  // console.log('##-slotsData', slotsData);
  const updateSlotsData = (data: slotsDataTypes) => {
    setSlotsData(data);
  };

  console.log('slotsData', slotsData);

  // flag for updating slotlist rendering on data update
  const [updateSlotRendering, setUpdateSlotRendering] = useState(true);
  const changeUpdateSlotRendering = (value: boolean) => {
    setUpdateSlotRendering(value);
  };

  // data of the slots to be used for rendering
  const [slotsRenderDataUI, setSlotsRenderDataUI] = useState<
    slotlistRenderDataTypes | undefined
  >();

  const updateSlotsRenderDataUI = (
    data: slotlistRenderDataTypes | undefined,
  ) => {
    setSlotsRenderDataUI(data);
  };

  // // data of the slots to be used for rendering PREVIEW
  // const [slotsRenderDataUIPreview, setSlotsRenderDataUIPreview] = useState<
  //   slotlistRenderDataTypes | undefined
  // >();

  // the slot id that is marked active for editing
  const [activeEditId, setActiveEditId] = useState<string | undefined>();
  const activeEditIdRef = useRef(activeEditId);

  // the slot id that is marked active for dnd
  const [activeDndId, setActiveDndId] = useState(null);

  // the data of the slot that is marked as avtive for Editing
  const [activeSlotData, setActiveSlotData] = useState<any>();

  // the data type we are editing ("slots" | "session")
  const [detailsEditing, setDetailsEditing] = useState('session');

  // data for prev / next navigation in details modal
  const [slotNavigationData, setSlotNavigationData] = useState<any>({
    currIndex: 0,
    totalItems: slotsData?.length,
  });

  // actor data of the active slot
  const [slotActorData, setSlotActorData] = useState<actorDataTypes[]>([]);

  // invitationlinks for the actors
  const [slotInvitationLinks, setSlotInvitationLinks] = useState<
    { [key: string]: string } | undefined
  >();

  // media data of the active slot
  const [slotMediaData, setSlotMediaData] = useState<mediaDataTypes[]>([]);

  // set main tabs in edit modal
  const [mainTabValue, setMainTabValue] = useState(0);

  // agenda update timeout
  const [agendaUpdateTimeout, setAgendaUpdateTimeout] = useState<any>(null);

  //// FUNCTIONS
  // navigation function
  const navigateSlot = (direction: 1 | -1) => {
    let tmpIndex = slotNavigationData.currIndex + direction;

    if (tmpIndex < 0) {
      tmpIndex = slotNavigationData.totalItems - 1;
    }

    if (tmpIndex + 1 > slotNavigationData.totalItems) {
      tmpIndex = 0;
    }

    setActiveSlotData(slots[tmpIndex]);
    setActiveDndId(slots[tmpIndex].id);
    setActiveEditId(slots[tmpIndex].id);

    setSlotNavigationData((oldData: any) => {
      const newData = { ...oldData };
      newData.currIndex = tmpIndex;
      return newData;
    });
  };

  // event actors
  const [actorsEvent, setActorsEvent] = useState<Parse.Object[]>(actorsSession);
  const [actorsEventMapped, setActorsEventMapped] = useState<
    ActorsListEventTypes[]
  >([]);

  //// DATA HANDLING
  // SLOTS
  const addNewSlot = async (
    slotData: slotDataTypes,
    invalidateQuery: boolean = true,
  ) => {
    // index for slot order
    let addAtIndex: number | undefined;

    // for slottype pause
    if (slotData?.type === 'pause') {
      // set defaults for type pause
      // set title to pause
      if (!slotData.title) slotData.title = 'Pause';

      // add startdate if not set
      if (!slotData.starttimePlanned) {
        slotData.starttimePlanned =
          slotsRenderDataUI?.sessionTiming.sessionEndTimeCalculated;
      } else {
        // sort in the new pause correctly
        // combine renderData & timing arrays
        const combinedArray = slotsRenderDataUI!.slotsRenderData!.map(
          (slot: slotDataTypes, index: any) => ({
            slot,
            starttime: slotsRenderDataUI?.slotsTiming[index].startTime,
          }),
        );

        console.log('#17-combinedArray', [...combinedArray]);

        // add new pause (not a valid object, but sufficient for sorting)
        combinedArray.push({
          slot: {
            id: 'newPause',
            durationMin: 30,
            durationPlaned: 30,
            title: 'pause',
            type: 'pause',
            starttimePlanned: slotData.starttimePlanned,
          },
          starttime: slotData.starttimePlanned,
        });

        console.log('#17-combinedArrayPush', [...combinedArray]);

        // sort
        combinedArray.sort(
          (a, b) => a.starttime!.getTime() - b.starttime!.getTime(),
        );

        console.log('#17-combinedArraySort', [...combinedArray]);

        // move eos to the end if we have no fixed enddate
        if (!sessionData?.get('endtimePlanned')) {
          console.log('#17-calledMoveEos');
          // get eosIndex
          const eosIndex = combinedArray.findIndex(
            (slotData: any) => slotData.slot.type === 'eos',
          );

          console.log('#17-eosIndex', eosIndex);
          if (eosIndex !== undefined && eosIndex !== -1) {
            const arrayLength = combinedArray.length;
            if (eosIndex !== arrayLength - 1) {
              const [eosElement] = combinedArray.splice(eosIndex, 1);
              combinedArray.push(eosElement);
            }
          }
        }

        console.log('#17-combinedArrayMovedEos', [...combinedArray]);

        // get the index of the new pause
        const newPauseIndex = combinedArray.findIndex((slot) => {
          return slot.slot!.id === 'newPause';
        });

        console.log('#17-newPauseIndex', newPauseIndex);

        if (newPauseIndex !== -1 && newPauseIndex !== undefined) {
          // check if the previous slot has the same starttime
          // if so, move the pause before this slot
          if (newPauseIndex > 0) {
            if (
              isSameTime(
                combinedArray[newPauseIndex - 1].starttime!,
                slotData.starttimePlanned,
              )
            ) {
              addAtIndex = newPauseIndex - 1;
              // addAtIndex = newPauseIndex;
            } else {
              addAtIndex = newPauseIndex;
            }
          } else {
            addAtIndex = newPauseIndex;
          }

          console.log('#17-addAtIndex', addAtIndex);
          // addAtIndex = newPauseIndex;
        }
      }
    } else {
      // regular slots sort in correctly
      if (activeEditId !== undefined && activeEditId !== null) {
        // after active slot
        const newSlotIndex = slotsRenderDataUI?.slotsRenderData?.findIndex(
          (slot) => slot!.id === activeEditId,
        );

        if (newSlotIndex !== -1 && newSlotIndex !== undefined) {
          const selectedSlot = slotsRenderDataUI?.slotsRenderData[newSlotIndex];

          // OLD VERSION
          // addAtIndex =
          //   selectedSlot?.type === 'eos' ? newSlotIndex : newSlotIndex + 1;

          // NEW VERSION
          // fix, so new slots don't get added after eos object
          if (selectedSlot?.type === 'eos') {
            if (
              slotsRenderDataUI?.slotsRenderData[newSlotIndex - 1]?.type ===
              'buffer'
            ) {
              addAtIndex = newSlotIndex - 1;
            } else {
              addAtIndex = newSlotIndex;
            }
          } else {
            addAtIndex = newSlotIndex + 1;
          }
        }
        console.log('##197-addAtIndex', addAtIndex);
      } else {
        // in segment that fits
        // combine renderData & timing arrays
        const combinedArray = slotsRenderDataUI!.slotsRenderData!.map(
          (slot: slotDataTypes, index: any) => ({
            slot,
            starttime: slotsRenderDataUI?.slotsTiming[index].startTime,
            endtime: slotsRenderDataUI?.slotsTiming[index].endTime,
          }),
        );

        // remove buffers
        const filteredArray = combinedArray.filter(
          (slot) => slot.slot?.type !== 'buffer',
        );

        const indexSlotFitsIn = filteredArray.findIndex(
          (slot, index, array) => {
            if (index + 1 <= array.length - 1) {
              return (
                slotData!.durationPlaned! <=
                calcDurationBetweenDates(
                  slot.endtime!,
                  array[index + 1].starttime!,
                ).dif
              );
            } else {
              // if we have only one slot & this slot is eos with planed end return index 0 if slot fits inbetween session starttime & eos
              if (
                array.length === 1 &&
                slot.slot?.type === 'eos' &&
                slot?.slot?.starttimePlanned
              ) {
                return (
                  slotData!.durationPlaned! <=
                  calcDurationBetweenDates(
                    slotsRenderDataUI!.sessionTiming?.sessionStartTime,
                    slot.starttime!,
                  ).dif
                );
              }
            }
          },
        );

        if (indexSlotFitsIn !== -1 && indexSlotFitsIn !== undefined) {
          if (
            filteredArray.length === 1 &&
            filteredArray[0].slot.type === 'eos' &&
            filteredArray[0].slot.starttimePlanned
          ) {
            // if we only have an eos slot with planed end & an valid index
            // stay with addAtIndex = 0;
            addAtIndex = 0;
          } else {
            // must increment on all other cases
            addAtIndex = indexSlotFitsIn + 1;
          }
        } else {
          addAtIndex = slotsRenderDataUI!.slotsRenderData!.length;
        }
      }
    }

    // add new slot
    const newSlot = await addNewSlotMutation({
      slotData: slotData,
      eventId: eventId,
      sessionId: sessionId,
      addAtIndex: addAtIndex,
    })
      .then(async (res) => {
        console.log('#17-res', res);

        if (addAtIndex !== undefined && addAtIndex !== null) {
          setSlotsData((state) => {
            // state.splice(addAtIndex!, 0, res.slotMapped);
            // return state;
            console.log('#17-stateOld', [...state]);
            const newState = [...state];
            newState.splice(addAtIndex!, 0, res.slotMapped);
            return newState;
          });
        } else {
          // setSlotsData((state) => [...state, res.slotMapped]);
          // this is mainly for regular slots
          setSlotsData((state) => {
            if (!sessionData?.get('endtimePlanned')) {
              const tempState = [...state, res.slotMapped];
              const tsEosIndex = tempState.findIndex(
                (slot) => slot.type === 'eos',
              );
              const tsArrayLength = tempState.length;
              if (tsEosIndex !== tsArrayLength - 1) {
                const [eosElement] = tempState.splice(tsEosIndex, 1);
                tempState.push(eosElement);
              }
              return tempState;
            } else {
              return [...state, res.slotMapped];
            }
          });
        }

        // update the data of the active slot to the new created one
        setActiveSlotData(res.slotMapped);
        // update the active editId
        setActiveEditId(res.slot.id);
        // update the data editing type to slots so we see the details
        setDetailsEditing('slots');
        // update the navigation data
        updateSlotNavData(res.slotMapped);
        // update user roles so the new slot is readable by it's creator
        await getUserRoles(eventId!);
        // publish the new item (to set dnd list focus to it)
        events?.publish('slotListItemsChange', {
          newSlotListItem: res.slotMapped.id,
          type: 'add',
        });
        return res.slotMapped;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    // #QUERY#
    //// DO ALL INTERNAL UPDATES
    // invalidate queries
    // if (invalidateQuery) {
    //   queryClient.invalidateQueries({
    //     queryKey: ['slots', 'list', sessionId],
    //   });

    //   queryClient.invalidateQueries({
    //     queryKey: ['session', 'details', sessionId],
    //   });
    // }

    // // update the data of the active slot to the new created one
    // setActiveSlotData(newSlot);

    // // update the data editing type to slots so we see the details
    // setDetailsEditing('slots');

    // // update the navigation data
    // updateSlotNavData(newSlot);

    // // update user roles so the new slot is readable by it's creator
    // await getUserRoles(eventId!);

    // // publish the new item (to set dnd list focus to it)
    // events?.publish('slotListItemsChange', {
    //   newSlotListItem: newSlot.id,
    //   type: 'add',
    // });

    return newSlot;
  };

  const updateSlot = async (
    slotData: any,
    invalidateQuery: boolean = true,
    slotOrderUpdatePause: boolean = false,
  ) => {
    // remove split slot id appendix
    if (slotData.id.includes('-part')) {
      slotData.id = slotData.id.split('-part')[0];
    }

    const localQueryData: any[] | undefined = queryClient.getQueryData([
      'slots',
      'list',
      sessionId,
    ]);

    // update the query cache locally first
    if (localQueryData) {
      const localQueryDataIndex = localQueryData.findIndex(
        (slot: slotDataTypes) => slot.id === slotData.id,
      );

      if (localQueryDataIndex !== -1) {
        // check
        const newQueryCache: any[] = [];
        localQueryData.forEach((slot) => {
          newQueryCache.push(slot);
        });
        const slotDataClone = { ...slotData };
        slotDataClone.actors = localQueryData[localQueryDataIndex].actors;
        slotDataClone.media = localQueryData[localQueryDataIndex].media;
        newQueryCache[localQueryDataIndex] = slotDataClone;

        // localQueryData[localQueryDataIndex] = slotData;

        if (invalidateQuery) {
          await queryClient.setQueryData(
            ['slots', 'list', sessionId],
            newQueryCache,
          );
        }
      }
    }

    updateSlotMutation({
      slotData: slotData,
      slotOrderUpdatePause: slotOrderUpdatePause,
    })
      .then((res) => {
        return res;
      })
      .catch((e) => console.error());
  };

  const deleteSlot = async (
    slotId: string,
    invalidateQuery: boolean = true,
  ) => {
    // // do delete calculations
    // const slotdeleteInformation = slotDeleteCalculation({
    //   slotId: slotId,
    //   slotsData: slotsData,
    //   slotsRenderData: slotsRenderData,
    // });

    // delete the slot
    await deleteNewSlotMutation({
      queryClient: queryClient,
      slotId: slotId,
    })
      .then((res) => {
        // setFeedback({
        //   message: 'Löschen erfolgreich',
        //   type: 'success',
        //   autoclose: true,
        // });
        // delete locally first
        const deleteSlotIndex = slotsData.findIndex(
          (slots) => slots.id === slotId,
        );

        if (deleteSlotIndex !== -1) {
          const newSlotlist = [...slotsData];
          newSlotlist.splice(deleteSlotIndex, 1);

          setSlotsData(newSlotlist);
        }
      })
      .catch((e) => {
        console.error(e);
        setFeedback({
          headline: 'Löschen fehlgeschlagen',
          message: e.message,
          type: 'error',
          autoclose: true,
        });
      });

    // // perform necessary updates
    // if (slotdeleteInformation.requiresUpdate.length > 0) {
    //   // add all required update promises to an array
    //   const updateActionPromises: any = [];

    //   slotdeleteInformation.requiresUpdate.forEach((slotUpdate: any) => {
    //     switch (slotUpdate.type) {
    //       case 'update':
    //         updateActionPromises.push(
    //           updateSlotMutation({ slotData: slotUpdate.slotData }),
    //         );
    //         break;
    //       case 'new':
    //         updateActionPromises.push(
    //           addNewSlotMutation({
    //             slotData: slotUpdate.slotData,
    //             sessionId: sessionId,
    //             eventId: eventId,
    //           }),
    //         );
    //         break;
    //       case 'delete':
    //         break;
    //       default:
    //     }
    //   });

    //   // await all Promises to resolve
    //   await Promise.all(updateActionPromises)
    // .then((res) => {
    //   setFeedback({
    //     message: 'Löschen erfolgreich',
    //     type: 'success',
    //     autoclose: true,
    //   });
    // })
    //     .catch((e) => {
    //       console.error(e);
    //       setFeedback({
    //         headline: 'Löschen fehlgeschlagen',
    //         message: e.message,
    //         type: 'error',
    //         autoclose: true,
    //       });
    //     });
    // }

    // update data for display
    if (slotsData) {
      if (slotsData.length > 0) {
        setActiveSlotData(slotsData[0]);
        // update the navigation data
        updateSlotNavData(slotsData[0]);
      } else {
        setActiveSlotData(undefined);
        setDetailsEditing('session');
      }
    }

    // invalidate rq cache to display new data in ui
    if (invalidateQuery) {
      queryClient.invalidateQueries({ queryKey: ['slots', 'list', sessionId] });
    }

    // publish the new item (to set dnd list focus to it)
    if (slotsData && slotsData.length > 0) {
      events?.publish('slotListItemsChange', {
        newSlotListItem: slotsData[0]!.id,
      });
    }
  };

  // ACTORS
  const addNewActor = async (
    parentClassName: string,
    name: string = '',
    invalidateQuery: boolean = true,
  ) => {
    let firstname = '';
    let lastname = '';
    if (name && name !== '') {
      const nameSplit = name.split(' ');
      firstname = nameSplit[0];
      lastname = nameSplit.slice(1).join(' ');
    }

    const parentId =
      parentClassName === 'Slot' ? activeSlotData.id : sessionData.id;

    if (parentId && parentId !== null) {
      const data = {
        eventId: eventId!,
        sessionId: sessionId!,
        firstname: firstname,
        lastname: lastname,
        parent: {
          className: parentClassName,
          id: parentId,
        },
      };

      //

      return setNewActorApi(data)
        .then((res) => {
          if (parentClassName === 'Slot') {
            setSlotActorData((actorData: actorDataTypes[]) => [
              ...actorData,
              res,
            ]);
          } else {
            setSessionActorData((actorData: actorDataTypes[]) => [
              ...actorData,
              res,
            ]);
          }

          getUserRoles(eventId!);

          if (invalidateQuery) {
            if (detailsEditing === 'slots') {
              queryClient.invalidateQueries({
                queryKey: ['slots', 'list', sessionId],
              });
            } else {
              queryClient.invalidateQueries({
                queryKey: ['session', 'details', sessionId],
              });
            }

            // #QUERY#
            // // invalidate actors query
            // queryClient.invalidateQueries({
            //   queryKey: ['session', 'actors', eventId],
            // });
          }

          // invalidate actors query
          queryClient.invalidateQueries({
            queryKey: ['session', 'actors', eventId],
          });

          return res;
        })
        .catch((e) => console.error(e));
    } else {
      throw new Error('Akteur konnte nicht erstellt werden');
    }
  };

  const appendActor = async (
    parentClassName: string,
    actorId: string,
    invalidateQuery: boolean = true,
  ) => {
    const parent = {
      __type: 'Pointer',
      className: parentClassName,
      objectId: parentClassName === 'Slot' ? activeSlotData.id : sessionId,
    };

    const appenActor = await appendActorApi(
      parent,
      actorId,
      eventId,
      sessionId,
    );

    // // update the users permissions
    // should not be necessary as for this user there are no changes
    // await getUserRoles(eventId!);

    // add actor to current slot & session obj
    if (appenActor) {
      if (parentClassName === 'Session') {
        setSessionActorData((actorData: actorDataTypes[]) => [
          ...actorData,
          appenActor,
        ]);
      } else {
        setSlotActorData((actorData: actorDataTypes[]) => [
          ...actorData,
          appenActor,
        ]);
      }
    }

    if (invalidateQuery) {
      // #QUERY#
      // taken over by liveQuery
      if (detailsEditing === 'slots') {
        queryClient.invalidateQueries({
          queryKey: ['slots', 'list', sessionId],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['session', 'details', sessionId],
        });
      }
    }

    return appenActor;
  };

  const removeActorFromParent = async (
    parentClassName: string,
    actorId: string,
    invalidateQuery: boolean = true,
  ) => {
    const parent = {
      __type: 'Pointer',
      className: parentClassName,
      objectId: parentClassName === 'Slot' ? activeSlotData.id : sessionId,
    };

    const removeActor = await removeActorFromParentApi(
      parent,
      actorId,
      eventId,
      sessionId,
    );

    // remove actor from current parent
    if (removeActor) {
      if (parentClassName === 'Session') {
        const actorIndex = sessionActorData.findIndex(
          (actor) => actor.id === removeActor.id,
        );
        if (actorIndex !== -1) {
          setSessionActorData((actors) => {
            const actorsCopy = clone(actors);
            actorsCopy.splice(actorIndex, 1);
            return actorsCopy;
          });
        }
      } else {
        const actorIndex = slotActorData.findIndex(
          (actor) => actor.id === removeActor.id,
        );
        if (actorIndex !== -1) {
          setSlotActorData((actors) => {
            const actorsCopy = clone(actors);
            actorsCopy.splice(actorIndex, 1);
            return actorsCopy;
          });
        }
      }
    }

    if (invalidateQuery) {
      // #QUERY#
      // taken over by livequery
      if (detailsEditing === 'slots') {
        queryClient.invalidateQueries({
          queryKey: ['slots', 'list', sessionId],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['session', 'details', sessionId],
        });
      }
    }

    return removeActor;
  };

  const updateActor = async (
    data: UpdateActorApiParamsTypes,
    invalidateQuery: boolean = true,
  ) => {
    const actor = await updateActorApi(data);

    if (invalidateQuery) {
      if (detailsEditing === 'slots') {
        queryClient.invalidateQueries({
          queryKey: ['slots', 'list', sessionId],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['session', 'details', sessionId],
        });
      }

      // invalidate actors query
      queryClient.invalidateQueries({
        queryKey: ['session', 'actors', eventId],
      });
    }

    return actor;
  };

  const updateActorOrder = async (
    data: string[],
    invalidateQuery: boolean = true,
  ) => {
    const payload = {
      actorList: data,
      parentClass: detailsEditing === 'slots' ? 'Slot' : 'Session',
      parentId: detailsEditing === 'slots' ? activeSlotData.id : sessionId,
      eventId: eventId,
      sessionId: sessionId,
    };

    await updateActorOrderApi(payload);

    if (invalidateQuery) {
    }
  };

  const deleteActor = async (
    actorId: string,
    invalidateQuery: boolean = true,
  ) => {
    await deleteActorApi(actorId, eventId, sessionId);

    if (invalidateQuery) {
      // !?! CHECK IF REMOVING THE CONDITION IS GOOD?
      // if (detailsEditing === 'slots') {
      queryClient.invalidateQueries({
        queryKey: ['slots', 'list', sessionId],
      });
      // } else {
      queryClient.invalidateQueries({
        queryKey: ['session', 'details', sessionId],
      });
      // }

      // invalidate actors query
      queryClient.invalidateQueries({
        queryKey: ['session', 'actors', eventId],
      });
    }

    return -1;
  };

  // MEDIA
  const addNewMedia = async (
    fileName: string,
    invalidateQuery: boolean = true,
  ) => {
    //
    const parentClassName = detailsEditing === 'slots' ? 'Slot' : 'Session';
    const parentId =
      parentClassName === 'Slot' ? activeSlotData.id : sessionData.id;

    const newMedia = await setNewMediaApi(
      parentClassName,
      parentId,
      fileName,
      eventId,
      sessionId,
    );

    // update media array
    if (newMedia && newMedia[1]) {
      if (parentClassName === 'Session') {
        setSessionMediaData((media) => {
          return [...media, newMedia[1]];
        });
      } else {
        setSlotMediaData((media) => {
          return [...media, newMedia[1]];
        });
      }
    }

    if (invalidateQuery) {
      // #QUERY#
      if (detailsEditing === 'slots') {
        queryClient.invalidateQueries({
          queryKey: ['slots', 'list', sessionId],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['session', 'details', sessionId],
        });
      }
    }

    return newMedia;
  };

  const addNewMediaFileBased = async (
    files: any[],
    parentClassName: string,
    // parentId: string,
    invalidateQuery: boolean = true,
  ) => {
    //
    const data = {
      file: files[0],
      parent: {
        type: parentClassName,
        id:
          parentClassName === 'Slot'
            ? //activeSlotDataIdRef.current // need to use ref because of closure problem
              // activeSlotData.id
              activeEditIdRef.current
            : sessionIdRef.current,
      },
    };

    const newMedia = await setNewMediaFileBasedApi(data, eventId, sessionId);
    // #QUERY#
    // if (invalidateQuery) {
    //   if (detailsEditing === 'slots') {
    //     queryClient.invalidateQueries({
    //       queryKey: ['slots', 'list', sessionId],
    //     });
    //   } else {
    //     queryClient.invalidateQueries({
    //       queryKey: ['session', 'details', sessionId],
    //     });
    //   }
    // }

    queryClient.invalidateQueries({
      queryKey: ['slots', 'list', sessionId],
    });
    queryClient.invalidateQueries({
      queryKey: ['session', 'details', sessionId],
    });

    return newMedia;
  };

  const addNewMediaVersion = async (
    activeMediaId: string | null,
    files: FileList,
    invalidateQuery: boolean = true,
  ) => {
    const newMediaVersion = await setNewMediaVersionApi(
      activeMediaId,
      files[0],
      eventId,
      sessionId,
    );

    if (invalidateQuery) {
      // #QUERY#
      if (detailsEditing === 'slots') {
        queryClient.invalidateQueries({
          queryKey: ['slots', 'list', sessionId],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['session', 'details', sessionId],
        });
      }
    }

    return newMediaVersion;
  };

  const deleteMediaVersion = async (
    activeMediaId: string | null,
    mediafileId: string,
    invalidateQuery: boolean = true,
  ) => {
    const deleteMediaVersion = await deleteMediaVersionApi(
      activeMediaId,
      mediafileId,
    ).catch((e) => console.error(e));

    if (invalidateQuery) {
      // #QUERY#
      // if (detailsEditing === 'slots') {
      queryClient.invalidateQueries({
        queryKey: ['slots', 'list', sessionId],
      });
      // } else {
      queryClient.invalidateQueries({
        queryKey: ['session', 'details', sessionId],
      });
      // }
    }

    return deleteMediaVersion;
  };

  const deleteMedia = async (
    mediaId: string | null,
    invalidateQuery: boolean = true,
  ) => {
    const deleteMedia = await deleteMediaApi(mediaId, eventId, sessionId).catch(
      (e) => console.error(e),
    );

    if (deleteMedia) {
      if (detailsEditing === 'slots') {
        const mediaIndex = slotMediaData.findIndex(
          (media) => media.id === deleteMedia.id,
        );
        if (mediaIndex !== -1) {
          setSlotMediaData((media) => {
            const mediaCopy = clone(media);
            mediaCopy.splice(mediaIndex, 1);
            return mediaCopy;
          });
        }
      } else {
        const mediaIndex = sessionMediaData.findIndex(
          (media) => media.id === deleteMedia.id,
        );
        if (mediaIndex !== -1) {
          setSessionMediaData((media) => {
            const mediaCopy = clone(media);
            mediaCopy.splice(mediaIndex, 1);
            return mediaCopy;
          });
        }
      }
    }

    if (invalidateQuery) {
      // #QUERY#
      if (detailsEditing === 'slots') {
        queryClient.invalidateQueries({
          queryKey: ['slots', 'list', sessionId],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['session', 'details', sessionId],
        });
      }
    }

    return deleteMedia;
  };

  const updateMedia = async (
    updateData: { data: mediaDataTypes; mediaId: string },
    invalidateQuery: boolean = true,
  ) => {
    const updateMedia = await updateMediaApi(updateData, eventId).catch((e) =>
      console.error(e),
    );

    if (invalidateQuery) {
      // #QUERY#
      if (detailsEditing === 'slots') {
        queryClient.invalidateQueries({
          queryKey: ['slots', 'list', sessionId],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['session', 'details', sessionId],
        });
      }
    }
    return updateMedia;
  };

  const updateMediaOrder = async (
    data: string[],
    invalidateQuery: boolean = true,
  ) => {
    const payload = {
      mediaList: data,
      parentClass: detailsEditing === 'slots' ? 'Slot' : 'Session',
      parentId: detailsEditing === 'slots' ? activeSlotData.id : sessionId,
      eventId: eventId,
      sessionId: sessionId,
    };

    await updateMediaOrderApi(payload);

    if (invalidateQuery) {
    }
  };

  const updateMediaFile = async (
    updateData: {
      data: mediaDataTypes;
      mediaFileId: string;
    },
    invalidateQuery: boolean = true,
  ) => {
    const updateMediaFile = await updateMediaFileApi(
      updateData,
      eventId,
      sessionId,
    );

    // do not invalidate query because it causes infinite rerenders.
    // Since mediaFile approvement ist persited locally anyway this should not be a problem
    if (invalidateQuery) {
      // #QUERY#
      if (detailsEditing === 'slots') {
        queryClient.invalidateQueries({
          queryKey: ['slots', 'list', sessionId],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['session', 'details', sessionId],
        });
      }
    }

    return updateMediaFile;
  };

  const getActorsMedia = (actorId: string) => {
    // get from session
    const actorMedia = sessionData
      ?.get('media')
      .filter((media: mediaDataTypes) => {
        return media.get('owner')?.id === actorId;
      });

    // get from all slots
    let slotMedia: mediaDataTypes[] = [];

    slotsData.forEach((slot: slotDataTypes) => {
      const media = slot.media?.filter((media: mediaDataTypes) => {
        return media.get('owner')?.id === actorId;
      });

      if (media) slotMedia = [...slotMedia, ...media];
    });

    return [...actorMedia, ...slotMedia];
  };

  //// UPDATES AFTER SLOTDATA CHANGED
  // update the slot order
  const updateSlotOrder = async (
    slotOrder: any[],
    invalidateQuery: boolean = true,
    resortedPauseData?: slotDataTypes,
  ) => {
    // remove split slot id appendix
    slotOrder.forEach((slot: slotDataTypes, index: number) => {
      if (slot?.id!.includes('-part')) {
        slotOrder[index].id = slot.id!.split('-part')[0];
      }
    });

    //
    await updateSlotOrderApi(sessionId!, slotOrder, resortedPauseData);

    // Update the slotsData in the context
    setSlotsData(slotOrder);

    // Update the query cache directly
    queryClient.setQueryData(['slots', 'list', sessionId], slotOrder);

    // #QUERY#
    // if (invalidateQuery) {
    //   queryClient.invalidateQueries({
    //     queryKey: ['slots', 'list', sessionId],
    //   });
    // }
  };

  // session update
  const updateSession = async (
    newSessionData: any,
    invalidateQuery: boolean = true,
    invalidateEventQuery: boolean = false,
  ) => {
    const shallowData = { ...newSessionData.attributes };
    shallowData['id'] = sessionId;

    console.log('###1-newSessionData', newSessionData);

    // sync data to the server
    const updatedSession = await updateSessionApi(shallowData, eventId);

    // update the local session store
    setStoreSessionData(updatedSession);

    // if desired invalidate the query
    if (invalidateQuery) {
      queryClient.invalidateQueries({
        queryKey: ['session', 'details', sessionId],
      });
    }

    // reset the sessions list if we have a multisession event
    if (eventData?.multiSession) {
      queryClient.resetQueries({
        queryKey: ['sessions', 'list', eventId],
      });
    }

    // invalidate event data, especially needed for
    // starttime update coming from slot list actions
    if (invalidateEventQuery) {
      queryClient.invalidateQueries({
        queryKey: ['event', 'details', eventId],
      });
    }
  };

  // update slot navigation data
  const updateSlotNavData = (newActiveSlotData: any) => {
    if (slotsData) {
      const currIndex = slotsData.findIndex(
        (slot: any) => slot.id === newActiveSlotData.id,
      );
      setSlotNavigationData({
        currIndex: currIndex,
        totalItems: slotsData.length,
      });
    }
  };

  //// ACTION MENU FUNCTIONS
  const trimSlot = (slot: slotDataTypes) => {
    // !!! it's not going to be that easy for split slots....
    if (slotsRenderDataUI) {
      // get the index of the slot in render data
      const indexInRenderData = slotsRenderDataUI?.slotsRenderData?.findIndex(
        (renderSlot) => renderSlot?.id === slot?.id,
      );

      if (indexInRenderData !== -1 && indexInRenderData !== undefined) {
        // index is valid
        // local copy of the slot for mutation
        const localRenderSlot = {
          ...slotsRenderDataUI.slotsRenderData![indexInRenderData],
        };

        if (localRenderSlot.id!.includes('-part_')) {
          const [mainId, part] = localRenderSlot.id!.split('-part_');

          const allSplitIndexes = getAllIndexes(
            slotsRenderDataUI.slotsRenderData!,
            mainId,
          );

          let overallTime = 0;

          allSplitIndexes.forEach((index) => {
            overallTime +=
              slotsRenderDataUI.slotsFeedback![index].timing.calculatedDuration;
          });

          localRenderSlot.durationPlaned = overallTime;
          localRenderSlot.durationMin = overallTime;

          updateSlot(localRenderSlot);
        } else {
          // regular slots
          // set the duration to the calculated time
          localRenderSlot.durationPlaned =
            slotsRenderDataUI?.slotsFeedback[indexInRenderData!].timing
              .calculatedDuration;
          localRenderSlot.durationMin =
            slotsRenderDataUI?.slotsFeedback[indexInRenderData!].timing
              .calculatedDuration;

          updateSlot(localRenderSlot);
        }
      }
    }
  };

  const splitSlot = (slot: slotDataTypes) => {
    //
    if (slotsRenderDataUI) {
      // get the index of the slot in render data
      const indexInRenderData = slotsRenderDataUI?.slotsRenderData?.findIndex(
        (renderSlot) => renderSlot?.id === slot?.id,
      );

      if (indexInRenderData !== -1 && indexInRenderData !== undefined) {
        // index is valid
        if (
          slotsRenderDataUI.slotsRenderData![indexInRenderData + 1]?.type ===
          'pause'
        ) {
          // yes, the next elemt is a pause
          // local copy of the slots for mutation

          // slots to copy for mutation
          const localSlots = [...slotsRenderDataUI.slotsRenderData!];

          // add second part after break
          localSlots.splice(
            indexInRenderData + 2,
            0,
            slotsRenderDataUI.slotsRenderData![indexInRenderData]!,
          );

          // remove buffer objects from list
          const cleanedItemsForUpdating = localSlots.filter(
            (item: slotDataTypes) => !item?.id!.includes('buffer-'),
          );

          // // remove virtual eos objects from list
          // if (!sessionData.endtimePlanned)
          //   cleanedItemsForUpdating = cleanedItemsForUpdating.filter(
          //     (item: slotDataTypes) => item?.type !== 'eos',
          //   );

          // update slot order
          updateSlotOrder(cleanedItemsForUpdating);
        }
      }
    }
  };

  const movePause = (slot: slotDataTypes) => {
    if (slotsRenderDataUI) {
      // get the index of the slot in render data
      const indexInRenderData = slotsRenderDataUI?.slotsRenderData?.findIndex(
        (renderSlot) => renderSlot?.id === slot?.id,
      );

      if (indexInRenderData !== -1 && indexInRenderData !== undefined) {
        if (
          slotsRenderDataUI.slotsRenderData![indexInRenderData + 1]
            ?.starttimePlanned
        ) {
          // only do something if the next slot has a fixed starttime
          if (
            slotsRenderDataUI.slotsRenderData![indexInRenderData]?.id!.includes(
              '-part_',
            )
          ) {
            // split slot
            // none split slots
            const localFixedSlot = {
              ...slotsRenderDataUI.slotsRenderData![indexInRenderData + 1],
            };

            const slotOverlap =
              slotsRenderDataUI.slotsFeedback![indexInRenderData].timing
                .overlapMinutes;

            const fixedSlotStarttime = new Date(
              localFixedSlot.starttimePlanned!,
            );

            fixedSlotStarttime.setMinutes(
              fixedSlotStarttime.getMinutes() + slotOverlap,
            );

            localFixedSlot.starttimePlanned = fixedSlotStarttime;

            updateSlot(localFixedSlot);

            // check if we change the eos
            if (
              localFixedSlot.type === 'eos' &&
              slotsRenderDataUI.sessionTiming.endTimePlanned
            ) {
              // need to update the sessions endtime
              const newSessionData = sessionData.clone();
              newSessionData.set('endtimePlanned', fixedSlotStarttime);

              updateSession(newSessionData);
            }
          } else {
            // none split slots
            const localFixedSlot = {
              ...slotsRenderDataUI.slotsRenderData![indexInRenderData + 1],
            };
            const slotOverlap =
              slotsRenderDataUI.slotsFeedback![indexInRenderData].timing
                .overlapMinutes;

            const fixedSlotStarttime = new Date(
              localFixedSlot.starttimePlanned!,
            );

            fixedSlotStarttime.setMinutes(
              fixedSlotStarttime.getMinutes() + slotOverlap,
            );

            localFixedSlot.starttimePlanned = fixedSlotStarttime;

            updateSlot(localFixedSlot);

            // check if we change the eos
            if (
              localFixedSlot.type === 'eos' &&
              slotsRenderDataUI.sessionTiming.endTimePlanned
            ) {
              // need to update the sessions endtime
              const newSessionData = sessionData.clone();
              newSessionData.set('endtimePlanned', fixedSlotStarttime);

              updateSession(newSessionData);
            }
          }
        }
      }
    }
  };

  const fillBuffer = (slot: slotDataTypes) => {
    if (slotsRenderDataUI) {
      // get the index of the slot in render data
      const indexInRenderData = slotsRenderDataUI?.slotsRenderData?.findIndex(
        (renderSlot) => renderSlot?.id === slot?.id,
      );

      if (indexInRenderData !== -1 && indexInRenderData !== undefined) {
        // index is valid
        if (
          indexInRenderData + 1 <
            slotsRenderDataUI.slotsRenderData!.length - 1 &&
          slotsRenderDataUI.slotsRenderData![indexInRenderData + 1]!.type ===
            'buffer'
        ) {
          //next item is indeed a buffer
          const bufferDuration =
            slotsRenderDataUI.slotsTiming[indexInRenderData + 1].duration;

          const localRenderSlot = {
            ...slotsRenderDataUI.slotsRenderData![indexInRenderData],
          };

          if (localRenderSlot.id!.includes('-part_')) {
            localRenderSlot.durationPlaned =
              localRenderSlot.durationPlaned! + bufferDuration;

            updateSlot(localRenderSlot);
          } else {
            localRenderSlot.durationPlaned =
              localRenderSlot.durationPlaned! + bufferDuration;

            updateSlot(localRenderSlot);
          }
        }
      }
    }
  };

  //// EFFECTS
  // updates on slot changes
  useEffect(() => {
    // update the slot list
    setSlotsData(slots);

    // update the active slot data if there was a slot active
    if (activeSlotData) {
      setActiveSlotData((slot: slotDataTypes) => {
        return slots.filter((s: slotDataTypes) => s?.id === slot?.id)[0];
      });
    }

    // update the slot navigation
    setSlotNavigationData({
      currIndex: 0,
      totalItems: slots.length,
    });
  }, [slots]);

  // updates on session change
  useEffect(() => {
    // update the session data state
    setSessionData(session);

    if (sessionData) {
      sessionIdRef.current = session.id;

      setSessionActorData(sessionData.get('actors'));
      setSessionMediaData(sessionData.get('media'));

      const sessionActorInvitationData: { [key: string]: string } = {};
      sessionData.get('actors').map((actor: Parse.Object, index: number) => {
        sessionActorInvitationData[actor.id] =
          process.env.REACT_APP_PARENT_PUBLIC_URL +
          '?eit=' +
          actor.get('invitationToken') +
          '&rp=/events/' +
          masterEventId +
          '/' +
          eventId +
          '/sessions' +
          '&sId=' +
          useAuthStore.getState().user.ouid;
      });
      setSessionActorInvitationLinks(sessionActorInvitationData);

      //// NOT NEEDED ANY MORE
      // // publish session name for navigation
      // events?.publish('subMenuPos', {
      //   subMenuPos: sessionData?.get('title'),
      //   backLink: true,
      //   // type: 'delete',
      // });
    }
  }, [session]);

  // set the second level data (actors, media)
  useEffect(() => {
    console.log('#7#-activeSlotData-changed', activeSlotData);

    // set actors
    if (activeSlotData && activeSlotData.actors) {
      setSlotActorData(activeSlotData.actors);

      const activeSlotActorInvitationData: { [key: string]: string } = {};

      activeSlotData.actors.map((actor: Parse.Object, index: number) => {
        activeSlotActorInvitationData[actor.id] =
          process.env.REACT_APP_PARENT_PUBLIC_URL +
          '?eit=' +
          actor.get('invitationToken') +
          '&rp=/events/' +
          masterEventId +
          '/' +
          eventId +
          '/sessions' +
          '&sId=' +
          useAuthStore.getState().user.ouid;
      });

      setSlotInvitationLinks(activeSlotActorInvitationData);
    }

    // set media
    if (activeSlotData && activeSlotData.media) {
      setSlotMediaData(activeSlotData.media);
    }

    // update navigation data
    if (activeSlotData) {
      const newNavIndex = slotsData?.findIndex(
        (slot: slotDataTypes) => slot?.id === activeSlotData.id,
      );

      setSlotNavigationData((oldData: any) => {
        const newData = { ...oldData };
        newData.currIndex = newNavIndex;
        return newData;
      });
    }
  }, [activeSlotData]);

  //
  useEffect(() => {
    setActorsEvent(actorsSession);
  }, [actorsSession]);

  useEffect(() => {
    const newActorList = actorsEvent.map((actor) => {
      const newActor: ActorsListEventTypes = {
        id: '',
        status: 'pending',
        firstname: '',
        lastname: '',
        organisation: '',
        title: '',
        displayActorInAgenda: true,
        email: '',
      };

      newActor.id = actor.id;
      newActor.status = actor.get('status');
      newActor.firstname = actor.get('firstname');
      newActor.lastname = actor.get('lastname');
      newActor.organisation = actor.get('organisation');
      newActor.title = actor.get('title');
      newActor.email = actor.get('email');

      return newActor;
    });
    setActorsEventMapped(newActorList);
  }, [actorsEvent]);

  useEffect(() => {
    activeEditIdRef.current = activeEditId;
  }, [activeEditId]);

  //// update agenda
  // agenda mutation function
  const agendaMutation = useMutation({
    mutationFn: (agendaPayload: any) => {
      return updateSessionAgendaApi(
        agendaPayload.agendaData,
        agendaPayload.agendaTypes,
        agendaPayload.updateProgramOnly,
      );
    },
    // onSuccess: (data) => console.log('#!#!-agendaData', data),
    // onSettled: () => setAgendaUpdateTimeout(null),
  });

  const updateAgenda = (
    e: any,
    agendaTypes: agendaType | agendaType[],
    updateProgramOnly?: boolean,
  ) => {
    // build the agenda jsons
    const agendaData = buildAgenda(sessionData, slotsRenderDataUI);

    if (agendaData) {
      agendaMutation.mutate({
        agendaData: agendaData,
        agendaTypes: agendaTypes,
        updateProgramOnly: updateProgramOnly,
      });
    }
  };

  // update the agenda on any changes to slotlist  or session
  useEffect(() => {
    if (slotsRenderDataUI && sessionData) {
      // if a timeout that did not yet execute exists, stop it
      if (agendaUpdateTimeout) {
        clearTimeout(agendaUpdateTimeout);
        setAgendaUpdateTimeout(null);
      }

      // build the agenda jsons
      const agendaData = buildAgenda(sessionData, slotsRenderDataUI);

      if (agendaData) {
        // // use timeout to decouple multiple calls caused by rerenders
        // const agendaTimeout = setTimeout(() => {
        //   agendaMutation.mutate({
        //     agendaData: agendaData,
        //     agendaTypes: ['preview'],
        //   });
        //   setAgendaUpdateTimeout(null);
        // }, 1000);

        // setAgendaUpdateTimeout(agendaTimeout);

        agendaMutation.mutate({
          agendaData: agendaData,
          agendaTypes: ['preview'],
        });
      }
    }
  }, [slotsRenderDataUI, sessionData]);

  // update the actorslist
  useEffect(() => {
    // set all actors in this session

    // const allActors = new Set<actorDataTypes>();
    const allActors: actorDataTypes[] = [];

    // make sure evey actor is only added once & only for the currently active session
    actorsSession.forEach((actor: actorDataTypes) => {
      if (
        actor
          .get('parents')
          ?.some(
            (parent: any) =>
              parent.className === 'Session' && parent.id === sessionData.id,
          ) &&
        !allActors.some((act: actorDataTypes) => act.id === actor.id)
      ) {
        allActors.push(actor);
      }
    });

    slots.forEach((slot: slotDataTypes) => {
      const actors: actorDataTypes[] = slot.actors!;
      actors?.forEach((actor) => {
        if (!allActors.some((act: actorDataTypes) => act.id === actor.id)) {
          allActors.push(actor);
        }
      });
    });

    // sort the list

    allActors?.sort((a, b) => {
      const lastnameA = a.get('lastname')
        ? a.get('lastname').toLowerCase()
        : a.get('firstname').toLowerCase();
      const lastnameB = b.get('lastname')
        ? b.get('lastname').toLowerCase()
        : b.get('firstname').toLowerCase();

      if (lastnameA < lastnameB) {
        return -1;
      }
      if (lastnameA > lastnameB) {
        return 1;
      }
      return 0;
    });

    console.log('###!!!-allActors', allActors);

    //
    setAllSessionActors(allActors);
  }, [slots, actorsSession, sessionData]);

  // expose state & functions
  const value = {
    sessionData,
    setSessionData,
    sessionActorData,
    allSessionActors,
    sessionMediaData,
    slotsData,
    setSlotsData,
    updateSlotsData,
    slotsRenderDataUI,
    // setSlotsRenderDataUI,
    updateSlotRendering,
    changeUpdateSlotRendering,
    updateSlotsRenderDataUI,
    activeEditId,
    setActiveEditId,
    activeDndId,
    setActiveDndId,
    activeSlotData,
    setActiveSlotData,
    slotNavigationData,
    setSlotNavigationData,
    navigateSlot,
    detailsEditing,
    setDetailsEditing,
    addNewSlot,
    updateSlot,
    deleteSlot,
    updateSlotOrder,
    updateSession,
    mainTabValue,
    setMainTabValue,
    slotActorData,
    actorsEvent,
    actorsEventMapped,
    slotInvitationLinks,
    sessionActorInvitationLinks,
    addNewActor,
    updateActor,
    updateActorOrder,
    deleteActor,
    appendActor,
    removeActorFromParent,
    slotMediaData,
    addNewMedia,
    addNewMediaFileBased,
    addNewMediaVersion,
    deleteMedia,
    deleteMediaVersion,
    updateMedia,
    updateMediaFile,
    updateMediaOrder,
    getActorsMedia,
    // downloadMedia,
    trimSlot,
    splitSlot,
    movePause,
    fillBuffer,
    updateAgenda,
  };

  return <SlotContext.Provider value={value}>{children}</SlotContext.Provider>;
}

// provide hook to use slotcontext
export const useSlotContext = (): SlotContextType => {
  // use our context
  const context = useContext(SlotContext);

  // make sure we return a valid context
  if (!context) {
    throw new Error('useSlotContext must be used within a SlotProvider');
  }

  return context;
};

function getAllIndexes(arr: slotDataTypes[], val: string) {
  const indexes = [];
  let i;
  for (i = 0; i < arr.length; i++)
    if (arr[i]?.id!.includes(val)) indexes.push(i);
  return indexes;
}

function buildAgenda(
  sessionData: any,
  slotsRenderDataUI: slotlistRenderDataTypes | undefined,
) {
  // build the session agenda
  const sessionAgenda = {
    id: sessionData.id,
    eventId: sessionData.get('event').id,
    title: sessionData.get('title'),
    description: sessionData.get('description'),
    startdate: sessionData.get('startdate'),
    indexDay: sessionData.get('indexDay'),
    starttime: slotsRenderDataUI?.sessionTiming.sessionStartTime,
    endtime: slotsRenderDataUI?.sessionTiming.sessionEndTime,
    showInProgram: sessionData.get('showInProgram'),
    showDescriptionInProgram: sessionData.get('showDescriptionInProgram'),
    showActorsInProgram: sessionData.get('showActorsInProgram'),
    hasOnlineStage: sessionData.get('scene').active,
    showInAgenda: sessionData.get('showInAgenda'), // not needed anymore?

    showSessionDescriptionInAgenda: sessionData.get(
      'showSessionDescriptionInAgenda',
    ),
    showSessionActorsInAgenda: sessionData.get('showSessionActorsInAgenda'),
    actors: sessionData.get('actors').map((actor: actorDataTypes) => {
      const act = actor.toJSON();
      delete act.event;
      delete act.parents;
      delete act.invitationToken;

      // remove user pointer, but add userId
      const userId = act.user?.objectId;
      delete act.user;
      act.userId = userId;

      return act;
    }),
  };

  // map the slots agenda
  const slotsAgenda: any[] = [];

  slotsRenderDataUI?.slotsRenderData.forEach((slot, index) => {
    if (slot.type !== 'buffer') {
      const slotAgendaItem = {
        id: slot.id,
        sessionId: slot.sessionId,
        title: slot.title,
        description: slot.description,
        type: slot.type,
        durationPlaned: slot.durationPlaned,
        durationMin: slot.durationMin,
        showInAgenda: slot.showInAgenda,
        showDescriptionInAgenda: slot.showDescriptionInAgenda,
        showActorsInAgenda: slot.showActorsInAgenda,
        newColumnAfter: slot.newColumnAfter,
        actorOptions: slot.actorOptions,
        actors: slot.actors?.map((actor: actorDataTypes) => {
          const act = actor.toJSON();
          delete act.event;
          delete act.parents;
          delete act.invitationToken;

          // remove user pointer, but add userId
          const userId = act.user?.objectId;
          delete act.user;
          act.userId = userId;

          return act;
        }),
        starttime: slotsRenderDataUI.slotsTiming[index].startTime,
        endtime: slotsRenderDataUI.slotsTiming[index].endTime,
      };
      slotsAgenda.push(slotAgendaItem);
    }
  });

  return { sessionAgenda: sessionAgenda, slotsAgenda: slotsAgenda };
}
