import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export type sendStaffInvitationParams = {
  staffId: string;
  appUrl: string;
  eventId: { masterEventId: string; eventId: string };
};

export default async function sendStaffInvitationApi(
  params: sendStaffInvitationParams,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run('sendStaffInvitation', params, {
    sessionToken: sessionToken,
  });
}
