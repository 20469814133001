import { ErSlotContentTypesOptions } from 'eventrocket-commoncomponents';
import { useSlotContext } from '../../Slots/Context/SlotContext';
import { Stack, Typography } from '@mui/material';

import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import MediaList from '../../Media/MediaList';
import { useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { filterSortParents } from '../Actors';

type ActorSlotlistProps = { actorData: Parse.Object };

export default function ActorSlotlist(props: ActorSlotlistProps) {
  // props
  const { actorData } = props;

  // url params
  const { sessionId } = useParams();

  console.log('###!!!-actorData', actorData);

  const actorParents = actorData?.get('parents');
  const actorParentsFiltered = filterSortParents(actorParents, sessionId!);
  const actorUserId = actorData?.get('user')?.id;

  // slot context
  const {
    slotsRenderDataUI,
    deleteMedia,
    deleteMediaVersion,
    updateMediaFile,
    updateMedia,
    updateMediaOrder,
  } = useSlotContext();

  //// slots
  // get the slots starttime
  const getStarttime = (parent: Parse.Object) => {
    if (parent.className === 'Slot') {
      const slotIndex = slotsRenderDataUI?.slotsRenderData?.findIndex(
        (slot: any) => slot.id === parent.id,
      );

      if (slotIndex !== undefined && slotIndex !== -1) {
        return slotsRenderDataUI?.slotsTiming?.[slotIndex]?.startTime;
      }
    } else {
      return parent?.get('starttime');
    }
  };

  // build the slots full title
  const getSlotTitle = (parent: Parse.Object) => {
    if (parent.className === 'Slot') {
      if (parent?.get('slotContentType')) {
        const typeLabel = ErSlotContentTypesOptions.find(
          (contentType) => contentType.value === parent.get('slotContentType'),
        )?.label;

        return typeLabel + ': ' + parent?.get('title');
      } else {
        return parent?.get('title');
      }
    } else {
      return 'Moderation: ' + parent?.get('title');
    }
  };

  // get actors slot media
  const getActorsSlotMedia = (parent: Parse.Object) => {
    // get media that belongs to the actor
    const actorsMedia = parent?.get('media')?.filter((medium: any) => {
      return actorUserId === medium.get('owner')?.id;
    });

    return actorsMedia;
  };

  // get media requested from the user
  const getActorsRequestedMedia = (parent: Parse.Object) => {
    const hasRequestedMedia = parent
      ?.get('actorOptions')
      ?.some((option: any) => option.actorId === actorData.id);

    return hasRequestedMedia;
  };

  return (
    <>
      {actorParentsFiltered &&
        actorParentsFiltered.map((parent: Parse.Object) => {
          return (
            <React.Fragment key={parent.id}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  mb:
                    getActorsSlotMedia(parent) &&
                    getActorsSlotMedia(parent).length > 0
                      ? 0
                      : 1,
                }}
              >
                {/* <VideoLibraryOutlinedIcon sx={{ fontSize: '1rem' }} /> */}
                <Typography variant="body1">
                  {getStarttime(parent)?.toLocaleTimeString('de-DE', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </Typography>
                <Typography variant="body1">{getSlotTitle(parent)}</Typography>
              </Stack>
              {((getActorsSlotMedia(parent) &&
                getActorsSlotMedia(parent).length > 0) ||
                getActorsRequestedMedia(parent)) && (
                <>
                  {console.log('###!!!-inMedia')}
                  <MediaList
                    variant="reduced"
                    media={getActorsSlotMedia(parent)}
                    // mediaquery={false}
                    deleteMedia={deleteMedia}
                    deleteMediaVersion={deleteMediaVersion}
                    updateMedia={updateMedia}
                    updateMediaOrder={updateMediaOrder}
                    updateMediaFile={updateMediaFile}
                  />
                </>
              )}
            </React.Fragment>
          );
        })}
    </>
  );
}
