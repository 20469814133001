import { Box, Theme, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import PreviewNavigation from '../Navigation/PreviewNavigation';
import { Outlet, useParams } from 'react-router-dom';
import { PreviewContextProvider } from '../Preview/PreviewContext';
import { Suspense } from 'react';
import EventSettingsModal from '../Event/EventSettingsModal';
import { EventDetailsFormContextProvider } from '../Event/Form/EventDetailsFormContext';
import useEventStore from '../../store/eventStore';
import { useQueryClient } from '@tanstack/react-query';
import useEventQueryHook from '../../data/event/event.query';

export default function PreviewLayout() {
  // get path params
  const { eventId, sessionId } = useParams();

  // theme
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  //// react query
  const queryClient = useQueryClient();

  // subscribe to cached queries
  useEventQueryHook(eventId!);

  // event data
  const eventData = useEventStore((store) => store.data);
  const eventDataStatus = useEventStore((store) => store.status);

  return (
    <>
      {eventDataStatus === 'success' && (
        <>
          <PreviewContextProvider>
            <EventDetailsFormContextProvider eventData={eventData}>
              <PreviewNavigation />

              {/* Spacer */}
              {/* <Toolbar
                sx={{
                  maxHeight: isMobile ? '160px' : '56px',
                  height: isMobile ? '160px' : 'unset',
                }}
              ></Toolbar> */}

              <Outlet />
              {/* </Suspense> */}
            </EventDetailsFormContextProvider>
          </PreviewContextProvider>
        </>
      )}
    </>
  );
}
