import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default async function updateStaffRoleApi(
  eventId: string,
  staffId: string,
  staffRole: string,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'updateStaffRole',
    { eventId, staffId, staffRole },
    { sessionToken: sessionToken },
  );
}
