import * as yup from 'yup';
import i18n from 'i18next';

export type CrewNewMemberFormData = {
  name: string;
  email: string;
  role: string;
};

export const crewNewMemberFormDataValSchema = yup.object({
  email: yup
    .string()
    .email(() =>
      i18n.t('user:SETTINGS.FORM.USERNAME.FIELD_email.Error_invalid'),
    )
    .required(() =>
      i18n.t('user:SETTINGS.FORM.USERNAME.FIELD_email.Error_missing'),
    ),
  name: yup.string().required(),
  role: yup.string().required(),
});

export const crewNewMemberFormDataDafaults = {
  name: '',
  email: '',
  role: 'eventRead',
};
