import {
  Box,
  Container,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { changeRgbAlpha } from '../../util/colorHelpers';
import { useState } from 'react';
import ActorDetailsModal from './ActorDetails/ActorDetailsModal';
import {
  actorDataTypes,
  mediaDataTypes,
  useSlotContext,
} from '../Slots/Context/SlotContext';
import { ErSlotContentTypesOptions } from 'eventrocket-commoncomponents';
import useSlotlistRenderData from '../Slots/Context/useSlotlistRenderData';
import { useFeedback } from '../Feedback/FeedbackContext';
import { mediaIconStateTypes } from '../Slots/SlotListItem';
import getMediaState from '../../util/media/getMediaState';
import sendActorInvitationApi from '../../api/actor/sendActorInvitationApi';
import { useParams } from 'react-router-dom';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import useUserPermissions from '../Hooks/useUserPermissions';
import { useTranslation } from 'react-i18next'; // Import useTranslation

export default function Actors() {
  // url params
  const { masterEventId, eventId, sessionId } = useParams();

  //
  const {
    allSessionActors,
    actorsEvent,
    deleteActor,
    slotsRenderDataUI,
    getActorsMedia,
  } = useSlotContext();

  console.log('#actorLists', allSessionActors, actorsEvent);

  // feedback
  const { setFeedback } = useFeedback();

  // permission handling
  const { hasPermission } = useUserPermissions();

  // translation
  const { t } = useTranslation('actor');

  // delete actor handling
  const handleDeleteActor = (actorId: string) => {
    setFeedback({
      type: 'confirmAction',
      headline: t('ACTORS.HEADLINE_deleteActor'),
      message: t('ACTORS.MESSAGE_deleteActorFromAllSlots'),
      confirmButtons: {
        cancel: t('ACTORS.BUTTON_DELETE_cancel'),
        confirm: t('ACTORS.BUTTON_DELETE_confirm'),
      },
      callback: () => deleteActor(actorId),
    });
  };

  // handle detailsModal
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  // state
  const [activeActorIndex, setActiveActorIndex] = useState(0);
  const [activeActorData, setActiveActorData] = useState(actorsEvent[0]);

  const toggleModalOpen = (actorId?: string) => {
    if (actorId) {
      const actorIndex = actorsEvent.findIndex(
        (actor: actorDataTypes) => actor.id === actorId,
      );

      if (actorIndex !== undefined && actorIndex !== -1) {
        setActiveActorIndex(actorIndex);
        setActiveActorData(actorsEvent[actorIndex]);
      }
    }

    setDetailModalOpen(!detailModalOpen);
  };

  const stepThroughActors = (direction: 1 | -1) => {
    let newIndex = activeActorIndex + direction;

    if (newIndex > actorsEvent.length - 1) newIndex = 0;
    if (newIndex < 0) newIndex = actorsEvent.length - 1;

    setActiveActorData(actorsEvent[newIndex]);
    setActiveActorIndex(newIndex);
  };

  // theme
  const theme = useTheme();

  // get the slots starttime
  const getStarttime = (parent: Parse.Object) => {
    if (parent.className === 'Slot') {
      const slotIndex = slotsRenderDataUI?.slotsRenderData.findIndex(
        (slot: any) => slot.id === parent.id,
      );

      if (slotIndex !== undefined && slotIndex !== -1) {
        return slotsRenderDataUI?.slotsTiming?.[slotIndex].startTime;
      }
    } else {
      return parent.get('starttime');
    }
  };

  // build the slots full title
  const getSlotTitle = (parent: Parse.Object) => {
    if (parent.className === 'Slot') {
      if (parent.get('slotContentType')) {
        const typeLabel = ErSlotContentTypesOptions.find(
          (contentType) => contentType.value === parent.get('slotContentType'),
        )?.label;

        return typeLabel + ': ' + parent.get('title');
      } else {
        return parent.get('title');
      }
    } else {
      return t('ACTORS.LABEL_moderation') + ': ' + parent.get('title');
    }
  };

  // get actor state icon color
  const getActorIconState = (actor: actorDataTypes) => {
    //
    const actorState = { color: theme.palette.action.active };
    const invitationState = actor.get('invitationStatus');

    switch (invitationState) {
      case 'pending':
        actorState.color = theme.palette.warning.main;
        break;
      case 'confirmed':
        actorState.color = theme.palette.success.main;
        break;
      case 'rejected':
        actorState.color = theme.palette.error.main;
        break;
    }

    return actorState;
  };

  const getMediaSingleState = (actor: actorDataTypes, slot: any) => {
    //
    let mediaIconState: mediaIconStateTypes = {
      mediaColor: theme.palette.warning.main,
      mediaActive: true,
    };

    // get actors media in this slot
    const media = slot.get('media')?.filter((medium: mediaDataTypes) => {
      return medium?.get('owner')?.id === actor.get('user')?.id;
    });

    // get actors requested media in this slot
    const actorsOptions = slot.get('actorOptions')?.find((option: any) => {
      return option.actorId === actor.id;
    });

    // check the state of the media
    if (media)
      mediaIconState = getMediaState({
        actor: actor,
        media: media,
        actorOptions: [actorsOptions],
        mediaRequests: actorsOptions?.requestMediaUpload,
        theme: theme,
      });

    return mediaIconState;
  };

  // query client
  const queryClient = useQueryClient();

  // handle invitation sending
  const handleSendInvitation = (actor: actorDataTypes) => {
    //
    let htmlMessage = `<p>${t('ACTORS.MESSAGE_actor')} ${actor.get(
      'firstname',
    )} ${actor.get('lastname')} (${actor.get('email')}) ${
      actor.get('invitationStatus') === 'unknown'
        ? t('ACTORS.MESSAGE_inviteToSlots')
        : t('ACTORS.MESSAGE_remindOf')
    } </p><ul>`;

    const actorParents = actor.get('parents');

    actorParents.forEach((parent: any) => {
      htmlMessage += `<li>${parent.get('title')}</li>`;
    });
    //
    htmlMessage += '</ul>';
    // get media requests
    const mediaRequests = actorParents.filter((parent: any) => {
      return parent
        .get('actorOptions')
        .some(
          (option: any) =>
            option.actorId === actor.id && option.requestMediaUpload === true,
        );
    });

    if (mediaRequests.length > 0) {
      htmlMessage += `<p>${
        actor.get('invitationStatus') === 'unknown'
          ? t('ACTORS.MESSAGE_requestMediaFor')
          : t('ACTORS.MESSAGE_mediaFor')
      }</p>`;
      htmlMessage += '<ul>';

      mediaRequests.forEach((req: any) => {
        htmlMessage += `<li>${req.get('title')}</li>`;
      });

      htmlMessage += '</ul>';
    }

    setFeedback({
      type: 'confirmAction',
      headline:
        actor.get('invitationStatus') === 'unknown'
          ? t('ACTORS.HEADLINE_sendInvitation')
          : t('ACTORS.HEADLINE_sendReminder'),
      htmlMessage: htmlMessage,
      confirmButtons: {
        cancel: t('ACTORS.BUTTON_cancel'),
        confirm: t('ACTORS.BUTTON_confirm'),
      },
      callback: () =>
        sendActorInvitationApi({
          actorId: actor.id,
          eventId: { masterEventId: masterEventId!, eventId: eventId! },
          appUrl: process.env.REACT_APP_PARENT_PUBLIC_URL!,
          inviteToParents: actorParents.map((parent: any) =>
            parent.get('title'),
          ),
          mediaforParents: mediaRequests.map((parent: any) =>
            parent.get('title'),
          ),
          mediaforParentsIds: mediaRequests.map((parent: any) =>
            parent.toPointer(),
          ),
        }).then((res) => {
          // invalidate actors query
          queryClient.invalidateQueries({
            queryKey: ['session', 'actors', eventId],
          });
        }),
    });
  };

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          mt: { xs: '40px', sm: '40px' },
          maxHeight: 'calc(100vh - 146px)',
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr auto ',
              sm: '250px 66px 250px auto',
            },
          }}
        >
          {/* HEADER */}
          <Stack
            direction="row"
            spacing={1}
            sx={{
              borderBottom: `1px solid ${theme.palette.text.primary}`,
              pb: '4px',
              pr: '8px',
              // mb: '8px',
            }}
          >
            <PersonOutlineOutlinedIcon sx={{ fontSize: '1rem', opacity: 0 }} />
            <Typography variant="subtitle1">
              {t('ACTORS.LABEL_nameTitleOrganisation')}
            </Typography>
          </Stack>

          <Box
            sx={{
              borderBottom: `1px solid ${theme.palette.text.primary}`,
              pb: '4px',
              pr: '8px',
              // mb: '8px',
            }}
          >
            <Typography variant="subtitle1"></Typography>
          </Box>

          <Box
            sx={{
              display: { xs: 'none', sm: 'block' },
              borderBottom: `1px solid ${theme.palette.text.primary}`,
              pb: '4px',
              pr: '8px',
              // mb: '8px',
            }}
          >
            <Typography variant="subtitle1">
              {t('ACTORS.LABEL_emailMobile')}
            </Typography>
          </Box>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: { xs: 'none', sm: 'flex' },
              borderBottom: `1px solid ${theme.palette.text.primary}`,
              pb: '4px',
              // mb: '8px',
            }}
          >
            <VideoLibraryOutlinedIcon sx={{ fontSize: '1rem', opacity: 0 }} />
            <Typography variant="subtitle1">
              {t('ACTORS.LABEL_slots')}
            </Typography>
          </Stack>

          {/* CONTENT */}
          {actorsEvent.map((actor: actorDataTypes, index: number) => {
            //
            const actorIconState = getActorIconState(actor);
            // const mediaAllState = getMediaAllState(actor);

            return (
              <React.Fragment key={actor.id}>
                <Stack
                  key={index + 'ct0'}
                  sx={{
                    cursor: 'pointer',
                    borderTop:
                      index !== 0
                        ? `1px solid ${changeRgbAlpha(
                            theme.palette.text.primary,
                            0.12,
                          )}`
                        : 'none',
                    py: '8px',
                    pr: '8px',
                    // mb: '8px',
                  }}
                  onClick={() => toggleModalOpen(actor.id)}
                >
                  <Stack direction="row" spacing={1}>
                    <PersonOutlineOutlinedIcon
                      sx={{ fontSize: '1rem', color: actorIconState.color }}
                    />
                    <Typography
                      variant="subtitle1"
                      color={theme.palette.primary.main}
                    >
                      {actor.get('firstname') + ' ' + actor.get('lastname')}
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <VideoLibraryOutlinedIcon
                      // sx={{ fontSize: '1rem', color: mediaAllState.mediaColor }}
                      sx={{ fontSize: '1rem', opacity: 0 }}
                    />
                    <Typography variant="subtitle1">
                      {actor.get('title')}
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <VideoLibraryOutlinedIcon
                      sx={{ fontSize: '1rem', opacity: 0 }}
                    />
                    <Typography variant="subtitle1">
                      {actor.get('organisation')}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  key={index + 'ct1'}
                  direction="row"
                  spacing={1}
                  sx={{
                    borderTop:
                      index !== 0
                        ? `1px solid ${changeRgbAlpha(
                            theme.palette.text.primary,
                            0.12,
                          )}`
                        : 'none',
                    pt: '8px',
                    pr: '8px',
                    // mb: '8px',
                    height: '42px',
                  }}
                >
                  <IconButton
                    key={index + 'ct1-bt0'}
                    sx={{ p: 0 }}
                    onClick={() => handleSendInvitation(actor)}
                    disabled={
                      !actor.get('email') ||
                      actor.get('email') === '' ||
                      !hasPermission({
                        objPath: 'actors',
                        operation: 'update',
                      }) ||
                      !allSessionActors.some((act) => act.id === actor.id)
                    }
                  >
                    <MailOutlinedIcon />
                  </IconButton>
                  <IconButton
                    disabled={
                      !hasPermission({
                        objPath: 'actors',
                        operation: 'update',
                      })
                    }
                    key={index + 'ct1-bt1'}
                    sx={{ p: 0 }}
                    onClick={() => handleDeleteActor(actor.id)}
                  >
                    <DeleteForeverOutlinedIcon />
                  </IconButton>
                </Stack>

                <Stack
                  key={index + 'ct2'}
                  sx={{
                    display: { xs: 'none', sm: 'flex' },
                    borderTop:
                      index !== 0
                        ? `1px solid ${changeRgbAlpha(
                            theme.palette.text.primary,
                            0.12,
                          )}`
                        : 'none',
                    py: '8px',
                    pr: '8px',
                    // mb: '8px',
                  }}
                >
                  <Typography variant="subtitle1">
                    {actor.get('email')}
                  </Typography>
                  <Typography variant="subtitle1">
                    {actor.get('mobile')}
                  </Typography>
                </Stack>

                <Stack
                  key={index + 'ct3'}
                  sx={{
                    display: { xs: 'none', sm: 'flex' },
                    borderTop:
                      index !== 0
                        ? `1px solid ${changeRgbAlpha(
                            theme.palette.text.primary,
                            0.12,
                          )}`
                        : 'none',
                    py: '8px',
                    // mb: '8px',
                  }}
                >
                  {
                    // actor.get('parents');

                    allSessionActors.some((act) => act.id === actor.id) &&
                      filterSortParents(actor.get('parents'), sessionId!).map(
                        (parent: any, indexSlots: number) => {
                          const mediatate = getMediaSingleState(actor, parent);
                          return (
                            <Stack
                              direction="row"
                              spacing={1}
                              key={index + 'ct3_' + indexSlots + '_actorSlots'}
                            >
                              <VideoLibraryOutlinedIcon
                                sx={{
                                  fontSize: '1rem',
                                  color: mediatate.mediaColor,
                                  opacity:
                                    mediatate.mediaColor ===
                                    theme.palette.action.disabled
                                      ? 0
                                      : 1,
                                }}
                              />
                              <Typography variant="subtitle1">
                                {getStarttime(parent)?.toLocaleTimeString(
                                  'de-DE',
                                  {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                  },
                                )}
                              </Typography>
                              <Typography variant="subtitle1">
                                {getSlotTitle(parent)}
                              </Typography>
                            </Stack>
                          );
                        },
                      )
                  }
                </Stack>
              </React.Fragment>
            );
          })}
        </Box>
      </Container>

      <ActorDetailsModal
        modalOpen={detailModalOpen}
        modalClose={toggleModalOpen}
        actorData={activeActorData}
        stepThroughActors={stepThroughActors}
      />
    </>
  );
}

// filter & sort actor parents
export function filterSortParents(parents: Parse.Object[], sessionId: string) {
  const filteredList = parents.filter((parent: Parse.Object) => {
    if (parent.className === 'Session') {
      return parent.id === sessionId;
    } else {
      return parent?.get('session')?.id === sessionId;
    }
  });

  return filteredList;
}
