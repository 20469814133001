import Parse from 'parse';
import useAuthStore from '../../store/authStore';

type sendActorInvitationParams = {
  actorId: string;
  eventId: { masterEventId: string; eventId: string };
  appUrl: string;
  inviteToParents: Parse.Object[];
  mediaforParents: Parse.Object[];
  mediaforParentsIds: string[];
};
export default async function sendActorInvitationApi(
  params: sendActorInvitationParams,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'sendActorInvitation',
    { ...params },
    { sessionToken: sessionToken },
  );
}
