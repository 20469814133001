import { SvgIconComponent } from '@mui/icons-material';
import {
  Collapse,
  Divider,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useUserPermissions from '../../Hooks/useUserPermissions';

type EventDetailsFormSectionPropsTypes = {
  // expandedId: string;
  // targetId: string;
  headlineTranslationString: string;
  sublineTranslationString: string;
  translationNs: string;
  Icon: SvgIconComponent;
  FormComponent: ReactNode;
  mediaQueryMobile: boolean;
  keepOpen?: boolean;
  status?: string;
  active?: boolean;
  activationHandler?: () => void;
  noCollapse?: boolean;
};

export default function EventDetailsFormSection(
  props: EventDetailsFormSectionPropsTypes,
) {
  // props
  const {
    // targetId,
    // expandedId,
    headlineTranslationString,
    sublineTranslationString,
    translationNs,
    Icon,
    FormComponent,
    mediaQueryMobile,
    keepOpen = false,
    status,
    active,
    activationHandler = () => {},
    noCollapse = false,
  } = props;

  // theme
  const theme = useTheme();

  // translation
  const { t } = useTranslation(translationNs);

  // permission handling
  const { hasPermission } = useUserPermissions();

  // state
  const [expanded, setExpanded] = useState(keepOpen);
  const toggleExpanded = () => {
    setExpanded((exp) => (keepOpen ? true : !exp));
  };

  // collapse animation ended state
  const [collapseEnd, setCollapseEnd] = useState(true);

  const toggleCollapseEnd = () => {
    setCollapseEnd((col) => !col);
  };

  return (
    <>
      {!mediaQueryMobile && !keepOpen && (
        <Stack
          sx={{
            width: '100%',
            cursor: !mediaQueryMobile ? 'pointer' : 'default',
          }}
          onClick={() =>
            !noCollapse && (active === undefined || active) && toggleExpanded()
          }
        >
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Icon />
                <Stack>
                  <Typography variant="h6">
                    {t(
                      headlineTranslationString,
                      '_' + headlineTranslationString,
                    )}
                  </Typography>
                  <Typography variant="subtitle2">
                    {t(
                      sublineTranslationString,
                      '_' + sublineTranslationString,
                    )}
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="row" alignItems="center">
                {active !== undefined && !active && (
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.primary.main}
                    component="span"
                    sx={{
                      width: { xs: '120px', sm: 'unset' },
                      mr: 2,
                    }}
                  >
                    Zur Aktivierung bitte den BRITESTAGE Support kontaktieren
                  </Typography>
                )}

                {!mediaQueryMobile && (active === undefined || active) && (
                  <>
                    <ExpandMoreIcon
                      sx={{
                        transform: expanded ? 'rotate(180deg)' : 'unset',
                        opacity: !noCollapse ? 1 : 0,
                      }}
                    />
                  </>
                )}

                {active !== undefined && (
                  <Switch
                    size="small"
                    checked={active}
                    disabled={
                      !hasPermission({
                        objPath: 'adminOverride',
                        operation: 'update',
                      })
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      activationHandler();
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </>
        </Stack>
      )}

      {mediaQueryMobile && active !== undefined && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Switch
            size="small"
            checked={active}
            onClick={(e) => {
              e.stopPropagation();
              activationHandler();
            }}
          />
          <Typography variant="subtitle1">
            {t(headlineTranslationString, '_' + headlineTranslationString) +
              ' ' +
              t(
                'DETAILS-FORM.ACTIVATE_switch',
                '_DETAILS-FORM.ACTIVATE_switch',
              )}
          </Typography>
        </Stack>
      )}

      <Collapse
        in={expanded || mediaQueryMobile}
        addEndListener={toggleCollapseEnd}
      >
        {FormComponent}
      </Collapse>

      {!mediaQueryMobile && <Divider sx={{ my: 2 }} />}
    </>
  );
}
