import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default async function setNewStaffApi(
  eventId: { masterEventId: string; eventId: string },
  name: string,
  role: string,
  email: string,
  appUrl: string,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'setNewStaff',
    {
      eventId: eventId,
      name: name,
      role: role,
      email: email,
      appUrl: appUrl,
    },
    { sessionToken: sessionToken },
  );
}
