import Parse from 'parse';
import useAuthStore from '../../store/authStore';
import { slotDataTypes } from '../../Components/Slots/Context/SlotContext';
import { clone } from 'lodash';

// !!! typing
export default async function updateSlotOrderApi(
  sessionId: string,
  slotOrder: any[],
  resortedPauseData?: slotDataTypes,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;
  const instanceId = useAuthStore.getState().instanceId;

  const slotOrderIds = slotOrder.map((slot, index) => {
    return slot.id;
  });

  let resortedPauseDataCopy;

  if (resortedPauseData) {
    resortedPauseDataCopy = clone(resortedPauseData);
    delete resortedPauseDataCopy.actors;
    delete resortedPauseDataCopy.media;
  }

  return Parse.Cloud.run(
    'updateSlotOrder',
    {
      sessionId: sessionId,
      slotOrder: slotOrderIds,
      resortedPauseData: resortedPauseDataCopy,
      instanceId: instanceId,
    },
    { sessionToken: sessionToken },
  );
}
