import JoditEditor from 'jodit-pro-react';
import { useRef, useState } from 'react';
import { htmlEditorDefaultConfig } from '../../util/htmlEditor/htmlEditorDefaultConfig';
import { Box, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

type PreviewTextEditorProps = {
  text: string;
  closeHandler?: () => void;
  submitHandler: (text: string) => void;
};

export default function PreviewTextEditor(props: PreviewTextEditorProps) {
  // props
  const { text, closeHandler = () => {}, submitHandler } = props;

  //
  const [editorText, setEditorText] = useState(text);

  // editor ref
  const editor = useRef(null);

  // editor styles
  const jedtitorStyles = {
    flexGrow: 1,
  };

  // translation
  const { t } = useTranslation('preview');

  return (
    <>
      <Stack sx={{ height: '100%' }}>
        <Stack sx={{ flexGrow: 1 }}>
          <JoditEditor
            ref={editor}
            value={editorText}
            config={{
              ...htmlEditorDefaultConfig,
              language: 'de',
              height: '96%',
              width: '100%',
              allowResizeX: false,
              allowResizeY: false,
            }}
            onBlur={(newContent) => {
              setEditorText(newContent);
            }}
          />
        </Stack>
        <Stack direction="row">
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={() => closeHandler()}
          >
            {t('TEXT_EDITOR.BUTTON_close')}
          </Button>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={() => submitHandler(editorText)}
          >
            {t('TEXT_EDITOR.BUTTON_save')}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
