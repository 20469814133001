import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default async function getStaffApi(
  eventId: string,
  role?: string,
): Promise<Parse.Object[]> {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'getStaff',
    { eventId: eventId, roleName: role },
    { sessionToken: sessionToken },
  );
}
