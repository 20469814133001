import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Stack,
  Box,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ErCheckbox,
  ErDatePicker,
  ErHtmlEditor,
  ErSwitch,
  ErTextField,
} from 'eventrocket-commoncomponents';
import {
  eventDetailsFeatureRegistrationValidationSchema,
  eventDetailsFeatureRegistrationDefaults,
} from './eventDetailsFeatureRegistrationValidationSchema';
import { ErDateTimePicker } from 'eventrocket-commoncomponents';
import { default as Grid } from '@mui/material/Unstable_Grid2/Grid2';
import { forEach } from 'lodash';

import { htmlEditorDefaultConfig } from '../../../util/htmlEditor/htmlEditorDefaultConfig';
import useUserPermissions from '../../Hooks/useUserPermissions';
import { useTranslation } from 'react-i18next'; // Import useTranslation

type EventDetailsFormLayoutFeaturesRegistrationPropsTypes = {
  featureState?: boolean;
  toggleRegistrationActive: (value?: boolean) => void;
};
export default function EventDetailsFormLayoutFeaturesRegistration(
  props: EventDetailsFormLayoutFeaturesRegistrationPropsTypes,
) {
  // props
  const { featureState = false, toggleRegistrationActive } = props;

  // permission handling
  const { hasPermission } = useUserPermissions();

  // translation
  const { t } = useTranslation('event');

  // media query
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // event details context
  const { registrationData, updateRegistrationData, onlineData } =
    useEventDetailsFormData();

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsFeatureRegistrationValidationSchema),
    defaultValues: {
      ...eventDetailsFeatureRegistrationDefaults,
      ...registrationData?.data,
    },
  });

  const watch = useWatch({ control });
  const watchEmbed = useWatch({ name: 'embed', control });
  const watchFields = useWatch({ name: 'fieldDefinition', control });
  const watchMailConfirm = useWatch({ name: 'confirmEmail', control });

  // state
  const [embedActive, setEmbedActive] = useState(false);

  //
  const getSwitchActive = (fieldName: string) => {
    const activeState = getValues(`${fieldName}.active`);
    if (!activeState) {
      setValue(`${fieldName}.required`, false);
    }

    return activeState;
  };

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context
    if (!firstRun) {
      updateRegistrationData(getValues(), featureState ? featureState : false);
    } else {
      setFirstRun(false);
    }
  }, [watch, featureState]);

  useEffect(() => {
    setEmbedActive(getValues('embed'));
  }, [watchEmbed]);

  //// side effects
  // reset required state of field if field itself is not active
  useEffect(() => {
    if (!firstRun) {
      for (const key in watchFields) {
        if (watchFields.hasOwnProperty(key)) {
          if (!watchFields[key].active && watchFields[key].required) {
            setValue(`fieldDefinition.${key}.required`, false);
          }
        }
      }
    }
  }, [watchFields]);

  useEffect(() => {
    if (!onlineData?.active && registrationData?.active) {
      updateRegistrationData({}, false);
      toggleRegistrationActive(false);
    }
  }, [onlineData?.active]);

  return (
    <Stack sx={{ mt: 2 }}>
      <Accordion disabled={!featureState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="basic-options"
          id="basic-options-header"
        >
          {t('DETAILS-FORM.SECTION.REGISTRATION.ACCORDION_BASIC.headline')}
        </AccordionSummary>
        <AccordionDetails>
          <ErDateTimePicker
            disabled={!featureState}
            fullWidth
            readOnly={
              !hasPermission({
                objPath: 'event.settingsMenu',
                operation: 'update',
              })
            }
            textFieldSize="small"
            control={control}
            fieldLabel={t(
              'DETAILS-FORM.SECTION.REGISTRATION.FIELD_registrationStarts',
            )}
            fieldName="registrationStarts"
            sx={{
              mb: 2,
              width: 'fit-content',
              minWidth: '200px',
              maxWidth: '200px',
            }}
            error={
              errors.registrationStarts &&
              getErrorMessage(errors, 'registrationStarts')
            }
          />

          <ErSwitch
            disabled={
              !hasPermission({
                objPath: 'event.settingsMenu',
                operation: 'update',
              }) || !featureState
            }
            control={control}
            fieldName="confirmEmail"
            fieldLabel={t(
              'DETAILS-FORM.SECTION.REGISTRATION.SWITCH_confirmEmail',
            )}
            size="small"
            labelPos="end"
            sx={{ mb: 2 }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion disabled={!featureState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="registration-options"
          id="registration-options-header"
        >
          {t('DETAILS-FORM.SECTION.REGISTRATION.ACCORDION_FIELDS.headline')}
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: 'grid',
              columnGap: 1,
              gridTemplateColumns: 'max-content max-content',
            }}
          >
            {/* firstname */}
            <ErSwitch
              disabled
              control={control}
              fieldName="fieldDefinition.firstname.active"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.REGISTRATION.FIELD_firstname',
              )}
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled
              control={control}
              fieldName="fieldDefinition.firstname.required"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.REGISTRATION.CHECKBOX_required',
              )}
              size="small"
              sx={{ mb: 2 }}
            />

            {/* lastname */}
            <ErSwitch
              disabled
              control={control}
              fieldName="fieldDefinition.lastname.active"
              fieldLabel={t('DETAILS-FORM.SECTION.REGISTRATION.FIELD_lastname')}
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled
              control={control}
              fieldName="fieldDefinition.lastname.required"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.REGISTRATION.CHECKBOX_required',
              )}
              size="small"
              sx={{ mb: 2 }}
            />

            {/* email */}
            <ErSwitch
              disabled
              control={control}
              fieldName="fieldDefinition.email.active"
              fieldLabel={t('DETAILS-FORM.SECTION.REGISTRATION.FIELD_email')}
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled
              control={control}
              fieldName="fieldDefinition.email.required"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.REGISTRATION.CHECKBOX_required',
              )}
              size="small"
              sx={{ mb: 2 }}
            />

            {/* phone */}
            <ErSwitch
              disabled={
                !featureState ||
                !hasPermission({
                  objPath: 'event.settingsMenu',
                  operation: 'update',
                })
              }
              control={control}
              fieldName="fieldDefinition.phone.active"
              fieldLabel={t('DETAILS-FORM.SECTION.REGISTRATION.FIELD_phone')}
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled={
                !featureState ||
                !getValues('fieldDefinition.phone.active') ||
                !hasPermission({
                  objPath: 'event.settingsMenu',
                  operation: 'update',
                })
              }
              control={control}
              fieldName="fieldDefinition.phone.required"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.REGISTRATION.CHECKBOX_required',
              )}
              size="small"
              sx={{ mb: 2 }}
            />

            {/* organisation */}
            <ErSwitch
              disabled={
                !featureState ||
                !hasPermission({
                  objPath: 'event.settingsMenu',
                  operation: 'update',
                })
              }
              control={control}
              fieldName="fieldDefinition.organisation.active"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.REGISTRATION.FIELD_organisation',
              )}
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled={
                !featureState ||
                !getValues('fieldDefinition.organisation.active') ||
                !hasPermission({
                  objPath: 'event.settingsMenu',
                  operation: 'update',
                })
              }
              control={control}
              fieldName="fieldDefinition.organisation.required"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.REGISTRATION.CHECKBOX_required',
              )}
              size="small"
              sx={{ mb: 2 }}
            />

            {/* Address */}
            <ErSwitch
              disabled={
                !featureState ||
                !hasPermission({
                  objPath: 'event.settingsMenu',
                  operation: 'update',
                })
              }
              control={control}
              fieldName="fieldDefinition.address.active"
              fieldLabel={t('DETAILS-FORM.SECTION.REGISTRATION.FIELD_address')}
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled={
                !featureState ||
                !getValues('fieldDefinition.address.active') ||
                !hasPermission({
                  objPath: 'event.settingsMenu',
                  operation: 'update',
                })
              }
              control={control}
              fieldName="fieldDefinition.address.required"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.REGISTRATION.CHECKBOX_required',
              )}
              size="small"
              sx={{ mb: 2, py: 0 }}
            />

            {/* certificate */}
            <ErSwitch
              disabled={
                !featureState ||
                !hasPermission({
                  objPath: 'event.settingsMenu',
                  operation: 'update',
                })
              }
              control={control}
              fieldName="fieldDefinition.certificate.active"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.REGISTRATION.FIELD_certificate',
              )}
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              // disabled={
              //   !featureState ||
              //   !getValues('fieldDefinition.certificate.active')
              // }
              disabled
              control={control}
              fieldName="fieldDefinition.certificate.required"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.REGISTRATION.CHECKBOX_required',
              )}
              size="small"
              sx={{ mb: 2, opacity: 0 }}
            />

            {/* memberId */}
            <Collapse
              in={getValues('fieldDefinition.certificate.active')}
              // sx={{ width: 'fit-content' }}
            >
              <ErSwitch
                disabled={
                  !featureState ||
                  !hasPermission({
                    objPath: 'event.settingsMenu',
                    operation: 'update',
                  })
                }
                control={control}
                fieldName="fieldDefinition.memberId.active"
                fieldLabel={t(
                  'DETAILS-FORM.SECTION.REGISTRATION.FIELD_memberId',
                )}
                labelPos="end"
                size="small"
                sx={{ mb: 2 }}
              />
            </Collapse>
            <Collapse
              in={getValues('fieldDefinition.certificate.active')}
              // sx={{ width: 'fit-content' }}
            >
              <ErCheckbox
                disabled={
                  !featureState ||
                  !getValues('fieldDefinition.memberId.active') ||
                  !hasPermission({
                    objPath: 'event.settingsMenu',
                    operation: 'update',
                  })
                }
                control={control}
                fieldName="fieldDefinition.memberId.required"
                fieldLabel={t(
                  'DETAILS-FORM.SECTION.REGISTRATION.CHECKBOX_required',
                )}
                size="small"
                sx={{ mb: 2 }}
              />
            </Collapse>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
