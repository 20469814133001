import Parse from 'parse';
import { UpdateSlotDataTypes } from '../../Components/Slots/Form/slotDetailsFormTypes';
import useAuthStore from '../../store/authStore';
import { clone, cloneDeep } from 'lodash';

// !!! typings
export default async function updateSlotApi(
  data: any,
  slotOrderUpdatePause: boolean,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;
  const instanceId = useAuthStore.getState().instanceId;

  // clone the data, since we do not want to
  // remove stuff from the local version
  const dataClone = clone(data);
  delete dataClone.actors;
  delete dataClone.media;

  return Parse.Cloud.run(
    'updateSlot',
    {
      slotData: dataClone,
      slotOrderUpdatePause: slotOrderUpdatePause,
      instanceId: instanceId,
    },
    { sessionToken: sessionToken },
  )
    .then((res) => res)
    .catch((e) => {
      throw new Error(e);
    });
}
