import { useEffect, useState } from 'react';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import useEventDetailsFormDataHandling from './useEventDetailsFormDataHandling';
import EventDetailsFormLayoutCustomization from './EventDetailsFormLayoutCustomization';
import EventDetailsFormLayoutFeatures from './EventDetailsFormLayoutFeatures';
import { useTranslation } from 'react-i18next';
import EventDetailsFormLayoutGeneral from './EventDetailsFormLayoutGeneral';
import EventDetailsFormLayoutAccess from './EventDetailsFormLayoutAccess';
import EventDetailsFormSectionMenu from './EventDetailsFormSectionMenu';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import StyleIcon from '@mui/icons-material/Style';
import EventDetailsFormLayoutMarketing from './EventDetailsFormLayoutMarketing';
import TuneIcon from '@mui/icons-material/Tune';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useUserPermissions from '../../Hooks/useUserPermissions';

export default function EventDetailsSingleFormLayout() {
  // props

  //// state

  // toggle the display of the form container
  const [displayForm, setDisplayForm] = useState(false);

  // theme media queries
  const theme = useTheme();

  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // permission handling
  const { hasPermission } = useUserPermissions();

  // translation
  const { t, i18n } = useTranslation('event');

  const formHeadline: { [key: string]: string } = {
    main: 'Einstellungen',
    general: '',
    basics: t(
      'DETAILS-FORM.SECTION.BASIC.HEADLINE',
      '_DETAILS-FORM.SECTION.BASIC.HEADLINE',
    ),
    meta: t(
      'DETAILS-FORM.SECTION.META.HEADLINE',
      '_DETAILS-FORM.SECTION.META.HEADLINE',
    ),
    access: t(
      'DETAILS-FORM.SECTION.ACCESS.HEADLINE',
      '_DETAILS-FORM.SECTION.ACCESS.HEADLINE',
    ),
    customization: '',
    branding: t(
      'DETAILS-FORM.SECTION.BRANDING.HEADLINE',
      '_DETAILS-FORM.SECTION.BRANDING.HEADLINE',
    ),
    legal: t(
      'DETAILS-FORM.SECTION.LEGAL.HEADLINE',
      '_DETAILS-FORM.SECTION.LEGAL.HEADLINE',
    ),
    onlineStageDisplay: t(
      'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.HEADLINE',
      '_DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.HEADLINE',
    ),
    features: '',
    mediaMng: t(
      'DETAILS-FORM.SECTION.MEDIAMNG.HEADLINE',
      '_DETAILS-FORM.SECTION.MEDIAMNG.HEADLINE',
    ),
    monet: t(
      'DETAILS-FORM.SECTION.MONET.HEADLINE',
      '_DETAILS-FORM.SECTION.MONET.HEADLINE',
    ),
    register: t(
      'DETAILS-FORM.SECTION.REGISTRATION.HEADLINE',
      '_DETAILS-FORM.SECTION.REGISTRATION.HEADLINE',
    ),
    online: t(
      'DETAILS-FORM.SECTION.ONLINE.HEADLINE',
      '_DETAILS-FORM.SECTION.ONLINE.HEADLINE',
    ),
  };

  // form data
  const {
    eventId,
    basicData,
    monetData,
    eventSettingsIsDirty,
    handleCancel,
    activateSection,
  } = useEventDetailsFormData();

  const { handleSubmit } = useEventDetailsFormDataHandling();

  // active form
  const [activeForm, setActiveForm] = useState(
    activateSection ? activateSection : 'basics',
  );

  //// functions
  // menu action handler
  const changeMenu = async (menuId: string) => {
    setActiveForm(menuId);
    setDisplayForm(true);
  };

  // manually blur input fields
  // mainly needed for jodit editor data submit behaviour
  const handleSubmitButtonMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    // Get the active element, which should be the input field
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement && activeElement.tagName === 'INPUT') {
      activeElement.blur();
    }
  };

  //// side effects
  useEffect(() => {
    // if screensize changed to mobile reset the menu open flag
    if (mediaQuery) {
      setDisplayForm(false);
    }
  }, [mediaQuery]);

  useEffect(() => {
    if (
      activeForm === 'mediaMng' ||
      activeForm === 'mont' ||
      activeForm === 'register' ||
      activeForm === 'monet'
    ) {
      changeMenu(activeForm);
    }
  }, [activeForm]);

  return (
    <>
      <Stack
        direction="column"
        sx={{
          width: { xs: '100vw', sm: '100%' },
          height: { xs: '100%', sm: '80vh' },
          maxHeight: { xs: '100vh', sm: '750px' },
          position: { xs: 'absolute', sm: 'unset' },
        }}
      >
        {/* Header */}
        <Box
          display="flex"
          width="100%"
          height={mediaQuery ? '44px' : '60px'}
          alignItems="center"
          position={mediaQuery ? 'fixed' : 'static'}
          sx={{
            backgroundColor: theme.palette.navigationBg.main,
            flexShrink: 0,
          }}
        >
          {/* Desktop */}
          {!mediaQuery && (
            <Stack
              direction="row"
              alignItems="center"
              sx={{ maxWidth: '100%' }}
            >
              <IconButton color="primary" onClick={handleCancel}>
                <ArrowBackIosNewIcon />
              </IconButton>

              <Stack sx={{ maxWidth: 'calc(100% - 40px)', pr: 2 }}>
                <Typography
                  variant="InfoTextBold"
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {basicData.data.title}
                </Typography>
                <Typography
                  variant="InfoText"
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {t('DETAILS-FORM.HEADLINE', '_DETAILS-FORM.HEADLINE')}
                </Typography>
              </Stack>
            </Stack>
          )}

          {/* Mobile */}
          {mediaQuery && (
            <Stack
              sx={{ width: '100%', pr: '5px', height: '100%' }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              bgcolor={theme.palette.navigationBg.main}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ maxWidth: 'calc(100% - 94px)' }}
              >
                <IconButton size="large" onClick={handleCancel} color="primary">
                  <ArrowBackIosNewIcon />
                </IconButton>
                <Stack sx={{ maxWidth: 'calc(100% - 40px)', pr: 2 }}>
                  <Typography
                    variant="InfoTextBold"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {basicData.data.title}
                  </Typography>
                  <Typography
                    variant="InfoText"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {t('DETAILS-FORM.HEADLINE', '_DETAILS-FORM.HEADLINE')}
                  </Typography>
                </Stack>
              </Stack>
              {hasPermission({
                objPath: 'event.settingsMenu',
                operation: 'update',
              }) && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!eventSettingsIsDirty}
                >
                  {t('DETAILS-FORM.SAVE_button', '_DETAILS-FORM.SAVE_button')}
                </Button>
              )}
            </Stack>
          )}
        </Box>

        {/* Content / forms */}
        {/* <Box
          sx={{
            width: { xs: '100vw', sm: '100%' },
            // px: '10px',
            position: 'relative',
            flex: { xs: '0 0 100vw', sm: '0 0 100%' },
            display: 'flex',
            flexDirection: 'column',
          }}
        > */}
        {/* Content */}
        <Box
          width="100%"
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            py: 1.5,
            px: 2,
            mt: mediaQuery ? '44px' : 0,
          }}
        >
          {(activeForm === 'general' || activeForm === 'basics') && (
            <EventDetailsFormLayoutGeneral
              mediaQueryMobile={mediaQuery}
              featureType={activeForm}
            />
          )}

          {(activeForm === 'customization' ||
            activeForm === 'branding' ||
            activeForm === 'legal' ||
            activeForm === 'onlineStageDisplay' ||
            activeForm === 'meta') && (
            <EventDetailsFormLayoutCustomization
              mediaQueryMobile={mediaQuery}
              featureType={activeForm}
            />
          )}
        </Box>

        {/* Desktop Footer */}
        {!mediaQuery && (
          <Box
            width="100%"
            display="flex"
            flexShrink={0}
            justifyContent="end"
            sx={{ mb: 2, pr: 1 }}
            height="60px"
            alignItems="center"
          >
            {hasPermission({
              objPath: 'event.settingsMenu',
              operation: 'update',
            }) && (
              <Button
                size="small"
                variant="contained"
                onMouseDown={handleSubmitButtonMouseDown}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  handleSubmit();
                }}
                disabled={!eventSettingsIsDirty}
              >
                {t('DETAILS-FORM.SAVE_button', '_DETAILS-FORM.SAVE_button')}
              </Button>
            )}
          </Box>
        )}
      </Stack>
    </>
  );
}
