import Parse from 'parse';
import { UpdateSessionDataTypes } from '../../Components/Sessions/Session/Form/sessionDetailsFormTypes';
import useAuthStore from '../../store/authStore';

// !!! typings
export default async function updateSessionApi(data: any, eventId: string) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;
  const instanceId = useAuthStore.getState().instanceId;

  // remove parse objects before sending
  delete data.createdAt;
  delete data.updatedAt;
  delete data.event;
  delete data.slots;
  delete data.actors;
  delete data.media;
  delete data.slotlist;

  return Parse.Cloud.run(
    'updateSession',
    { sessionData: data, eventId: eventId, instanceId: instanceId },
    { sessionToken: sessionToken },
  )
    .then((res) => res)
    .catch((e) => {
      throw new Error(e);
    });
}
