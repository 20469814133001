import {
  Box,
  Button,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  downloadMediaFilesApiParamsTypes,
  downloadType,
} from '../../../api/media/downloadMediaFilesApi';
import { useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';

export interface MediaListDownloadWidgetPropsTypes {
  downloadHandler: (type: downloadType) => void;
  togglePaidOptionsModal: () => void;
  //   callbackProgress?: () => void;
  //   downloadMediaFilesData: downloadMediaFilesDataTypes;
  callBackAction?: () => void;
  featurePaid?: boolean;
}

export default function MediaListDownloadWidget(
  props: MediaListDownloadWidgetPropsTypes,
) {
  // props
  const {
    downloadHandler,
    togglePaidOptionsModal,
    callBackAction,
    featurePaid = false,
  } = props;

  // theme
  const theme = useTheme();

  // translation
  const { t } = useTranslation('media');

  // state
  const [downloadType, setDownloadType] = useState<downloadType>('new');

  const handleDownloadTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDownloadType((event.target as HTMLInputElement).value as downloadType);
  };

  return (
    <>
      <Stack width="100%">
        <RadioGroup
          name="downloadType-selection"
          value={downloadType}
          onChange={handleDownloadTypeChange}
          sx={{ px: '20px' }}
        >
          <FormControlLabel
            value="new"
            control={<Radio size="small" disabled={!featurePaid} />}
            label={t('DOWNLOAD_WIDGET.LABEL_latestVersion')}
          />
          <FormControlLabel
            value="newConfirmed"
            control={<Radio size="small" disabled={!featurePaid} />}
            label={t('DOWNLOAD_WIDGET.LABEL_confirmedVersions')}
          />
          <FormControlLabel
            value="newUnconfirmed"
            control={<Radio size="small" disabled={!featurePaid} />}
            label={t('DOWNLOAD_WIDGET.LABEL_unconfirmedVersions')}
          />
        </RadioGroup>

        {!featurePaid && (
          <>
            <Typography variant="body2" sx={{ px: '20px', mt: 0 }}>
              {t('DOWNLOAD_WIDGET.TEXT_activateMediaManagement')}
            </Typography>
            <List
              sx={{
                listStyleType: 'disc',
                listStylePosition: 'outside',
                px: '20px',
                ml: '20px',
              }}
            >
              <ListItem
                sx={{
                  display: 'list-item',
                  p: 0,
                  fontFamily: theme.typography.body2.fontFamily,
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.body2.fontWeight,
                }}
              >
                {t('DOWNLOAD_WIDGET.LISTITEM_inviteActors')}
              </ListItem>
              <ListItem
                sx={{
                  display: 'list-item',
                  p: 0,
                  fontFamily: theme.typography.body2.fontFamily,
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.body2.fontWeight,
                }}
              >
                {t('DOWNLOAD_WIDGET.LISTITEM_reviewAndApprove')}
              </ListItem>
              <ListItem
                sx={{
                  display: 'list-item',
                  p: 0,
                  fontFamily: theme.typography.body2.fontFamily,
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.body2.fontWeight,
                }}
              >
                {t('DOWNLOAD_WIDGET.LISTITEM_keepOverview')}
              </ListItem>
              <ListItem
                sx={{
                  display: 'list-item',
                  p: 0,
                  fontFamily: theme.typography.body2.fontFamily,
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.body2.fontWeight,
                }}
              >
                {t('DOWNLOAD_WIDGET.LISTITEM_receiveMediaSorted')}
              </ListItem>
            </List>
          </>
        )}

        <Box sx={{ p: '10px' }}>
          {!featurePaid && (
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                togglePaidOptionsModal();
              }}
            >
              {t('DOWNLOAD_WIDGET.BUTTON_activateNow')}
            </Button>
          )}
          {featurePaid && (
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                downloadHandler(downloadType);
                callBackAction && callBackAction();
              }}
              startIcon={<FileDownloadOutlinedIcon />}
            >
              {t('DOWNLOAD_WIDGET.BUTTON_downloadMedia')}
            </Button>
          )}
        </Box>
      </Stack>
    </>
  );
}
