import React from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
  } from '@mui/material';
  import { Controller, FieldValues } from 'react-hook-form';
import { ErSelectTypes } from './ErSelect.types';
  
  export default function ErSelect<T extends FieldValues>(props: ErSelectTypes<T>) {
    const {
      fullWidth,
      fieldName,
      fieldLabel,
      menuItems,
      control,
      required,
      variant,
      error,
      defaultValue = '',
      type,
      readOnly,
      disabled,
      autoComplete,
      size,
      sx,
      onChangeHandler,
      getIconFunction,
    } = props;
  
    return (
      <>
        <FormControl fullWidth={fullWidth} sx={sx} size={size} disabled={disabled}>
          <Controller
            name={fieldName}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <>
                <InputLabel
                  id={'select-label-' + fieldLabel}
                  // sx={{
                  //   transform: 'translate(0px, 20px)',
                  //   '&focused': 'translate(0px, 0px)',
                  // }}
                >
                  {fieldLabel}
                </InputLabel>
                <Select
                  labelId={'select-label-' + fieldLabel}
                  id="demo-simple-select"
                  // value={age}
                  required={required}
                  label={fieldLabel}
                  variant={variant}
                  sx={{'& .MuiSvgIcon-root': {fontSize: '1rem'}}}
                  inputProps={{ readOnly: readOnly, height: '32px', onChange: (e) => onChangeHandler && onChangeHandler(e) }}
                  {...field}
                >
                  {menuItems.map((item, index) => {
                    
                    return (
                      <MenuItem
                        key={'select-' + fieldLabel + '-' + index}
                        value={item.value}
                      >
                        
                        {item.icon ? <Stack direction='row' alignItems='center'><item.icon sx={{pr: 1}}/> {item.label}</Stack> : item.label}
                      </MenuItem>
                    );
                  })}
                  {/* <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </>
            )}
          />
        </FormControl>
      </>
    );
  }
  