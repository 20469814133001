import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import useEventStore from '../../store/eventStore';

export type avaialbleView =
  | 'announcement'
  | 'registration'
  | 'confirm'
  | 'welcome'
  | 'lobby'
  | 'sessionWelcome'
  | 'live'
  | 'closed';

type PreviewContextType = {
  eventData: any;
  eventDataStatus: string;
  avaialbleViews: avaialbleView[];
  view: avaialbleView;
  setView: (view: avaialbleView) => void;
  initialView: avaialbleView;
  setInitialView: (view: avaialbleView) => void;
  viewIndex: number;
  changeViewIndex: (direction: 1 | -1) => void;
  activeSession: string;
  setActiveSession: (sessionId: string) => void;
  reloadFromNav: boolean;
  updateReloadFromNav: (state: boolean) => void;
};

const PreviewContext = createContext<PreviewContextType | null>(null);

export function PreviewContextProvider({ children }: { children: ReactNode }) {
  // translation
  const translation = useTranslation();

  const eventData = useEventStore((store) => store.data);
  const eventDataStatus = useEventStore((store) => store.status);

  // feature vars
  const isMultiSession = eventData?.multiSession;
  const hasRegistration =
    eventData?.features_active?.['registration'] &&
    eventData?.features_active?.['registration'].length > 0
      ? true
      : false;
  const hasEmailConfirm =
    eventData?.features_active?.['registration'] &&
    eventData?.features_active?.['registration'].length > 0
      ? eventData?.features_active?.['registration']?.[0]?.settingsObj
          .confirmEmail
      : false;

  //// build avaialble view list
  const avaialbleViews = ['announcement' as avaialbleView];

  if (hasRegistration) avaialbleViews.push('registration');
  if (hasRegistration && hasEmailConfirm) avaialbleViews.push('confirm');
  if (hasRegistration) avaialbleViews.push('welcome');
  if (isMultiSession) avaialbleViews.push('lobby');
  if (isMultiSession) avaialbleViews.push('sessionWelcome');
  avaialbleViews.push('live');
  avaialbleViews.push('closed');

  // active view to preview
  const [view, setView] = useState<avaialbleView>(
    'announcement' as avaialbleView,
  );
  const [viewIndex, setViewIndex] = useState(0);

  // initial view when view is preselected via url param previewView
  const [initialView, setInitialView] = useState<avaialbleView>(
    'announcement' as avaialbleView,
  );

  const changeViewIndex = (direction: 1 | -1) => {
    if (direction === 1) {
      if (viewIndex < avaialbleViews.length - 1) {
        setViewIndex(viewIndex + 1);
      } else {
        setViewIndex(0);
      }
    } else {
      if (viewIndex > 0) {
        setViewIndex(viewIndex - 1);
      } else {
        setViewIndex(avaialbleViews.length - 1);
      }
    }
  };

  // reload triggered from navigation
  const [reloadFromNav, setReloadFromNav] = useState(true);

  const updateReloadFromNav = (state: boolean) => {
    setReloadFromNav(state);
  };

  // session to use in preview
  const [activeSession, setActiveSession] = useState('');

  // update the current view
  useEffect(() => {
    setView(avaialbleViews[viewIndex]);
  }, [viewIndex]);

  // set the initial view to be the active view
  useEffect(() => {
    setView(initialView);

    // also set the view index
    const initilViewIndex = avaialbleViews.findIndex(
      (view) => view === initialView,
    );
    setViewIndex(initilViewIndex);
  }, [initialView]);

  const value = {
    eventData: eventData,
    eventDataStatus: eventDataStatus,
    avaialbleViews: avaialbleViews,
    view: view,
    setView: setView,
    viewIndex: viewIndex,
    changeViewIndex: changeViewIndex,
    activeSession: activeSession,
    setActiveSession: setActiveSession,
    reloadFromNav: reloadFromNav,
    updateReloadFromNav: updateReloadFromNav,
    initialView: initialView,
    setInitialView: setInitialView,
  };

  return (
    <PreviewContext.Provider value={value}>{children}</PreviewContext.Provider>
  );
}

export const usePreviewContext = (): PreviewContextType => {
  // use our context
  const context = useContext(PreviewContext);

  // make sure we return a valid context
  if (!context) {
    throw new Error('useSlotContext must be used within a SlotProvider');
  }

  return context;
};
